import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { animated } from 'react-spring';
import Sound from 'react-sound';
import UIfx from 'uifx';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useImagesContext } from '../../contexts/ImagesContext';
import {
  getBonus as getBonusService,
  updateBonus as updateBonusService,
} from '../../services/bonus-dashboard';
import { handleError } from '../../services/handleResponse';
import  backgroundSound from '../../sounds/09.mp3';
import rollingSound from '../../sounds/10.mp3';
const soundBonusDisplay = new UIfx(rollingSound);

const useStyles = makeStyles((theme) => ({
  // root: {
  //   position: 'relative',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   width: '1920px',
  //   height: '1080px',
  //   overflow: 'hidden',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  //   '& [wheel-number]': {
  //     textTransform: 'uppercase',
  //     fontSize: '4.4rem',
  //     fontWeight: 800,
  //     transform: 'rotate(-90deg)',
  //     float: 'left',
  //     '-webkit-text-stroke': '1px #fff',
  //     'text-shadow': '1px 3px 3px #000000',
  //   },
  // },
  background: {
    position: 'relative',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
    '& [wheel-number]': {
      textTransform: 'uppercase',
      fontSize: '4.4rem',
      fontFamily: 'PSLxText',
      fontWeight: 'bold',
      transform: 'rotate(-90deg)',
      float: 'left',
      color: 'black',
      textStroke: '4px white',
      paintOrder: 'stroke fill',
    },
  },
  home: {
    position: 'absolute',
    left: 20,
    bottom: 20,
  },
  circleBlock: {
    position: 'absolute',
    left: 497,
    top: 140,
    width: 926,
    height: 924,
  },
  hiddenButton: {
    position: 'absolute',
    left: 830,
    top: 475,
    width: 250,
    height: 250,
    borderRadius: '50%',
    // border: '3px solid yellow',
  },
  bonusPoint: {
    color: '#000000',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontSize: 213,
    textShadow: '0 0 20px #000000'
  },
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));
const Bonus = ({ leave, config, history, student }) => {
  const { images } = useImagesContext();
  const classes = useStyles();
  const [rotation, setRotation] = useState(0);
  const [showPoint, setShowPoint] = useState(null);
  const [bonus, setBonus] = useState(undefined);
  const [updateBonusReqFlag, setUpdateBonusReqFlag] = useState(false);
  const isStartedDrawWheel = useRef(false);

  React.useEffect(() => {
    getBonusService(
      (resp) => {
        setBonus(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  }, []);

  useEffect(() => {
    disablePageScroll();
    return () => {
      enablePageScroll();
    };
  }, []);

  const onClick = () => {
    console.log('onClick:', showPoint);
    if (showPoint) {
      history.goBack();
    } else if (!updateBonusReqFlag && !isStartedDrawWheel.current) {
      // setRotation(4 * 360 + 45 * bonus?.circleIndex);
      isStartedDrawWheel.current = true;
      setUpdateBonusReqFlag(true);
      updateBonusService(
        student.id,
        (resp) => {
          // const { result, index, value, point } = resp;
          const { index } = resp;
          const number = [4, 5, 6, 7, 8];
          setRotation(
            (prev) =>
              getDegreeByBonusIndex(index) +
              360 * number[Math.floor(Math.random() * number.length)]
          );
        },
        (error) => {
          handleError(error);
          setShowPoint(null);
        },
        () => {
          setUpdateBonusReqFlag(false);
        }
      );
    }
  };

  const getPointByDegree = (degree) => {
    const mapPonit = {};
    const _bonus = bonus?.values || {};
    mapPonit[90] = _bonus[0];
    mapPonit[45] = _bonus[1];
    mapPonit[0] = _bonus[2];
    mapPonit[315] = _bonus[3];
    mapPonit[270] = _bonus[4];
    mapPonit[225] = _bonus[5];
    mapPonit[180] = _bonus[6];
    mapPonit[135] = _bonus[7];

    return mapPonit[degree % 360];
  };

  const getDegreeByBonusIndex = (index) => {
    if (index === 0) {
      return 90;
    } else if (index === 1) {
      return 45;
    } else if (index === 2) {
      return 0;
    } else if (index === 3) {
      return -45;
    } else if (index === 4) {
      return -90;
    } else if (index === 5) {
      return -135;
    } else if (index === 6) {
      return -180;
    } else if (index === 7) {
      return -225;
    } else {
      return 0;
    }
  };
  console.log('bonus:', bonus);
  return (
    <div
      className={classes.background}
      style={{
        backgroundImage: `url(${images['background.jpg']})`,
      }}
    >
      {config.isMutedSound ? null : (
        <Sound
          url={backgroundSound}
          playStatus='PLAYING'
          loop={true}
          volume={50}
        ></Sound>
      )}
      <div
        className={classes.circleBlock}
      >
        <animated.div
          id='bonus-circle'
          alt=''
          onTransitionEnd={() => {
            // console.log('point:', getPointByDegree(rotation));
            setShowPoint(getPointByDegree(rotation));
            if (!config.isMutedSound) {
              soundBonusDisplay.play();
            }
          }}
          style={{
            // margin: 'auto',
            // position: 'absolute',
            // height: 200,
            width: 926,
            height: 924,
            transition: '5s',
            transform: `rotate(${rotation}deg)`,
          }}
        >
          <img
            style={{ width: 926, height: 924 }}
            src={images['bonus-circle.png']}
            alt=''
          ></img>
          {(bonus?.values || []).map((number, idx) => (
            <div style={{ transform: 'translate(191px, -522px)' }}>
              <div
                style={{
                  width: 552,
                  height: 120,
                  backgroundColor: 'transparent',
                  transform: `rotate(${-4 + 45 * idx}deg)`,
                  position: 'absolute',
                }}
              >
                <Typography wheel-number=''>{number}</Typography>
              </div>
            </div>
          ))}
        </animated.div>
      </div>

      {/* <div
        id='wb_Image1'
        style={{
          position: 'absolute',
          left: 911,
          top: 0,
          width: 122,
          height: 252,
          zIndex: 7009,
        }}
      >
        <img
          src={images['bonus-head.png']}
          id='Image1'
          alt=''
          onContextMenu={(e) => e.preventDefault()}
        />
      </div> */}
      {showPoint && (
        <>
          <div
            // id='wb_bonus-star'
            style={{
              position: 'absolute',
              left: 700,
              top: 260,
              width: 544,
              height: 544,
              zIndex: 7010,
            }}
          >
            <div>
              <img
                src={images['bonus-star.png']}                
                // id='bonus-star'
                // className='my-btn'
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
          <div
            // id='wb_bonus-point'
            style={{
              position: 'absolute',
              left: 840,
              top: 280,
              width: 247,
              height: 243,
              textAlign: 'center',
              // zIndex: 7011,
            }}
          >
            <h1 
              // style={{
              //   color: '#000000',
              //   fontFamily: 'Arial',
              //   fontWeight: 'bold',
              //   fontSize: 213,
              //   // textShadow: '0 0 20 #000000'
              // }}
              className={classes.bonusPoint}
            >
              {showPoint}
            </h1>
          </div>
          <div
            onClick={()=> history.goBack()}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <img
              src={images['effect-06.gif']}
              alt=''
            />
          </div>
        </>
      )}
      <img
        className={`${classes.point} ${classes.home}`}
        src={images['home.png']}
        alt=''
        onClick={() => history.goBack()}
      />
      <div
        className={classes.hiddenButton}
        onClick={onClick}></div>
    </div>
  );
};

const mapStateToProps = ({ config, student }) => ({
  config,
  student,
});

export default connect(mapStateToProps, null)(Bonus);
