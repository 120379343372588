import * as type from './types';

export const saveJumpUri = uri => async dispatch => {

    console.debug('Redux:saveJumpUri')
    return dispatch({
        type: type.JUMP_SAVE,
        payload: { jumpUri: uri }
    });
};

export const clearJumpUri = () => async dispatch => {
    return dispatch({
        type: type.JUMP_CLEAR,
        payload: {}
    });
};

