import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const saveSuccess = () => {
  return MySwal.fire({
    title: 'Saved successfully',
    icon: 'success',
  });
};

export const errorMessage = (error) => {
  return MySwal.fire({ title: error, icon: 'error' });
  // if (error.length) {
  //   const msgString = error.map(v => v.msg);
  //   MySwal.fire({ title: msgString.join('\n'), icon: 'error' });
  // } else if (error.msg) {
  //   MySwal.fire({ title: error.msg, icon: 'error' });
  // }
};

export const successHandler = () => {
  Swal.fire('บันทึกข้อมูล', 'ทำรายการสำเร็จ', 'success');
};

export const errorHandler = (err) => {
  let errmsg = err;

  if (
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    Array.isArray(err.response.data.errors) &&
    (err.response.data.errors || []).length > 0
  ) {
    errmsg = err.response.data.errors.map((e) => e.msg).join(', ');
  } else if (err.response && err.response.data && err.response.data.errors) {
    errmsg = err.response.data.errors;
  } else if (err.message) {
    errmsg = err.message;
  }
  Swal.fire({
    title: 'เกิดข้อผิดพลาด',
    text: JSON.stringify(errmsg),
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'ตกลง',
  }).then((result) => {});
};
