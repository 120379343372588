import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useImagesContext } from '../../contexts/ImagesContext';
import { getFightStage, passFightStage } from '../../services/fight';
import MagicRule from './MagicRule';

import Loading from '../layout/Loading';
import HitPower from './HitPower';
import BossHealth from './BossHealth';
import Sound from 'react-sound';
import { connect } from 'react-redux';

import * as configActions from '../../actions/config';
import * as fightActions from '../../actions/fight';
import LoadImage from '../image/LoadImage';

import fightBackgroundSound from '../../sounds/fight/fight-background.mp3';
import hitBossSound from '../../sounds/fight/hit-boss.mp3';
import soundMissionClear from '../../sounds/fight/mission-clear.wav';
import soundMissionFailed from '../../sounds/fight/mission-failed.wav';
import soundPlayerMoveIn from '../../sounds/fight/player-movein.ogg';

const ANIMATION_ATTACK = 2000;
const LOAD_IMAGE_AMOUNT = 2;

const ATTACK_BUTTON_POSITION = [
  {
    left: 0,
    top: 0,
  },
  {
    left: 200,
    top: 0,
  },
  {
    left: 100,
    top: 120,
  },
  {
    left: 0,
    top: 240,
  },
  {
    left: 200,
    top: 240,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '1920px',
    height: '1080px',
    overflow: 'hidden',
  },
  background: {
    width: '1920px',
    height: '1080px',
    zIndex: -1,
  },
  fullScreen: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
  },
  buttonPrevious: {
    position: 'absolute',
    bottom: '24px',
    left: '24px',
    width: '149px',
    height: '103px',
  },
  buttonNext: {
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    width: '149px',
    height: '103px',
  },
  buttonHitBoss: {
    position: 'absolute',
    // border: '8px solid red',
  },
  frameHitBoss: {
    position: 'absolute',
    left: 1400,
    top: 600,
  },
  frameHitPower: {
    position: 'absolute',
    left: 10,
    top: 10,
    // padding: 30,
    // border: '8px solid yellow',
  },
  frameBossHealth: {
    position: 'absolute',
    right: 24,
    top: 24,
    // padding: 30,
    // border: '8px solid yellow',
  },
  frameBoss: {
    position: 'absolute',
    left: 0,
    top: 0,
    padding: 0,
    // border: '8px solid yellow'
  },
  frameHitEffect: {
    position: 'absolute',
    left: 1310,
    top: 450,
    padding: 0,
    // border: '8px solid yellow'
  },
  frameHitCount: {
    position: 'absolute',
    left: 20,
    bottom: 20,
    // padding: 30,
    // border: '8px solid yellow',
  },
  text: {
    fontSize: '8em',
  },
  imageWin: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  imgHover: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));

const ATTACK_MULTIPLIER = [0, 0, 1, 2, 3];

function getAttackMultiplier() {
  const indexList = ATTACK_MULTIPLIER.map((v) => v);
  const result = [];
  while (indexList.length > 0) {
    const index = Math.floor(Math.random() * indexList.length);
    result.push(indexList[index]);
    indexList.splice(index, 1);
  }
  // console.debug('getAttackMultiplier', result);
  return result;
}

function FightStage({ match, history, config, stateCompleted, student }) {
  const classes = useStyles();
  const { images } = useImagesContext();
  const { stageId } = match.params;
  const studentId = student.id;
  const [fightStage, setFightStage] = useState(null);
  const [studentPower, setStudentPower] = useState({});
  const [hitCount, setHitCount] = useState(null);
  const [bossHealth, setBossHealth] = useState(null);
  const attackMultiplier = useRef(getAttackMultiplier());
  const [playerShooting, setPlayerShooting] = useState(false);
  const [hitResult, setHitResult] = useState(null);
  const chooseIndex = useRef(null);
  const [intoSound, setIntoSound] = useState(true);
  const imageReset = useRef(Date.now());
  const [loadImages, setLoadImages] = useState([]);
  const resetImage = useRef(Date.now());
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    getFightStage(studentId, stageId).then((res) => {
      console.debug('getFightStage: ', res);
      setBossHealth(res.stage.bossHealth);
      setFightStage(res.stage);
      setStudentPower(res.studentPower);
    });
  }, []);

  // if (fightStage === null || loadImages.length < LOAD_IMAGE_AMOUNT)
  if (fightStage === null) return <Loading />;

  function reduceBossHealth(currentBossHealth, targetBossHealth) {
    if (currentBossHealth > targetBossHealth) {
      setTimeout(() => {
        setBossHealth((v) => {
          return v - 1;
        });
        reduceBossHealth(currentBossHealth - 1, targetBossHealth);
      }, 200);
    } else {
      setTimeout(() => {
        setHitResult(null);
        setProcessing(false);
      }, 2000);
    }
  }

  function attackProcess() {
    const index = chooseIndex.current;
    setPlayerShooting(false);
    console.debug('attackMultiplier:previous', attackMultiplier.current);
    console.debug(
      `hit: ${index + 1}, attackMultiplier: ${attackMultiplier.current[index]}`
    );
    setHitResult(attackMultiplier.current[index]);
    const remaining_health =
      bossHealth - attackMultiplier.current[index] * studentPower.totalPower;
    if (remaining_health <= 0) {
      reduceBossHealth(bossHealth, 0);
    } else {
      reduceBossHealth(bossHealth, remaining_health);
    }
    attackMultiplier.current = getAttackMultiplier();
    console.debug('attackMultiplier:after', attackMultiplier.current);
  }

  function attack(index) {
    setProcessing(true);
    setHitCount((v) => {
      return v - 1;
    });
    setPlayerShooting(true);
    chooseIndex.current = index;
    resetImage.current = Date.now();
    // setTimeout(() => {

    // }, ANIMATION_ATTACK);
  }

  const BossButtons = () =>
    ATTACK_BUTTON_POSITION.map((position, index) => (
      <div
        key={`attack_${index + 1}`}
        className={`${classes.buttonHitBoss} ${classes.imgHover}`}
        style={{ left: position.left, top: position.top }}
      >
        <img
          src={images['attack.png']}
          alt=''
          onContextMenu={(e) => e.preventDefault()}
          onClick={() => {
            attack(index);
          }}
        />
      </div>
    ));

  const HitCount = () => {
    return (
      <div>
        <img
          src={images[`hit-count.png`]}
          style={{ height: 120 }}
          alt='hit-count'
        />
        <img src={images[`in-number-${hitCount}.png`]} alt='hit-count-number' />
      </div>
    );
  };

  if (hitCount === null) {
    return <MagicRule setHitCount={setHitCount} />;
  }

  let battleResult = null;
  if (processing == false && hitCount == 0 && bossHealth > 0) {
    // setTimeout(() => {
    //   alert('You lose!');
    // }, 1000);
    battleResult = 'lose';
  }
  if (processing == false && bossHealth <= 0) {
    // setTimeout(() => {
    //   alert('You win!');
    // }, 1000);
    battleResult = 'win';
  }

  let HitResult = null;
  if (hitResult === 0) {
    HitResult = (
      <div className={`${classes.frameHitEffect}`}>
        <img
          src={images['hit-blocked.png'] + '?r=' + resetImage.current}
          alt=''
        />
      </div>
    );
  } else if (hitResult === 2) {
    HitResult = (
      <div className={`${classes.frameHitEffect}`}>
        <img
          src={images['hit-double.png'] + '?r=' + resetImage.current}
          alt=''
        />
      </div>
    );
  } else if (hitResult === 3) {
    HitResult = (
      <div className={`${classes.frameHitEffect}`}>
        <img
          src={images['hit-triple.png'] + '?r=' + resetImage.current}
          alt=''
        />
      </div>
    );
  }

  function imageLoaded(url) {
    setLoadImages((loadImages) => {
      if (loadImages.find((x) => x === url)) {
        return loadImages;
      }
      return [...loadImages, url];
    });
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        // style={{
        //   backgroundImage: `url(${fightStage.background})`,
        //   backgroundRepeat: 'no-repeat'
        // }}
        className={classes.root}
      >
        <LoadImage
          onLoad={imageLoaded}
          url={fightStage.background}
          className='background'
          alt='background'
        />
        {/* <div>{`studentId: ${studentId}`}</div>
      <div>{`stageId: ${stageId}`}</div> */}
        <Sound
          url={fightBackgroundSound}
          playStatus='PLAYING'
          volume={config.isMutedSound ? 0 : 15}
          loop={true}
        ></Sound>
        <div className={`${classes.frameBoss}`}>
          {/* <img src={fightStage.bossImage} alt='boss' /> */}
          <LoadImage
            onLoad={imageLoaded}
            url={fightStage.bossImage}
            style={{ height: 1080, width: 1920 }}
            alt='boss'
          />
        </div>
        <div className={`${classes.frameHitPower}`}>
          <HitPower studentPower={studentPower} />
        </div>
        <div className={classes.fullScreen}>
          <img
            src={images['player-movein.png'] + '?r=' + imageReset.current}
            alt=''
          />
        </div>
        {hitCount > 0 &&
          hitResult == null &&
          battleResult == null &&
          !playerShooting && (
            <div className={`${classes.frameHitBoss}`}>
              <BossButtons />
            </div>
          )}
        <div className={`${classes.frameBossHealth}`}>
          <BossHealth bossHealth={bossHealth} />
        </div>
        <div className={`${classes.frameHitCount}`}>
          <HitCount />
        </div>
        {HitResult}
        {playerShooting && (
          <div className={classes.fullScreen}>
            <Sound
              url={hitBossSound}
              playStatus='PLAYING'
              onFinishedPlaying={() => {
                attackProcess();
              }}
              volume={config.isMutedSound ? 0 : undefined}
            ></Sound>
            {/* <img id='shoot' src={images['shoot.png'] + '?r=' + hitCount} alt='' onContextMenu={(e) => e.preventDefault()} /> */}
            <img src={images['shoot.png'] + '?r=' + Date.now()} alt='' />
          </div>
        )}
        {/* {playerShooting && <div className={classes.fullScreen}><img src={shoot} alt='' /></div>}       */}
        {battleResult === 'win' && (
          <div className={classes.fullScreen}>
            <img
              className={classes.imageWin}
              src={images['mission-clear.png'] + '?r=' + Date.now()}
              alt=''
            />
            <Sound
              url={soundMissionClear}
              playStatus='PLAYING'
              onFinishedPlaying={() => {
                // Todo update state to completed
                passFightStage(studentId, stageId).then((data) => {
                  const { issueBonus, totalBonus } = data;
                  stateCompleted(stageId, issueBonus, totalBonus);
                  history.goBack();
                });
              }}
              volume={config.isMutedSound ? 0 : undefined}
            ></Sound>
          </div>
        )}
        {battleResult === 'lose' && (
          <div className={classes.fullScreen}>
            <img
              src={images['mission-failed.png'] + '?r=' + Date.now()}
              alt=''
            />
            <Sound
              url={soundMissionFailed}
              playStatus='PLAYING'
              onFinishedPlaying={() => {
                history.goBack();
              }}
              volume={config.isMutedSound ? 0 : undefined}
            ></Sound>
          </div>
        )}
      </div>
      {intoSound && (
        <Sound
          url={soundPlayerMoveIn}
          playStatus='PLAYING'
          onFinishedPlaying={() => {
            setIntoSound(false);
          }}
          volume={config.isMutedSound ? 0 : undefined}
        ></Sound>
      )}
    </div>
  );
}

const mapStateToProps = ({ config, student }) => ({
  config,
  student,
});

const mapDispatchToProps = {
  setMuteSound: configActions.setMuteSound,
  stateCompleted: fightActions.stateCompleted,
};

export default connect(mapStateToProps, mapDispatchToProps)(FightStage);
