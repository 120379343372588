import React, { useState } from 'react';

import { connect } from 'react-redux';
// import Img from 'react-image';

import { useImagesContext } from '../../contexts/ImagesContext';

//import { getStudentId, getStudentAll } from '../../services/student';
import { makeStyles } from '@material-ui/core/styles';
import {
  bookOpenBackward,
  bookOpenDisappear,
  bookOpenForward,
} from '../../actions/book';
import { BOOK_BACKWARD_DURATION, BOOK_FORWARD_DURATION } from '../../constant';
import { joinClasses } from '../../utils/utility';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  book: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
    animation: 'fadeInAnimation .2s ease-in-out',
  },
  hiddenButton: {
    position: 'absolute',
    left: 940,
    bottom: 100,
  },
  circle: {
    position: 'absolute',
    top: 180,
    left: 670,
    width: 620,
    height: 620,
    // border: '1px solid black',
    borderRadius: '50%',
  },
  backButton: {
    position: 'absolute',
    top: 740,
    left: 270,
  },
}));
// const Home = ({ student: { student }, history, getStudentId }) => {
const Home = ({
  history,
  bookOpenForward,
  bookOpenBackward,
  bookOpenDisappear,
}) => {
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();
  const [pressButton, setPressButton] = useState(false);

  const goBack = () => {
    bookOpenBackward();
    setTimeout(() => {
      bookOpenDisappear();
      history.push('/branch');
    }, BOOK_BACKWARD_DURATION);
  };

  const selectStudent = () => {
    bookOpenForward();
    setTimeout(() => {
      bookOpenDisappear();
      history.push('/students');
    }, BOOK_FORWARD_DURATION);
  };

  return (
    <div
      className={c('book')}
      style={{
        backgroundImage: `url(${images['home-content.png']})`,
      }}
    >
      <img
        className={c('point backButton')}
        src={images['back.png']}
        alt=''
        onClick={goBack}
        onContextMenu={(e) => e.preventDefault()}
      />
      <img
        className={c('hiddenButton')}
        src={images['hidden-button.png']}
        alt=''
        onClick={() => {
          setPressButton(true);
        }}
      />
      <div
        className={c('circle')}
        onClick={() => {
          if (pressButton) {
            selectStudent();
          }
        }}
      ></div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  branch: store.branch,
});

const mapDispatchToProps = {
  bookOpenForward,
  bookOpenBackward,
  bookOpenDisappear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
