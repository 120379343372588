import React, { useEffect, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Sound from 'react-sound';

import { getFightMaps } from '../../services/fight';
import Loading from '../layout/Loading';
import { connect } from 'react-redux';
import * as actions from '../../actions/fight';
import LoadImage from '../image/LoadImage';

import { useImagesContext } from '../../contexts/ImagesContext';

import stateClearSound from '../../sounds/fight/state-clear.mp3';
import mapBackgroundSound from '../../sounds/fight/map-background.ogg';

const LOAD_IMAGE_AMOUNT = 1;

const STAGES_BUTTON_POSITIONS = [
  {
    left: 400,
    top: 530,
  },
  {
    left: 682,
    top: 830,
  },
  {
    left: 1005,
    top: 530,
  },
  {
    left: 1358,
    top: 830,
  },
  {
    left: 1650,
    top: 530,
  },
];

const BOUNS_ISSUE = [5, 10, 15, 20, 50];

const useStyles = makeStyles((theme) => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  root: {
    position: 'relative',
    width: '1920px',
    height: '1080px',
    overflow: 'hidden',
  },
  mapBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
    zIndex: -1,
  },
  bounsIssue: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
    zIndex: 2,
  },
  blackScreen: {
    position: 'absolute',
    width: '1920px',
    height: '1080px',
    // overflow: 'hidden',
  },
  missionClear: {
    position: 'absolute',
  },
  buttonBack: {
    position: 'absolute',
    top: '10px',
    left: '20px',
    // width: '195px',
    // height: '94px',
  },
  buttonPrevious: {
    position: 'absolute',
    bottom: '24px',
    left: '24px',
    width: '84px',
    height: '84px',
  },
  buttonNext: {
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    width: '84px',
    height: '84px',
  },
  buttonStage: {
    position: 'absolute',
    width: '220px',
    height: '100px',
    // border: '8px solid red',
  },
  completedStage: {
    position: 'absolute',
    zIndex: 1,
  },
}));

let componentCallCount = 0;
let effectCallCount = 0;

function FightMap({ match, history, fight, stateCompletedClear, config, student }) {
  const classes = useStyles();
  const [mapIndex, setMapIndex] = useState(null);
  const { images } = useImagesContext();
  const [fightMaps, setFightMaps] = useState(null);
  const [loadImages, setLoadImages] = useState([]);
  // const [currentStage, setCurrentStage] = useState(null);

  componentCallCount++;
  console.debug('FightMap.componentCallCount', componentCallCount);
  console.debug('redux:fight', fight);

  // const mapIndex = useRef(0);
  const studentId = student.id;

  function goToStage(stageId) {
    history.push(`fight/${stageId}`);
  }

  useEffect(() => {
    if (fightMaps === null) {
      return;
    }

    let foundMapIndex;
    console.debug('fight.completedStateId', fight.completedStateId);
    if (fight.completedStateId) {
      foundMapIndex = fightMaps.findIndex(
        (map) =>
          map.fightStages.findIndex(
            (stage) => stage.id == fight.completedStateId
          ) != -1
      );
    } else {
      foundMapIndex = fightMaps.length - 1;
    }
    console.debug('foundMapIndex', foundMapIndex);
    console.debug('setMapIndex:begin');
    setMapIndex(foundMapIndex);
    console.debug('setMapIndex:end');
  }, [fightMaps]);

  useEffect(() => {
    effectCallCount++;
    console.debug('FightMap.effectCallCount:begin', effectCallCount);
    getFightMaps(studentId).then((res) => {
      const maps = res.data;
      console.debug('states', maps);
      const firstNoPassMap = maps.findIndex((stage) => stage.isPass === false);
      console.debug('setFightMaps:begin');
      if (firstNoPassMap === -1) {
        setFightMaps(maps);
      } else {
        setFightMaps(maps.slice(0, firstNoPassMap + 1));
      }
      console.debug('setFightMaps:end');
    });
    console.debug('FightMap.effectCallCount:end', effectCallCount);
  }, []);

  console.debug('fightMaps', fightMaps);
  console.debug('mapIndex', mapIndex);
  console.debug('loadImages', loadImages);
  // if (fightMaps === null || mapIndex === null || loadImages.length < LOAD_IMAGE_AMOUNT) {
  if (fightMaps === null || mapIndex === null) {
    console.debug('loading');
    return <Loading />;
  }
  const fightMap = fightMaps[mapIndex];
  const findCurrentState = fightMaps[mapIndex].fightStages.findIndex(
    (stage) => stage.isPass === false
  );
  const currentStage = findCurrentState != -1 ? findCurrentState : null;
  const stageButtons = fightMap.fightStages.map((stage, index) => {
    console.debug('stage', stage);
    const { left, top } = STAGES_BUTTON_POSITIONS[index];
    if (fight.completedStateId == stage.id) {
      return (
        <div key={`state_${stage.id}`}>
          <div
            className={`${classes.completedStage}`}
            style={{ left: left - 65, top: top - 140 }}
          >
            <img src={images['state-clear.png'] + '?r=' + Date.now()} alt='' />
          </div>
          ;
          <Sound
            url={stateClearSound}
            playStatus='PLAYING'
            onFinishedPlaying={() => {
              console.debug('totalBonus', fight.totalBonus);
              console.debug('issueBonus', fight.issueBonus);
              stateCompletedClear();
            }}
            volume={config.isMutedSound ? 0 : undefined}
          ></Sound>
        </div>
      );
    }
    if (stage.isPass) {
      return (
        <div
          key={`state_${stage.id}`}
          className={`${classes.completedStage}`}
          style={{ left: left - 65, top: top - 140 }}
        >
          <img src={images['state-clear_p9.png'] + '?r=' + Date.now()} alt='' />
        </div>
      );
    }
    if (index === currentStage) {
      return (
        <div
          key={`state_${stage.id}`}
          className={`${classes.buttonStage}`}
          style={{ left, top }}
          onClick={() => goToStage(stage.id)}
        >
          {' '}
        </div>
      );
    }
  });

  function handleNext(e) {
    const newIndex = mapIndex + 1 < fightMaps.length ? mapIndex + 1 : mapIndex;
    setMapIndex(newIndex);
  }

  function handlePrevious(e) {
    const newIndex = mapIndex - 1 < 0 ? mapIndex - 1 : 0;
    setMapIndex(newIndex);
  }

  if (BOUNS_ISSUE.find((x) => x === fight.issueBonus)) {
    console.debug(`display image: point-${fight.issueBonus}.png`);
  }

  function imageLoaded(url) {
    setLoadImages((loadImages) => {
      if (loadImages.find((x) => x === url)) {
        return loadImages;
      }
      return [...loadImages, url];
    });
  }

  console.debug('render:count', componentCallCount);
  return (
    <div style={{ position: 'relative' }}>
      <div
        // style={{
        //   backgroundImage: `url(${fightMap.background})`,
        // }}
        className={classes.root}
      >
        <Sound
          url={mapBackgroundSound}
          playStatus='PLAYING'
          volume={config.isMutedSound ? 0 : 15}
          loop={true}
        ></Sound>
        {currentStage !== null && (
          <img
            src={images[`fade0${currentStage + 1}.png`]}
            className={classes.blackScreen}
            alt=''
          />
        )}
        <LoadImage
          onLoad={imageLoaded}
          url={fightMap.background}
          className={classes.mapBackground}
          alt=''
        />
        {BOUNS_ISSUE.find((x) => x === fight.issueBonus) && (
          <img
            src={images[`point-${fight.issueBonus}.png`]}
            className={classes.bounsIssue}
            alt=''
          />
        )}
        <img
          src={images['back.png']}
          className={`${classes.buttonBack} ${classes.point}`}
          onClick={() => history.goBack()}
          alt=''
        />
        {mapIndex > 0 && (
          <img
            src={images['previous.png']}
            className={`${classes.buttonPrevious} ${classes.point}`}
            alt=''
            onClick={handlePrevious}
          />
        )}
        {mapIndex < fightMaps.length - 1 && (
          <img
            src={images['next.png']}
            className={`${classes.buttonNext} ${classes.point}`}
            alt=''
            onClick={handleNext}
          />
        )}
        {stageButtons}
      </div>
    </div>
  );
}

const mapStateToProps = ({ config, fight, student }) => ({
  config,
  fight,
  student
});

const mapDispatchToProps = {
  stateCompletedClear: actions.stateCompletedClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(FightMap);

// export default FightMap;
