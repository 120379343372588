import React, { Fragment } from 'react';
import spinner from './spinner.gif';
import { makeStyles } from '@material-ui/core/styles';
// import { Spinner } from 'evergreen-ui';

const useStyles = makeStyles(theme => ({
  root: {
    width: '200px',
    margin: 'auto',
    display: 'block',
    position: 'absolute'
  },
  center: {
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)'
  }
}));

const Loading = props => {
  const classes = useStyles();
  return (
    // <Fragment>
    //   {props.version === 2 ? (
    //     <Spinner
    //       className={[
    //         classes.root,
    //         props.align === 'center' ? classes.center : ''
    //       ].join(' ')}
    //     />
    //   ) : (
        <img
          src={spinner}
          className={[
            classes.root,
            props.align === 'center' ? classes.center : ''
          ].join(' ')}
          alt="Loading..."
          onContextMenu={e => e.preventDefault()}
        />
    //   )}
    // </Fragment>
  );
};
export default Loading;
