import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { fromEvent } from 'rxjs';

const useStyles = makeStyles((theme) => ({
  root: {},

  toolBar: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    width: '367px',
    height: '423px',
    top: '150px',
    right: '0px',
    zIndex: 5000,
    transform: 'translateX(282px)',
    cursor: 'url(/images/mouse.png),auto',
  },

  toolBarOpen: {
    '-webkit-animation': '$toolopen .5s forwards',
    '-webkit-animation-delay': '.1s',
    animation: '$toolopen .5s forwards',
    animationDelay: '.1s',
  },

  '@-webkit-keyframes toolopen': {
    '0%': { transform: 'translateX(282px)' },

    '100%': { transform: 'translateX(0px)' },
  },

  '@keyframes toolopen': {
    '0%': { transform: 'translateX(282px)' },
    '100%': { transform: 'translateX(0px)' },
  },

  toolBarClose: {
    transform: 'translateX(0px)',
    '-webkit-animation': '$toolclose .5s forwards',
    '-webkit-animation-delay': '.1s',
    animation: '$toolclose .5s forwards',
    animationDelay: '.1s',
  },

  '@-webkit-keyframes toolclose': {
    '0%': { transform: 'translateX(0px)' },

    '100%': { transform: 'translateX(282px)' },
  },

  '@keyframes toolclose': {
    '0%': { transform: 'translateX(0px)' },
    '100%': { transform: 'translateX(282px)' },
  },

  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },

  selectedItem: {
    '&[go_item]': {
      filter: 'drop-shadow(2px 6px 5px black)',
    },
    '&[death_item]': {
      filter: 'drop-shadow(2px 6px 5px #0160b2)',
    },
  },
  toolBoard: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 4000,
  },
  toolBoardContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  clearBtn: {
    position: 'relative',
    width: '100%',
    height: '85px',
    '& img': {
      position: 'absolute',
      left: '165px',
      bottom: '12px',
    },
  },
  adjustCursor: {
    // '&[selectedtoolitem="go_s"][turn="black"]': {
    //   //cursor: 'url(/images/go_s_black_i.png),auto',
    //   cursor: 'url(/images/go_s_black_i.png),auto',
    // },
    // '&[selectedtoolitem="go_s"][turn="white"]': {
    //   //cursor: 'url(/images/go_s_white_i.png),auto',
    //   cursor: 'url(/images/go_s_white_i.png),auto',
    // },
    // '&[selectedtoolitem="go_m"][turn="black"]': {
    //   //cursor: 'url(/images/go_m_black_i.png),auto',
    //   cursor: 'url(/images/go_s_black_i.png),auto',
    // },
    // '&[selectedtoolitem="go_m"][turn="white"]': {
    //   //cursor: 'url(/images/go_m_white_i.png),auto',
    //   cursor: 'url(/images/go_s_white_i.png),auto',
    // },
    // '&[selectedtoolitem="go_b"][turn="black"]': {
    //   //cursor: 'url(/images/go_b_black_i.png),auto',
    //   cursor: 'url(/images/go_s_black_i.png),auto',
    // },
    // '&[selectedtoolitem="go_b"][turn="white"]': {
    //   //cursor: 'url(/images/go_b_white_i.png),auto',
    //   cursor: 'url(/images/go_s_white_i.png),auto',
    // },
    // '&[selectedtoolitem="death_s"]': {
    //   //cursor: 'url(/images/death_s_i.png),auto',
    //   cursor: 'url(/images/death_s_i.png),auto',
    // },
    // '&[selectedtoolitem="death_m"]': {
    //   //cursor: 'url(/images/death_m_i.png),auto',
    //   cursor: 'url(/images/death_s_i.png),auto',
    // },
    // '&[selectedtoolitem="death_b"]': {
    //   //cursor: 'url(/images/death_m_i.png),auto',
    //   cursor: 'url(/images/death_s_i.png),auto',
    // },
    cursor: 'none',
  },
}));

const ToolBar = (props) => {
  const classes = useStyles();

  const { images } = useImagesContext();
  const [isToolClicked, setIsToolClicked] = useState(false);
  const [isToolbarOpen, setIsToolbarOpen] = useState(false);
  const [selectedToolItem, setSelectedToolItem] = useState('');
  const [boradItemList, setBoradItemList] = useState([]);
  const [turn, setTurn] = useState('black');

  const pointRef = React.useRef({});
  const toolBarRef = React.useRef(null);
  const toolBoardRef = React.useRef(null);
  const boradItemPointRef = React.useRef(null);
  const [cursorIsEnterItem, setCursorIsEnterItem] = useState(false);

  React.useEffect(() => {
    if (props.toolstate === 'init') {
      setBoradItemList([]);
      setIsToolClicked(false);
      setIsToolbarOpen(false);
      setTurn('black');
      setSelectedToolItem('');
    }
  }, [props.toolstate]);

  React.useEffect(() => {
    if (true) {
      const mousemove$ = fromEvent(toolBoardRef.current, 'mousemove');
      const subscription = mousemove$.subscribe((event) => {
        // const selected_tool_item = event.currentTarget.getAttribute(
        //   'selected_tool_item'
        // );
        const rect = event.currentTarget.getBoundingClientRect();
        const xPos =
          event.clientX - rect.left; /*- getAdjustPosLeft(selected_tool_item)*/
        const yPos =
          event.clientY - rect.top; /*- getAdjustPosTop(selected_tool_item)*/
        boradItemPointOnLoadHandler(xPos, yPos);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToolbarOpen]);

  React.useEffect(() => {
    if (boradItemList.length > 0) {
      const refItem = boradItemList[boradItemList.length - 1].ref;
      const mouseleave$ = fromEvent(refItem.current, 'mouseleave');
      const subscription = mouseleave$.subscribe((event) => {
        //console.log('mouseleave::', boradItemList[boradItemList.length - 1]);
        setCursorIsEnterItem(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [boradItemList]);

  React.useEffect(() => {
    const mouseenter$ = fromEvent(toolBarRef.current, 'mouseenter');
    const subscription = mouseenter$.subscribe((event) => {
      //console.log('mouseenter:');
      setCursorIsEnterItem(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  });

  const toolBarOnClickHandler = () => {
    setIsToolClicked(true);
    setIsToolbarOpen((isToolbarOpen) => {
      // if (isToolbarOpen) {
      //   setSelectedToolItem('');
      // }
      return !isToolbarOpen;
    });
  };

  const selectedToolItemOnClickHandler = (selected) => {
    if (selectedToolItem === selected) {
      setSelectedToolItem('');
    } else {
      setSelectedToolItem(selected);
    }
    setCursorIsEnterItem(false);
  };

  const toolBoardOnClickHandler = (event) => {
    if (selectedToolItem !== '') {
      //console.dir(event.currentTarget);
      const rect = event.currentTarget.getBoundingClientRect();

      const xPos =
        event.clientX - rect.left; /*- getAdjustPosLeft(selectedToolItem)*/
      const yPos =
        event.clientY - rect.top; /*- getAdjustPosTop(selectedToolItem)*/
      pointRef.current[xPos + '_' + yPos] = React.createRef();
      const item = {
        ref: pointRef.current[xPos + '_' + yPos],
        type: !selectedToolItem.includes('death')
          ? selectedToolItem + '_' + turn
          : selectedToolItem,
        xPos: xPos,
        yPos: yPos,
      };
      if (boradItemPointRef.current) {
        boradItemPointRef.current.style.visibility = 'hidden';
      }
      setCursorIsEnterItem(true);
      setBoradItemList((boradItemList) => [...boradItemList, item]);
      if (!selectedToolItem.includes('death')) {
        setTurn(turn === 'black' ? 'white' : 'black');
      }
    }
  };

  const boradItemOnLoadHandler = (item) => {
    setTimeout(() => {
      if (item.ref && item.ref.current) {
        const bounding = item.ref.current.getBoundingClientRect();
        item.ref.current.style.visibility = 'visible';
        item.ref.current.style.position = 'absolute';
        item.ref.current.style.top = item.yPos - bounding.height / 2 + 'px';
        item.ref.current.style.left = item.xPos - bounding.width / 2 + 'px';
      }
    });
  };

  const boradItemPointOnLoadHandler = (xPos, yPos) => {
    if (boradItemPointRef && boradItemPointRef.current) {
      const bounding = boradItemPointRef.current.getBoundingClientRect();
      if (!cursorIsEnterItem) {
        boradItemPointRef.current.style.visibility = 'visible';
      }

      boradItemPointRef.current.style.position = 'absolute';
      boradItemPointRef.current.style.top = yPos - bounding.height / 2 + 'px';
      boradItemPointRef.current.style.left = xPos - bounding.width / 2 + 'px';
    }
  };

  const clearOnClickHandler = () => {
    setBoradItemList([]);
    setSelectedToolItem('');
    setTurn('black');
    setCursorIsEnterItem(false);
  };

  // const getAdjustPosLeft = (item = '') => {
  //   if (isTouchDevice()) {
  //     switch (item) {
  //       case 'go_s':
  //         return 65 / 2;
  //       case 'go_m':
  //         return 75 / 2;
  //       case 'go_b':
  //         return 97 / 2;
  //       case 'death_s':
  //         return 100 / 2;
  //       case 'death_m':
  //         return 118 / 2;
  //       case 'death_b':
  //         return 154 / 2;
  //       default:
  //         return 0;
  //     }
  //   }
  //   return item.includes('go_s') || item.includes('go_m')
  //     ? 0
  //     : item.includes('death_b')
  //     ? 38
  //     : item.includes('death_m')
  //     ? 15
  //     : 10;
  // };

  // const getAdjustPosTop = (item = '') => {
  //   if (isTouchDevice()) {
  //     switch (item) {
  //       case 'go_s':
  //         return 65 / 2;
  //       case 'go_m':
  //         return 75 / 2;
  //       case 'go_b':
  //         return 97 / 2;
  //       case 'death_s':
  //         return 100 / 2;
  //       case 'death_m':
  //         return 118 / 2;
  //       case 'death_b':
  //         return 154 / 2;
  //       default:
  //         return 0;
  //     }
  //   }
  //   return item.includes('death_b')
  //     ? 60
  //     : item.includes('death_m')
  //     ? 40
  //     : item.includes('go_s')
  //     ? 20
  //     : item.includes('go_m')
  //     ? 20
  //     : 30;
  // };

  const isTouchDevice = () => {
    //return 'ontouchstart' in window || !!navigator.msMaxTouchPoints;
    var isTouchCapable =
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
    return isTouchCapable;
  };

  console.log('isTouchDevice: ', isTouchDevice());
  console.log('cursorIsEnterItem: ', cursorIsEnterItem);
  console.log('selectedToolItem: ', selectedToolItem);

  return (
    <div
      className={[selectedToolItem !== '' ? classes.adjustCursor : ''].join(
        ' '
      )}
      selectedtoolitem={selectedToolItem}
      turn={turn}
    >
      <div
        ref={toolBarRef}
        className={[
          classes.toolBar,
          isToolClicked
            ? isToolbarOpen
              ? classes.toolBarOpen
              : classes.toolBarClose
            : '',
        ].join(' ')}
        style={{
          backgroundImage: `url(${images['toolbar_opentool.png']})`,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toolBarOnClickHandler();
        }}
      >
        <Grid
          container
          style={{
            width: '100%',

            padding: '25px 0  0 85px',
          }}
          spacing={4}
          wrap="nowrap"
        >
          <Grid
            item
            style={{
              width: '150px',
              height: '330px',
              marginTop: '2px',
              paddingLeft: '24px',
            }}
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <img
                className={[
                  classes.imageHover,
                  selectedToolItem === 'go_b' ? classes.selectedItem : '',
                ].join(' ')}
                src={images['toolbar_go_b.png']}
                id="toolbar_go_b"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectedToolItemOnClickHandler('go_b');
                }}
                go_item=""
                alt=''
              />
            </Grid>
            <Grid item>
              <img
                className={[
                  classes.imageHover,
                  selectedToolItem === 'go_m' ? classes.selectedItem : '',
                ].join(' ')}
                src={images['toolbar_go_m.png']}
                id="toolbar_go_m"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectedToolItemOnClickHandler('go_m');
                }}
                go_item=""
                alt=''
              />
            </Grid>
            <Grid item>
              <img
                className={[
                  classes.imageHover,
                  selectedToolItem === 'go_s' ? classes.selectedItem : '',
                ].join(' ')}
                src={images['toolbar_go_s.png']}
                id="toolbar_go_s"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectedToolItemOnClickHandler('go_s');
                }}
                go_item=""
                alt=''
              />
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              width: '150px',
              height: '330px',
              marginLeft: '20px',
              paddingLeft: '10px',
            }}
            container
            direction="column"
            // spacing={1}
            justify="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '120px',
                  height: '120px',
                }}
              >
                <img
                  style={{
                    objectFit: 'none',
                    objectPosition: '100%',
                  }}
                  className={[
                    classes.imageHover,
                    selectedToolItem === 'death_b' ? classes.selectedItem : '',
                  ].join(' ')}
                  src={images['toolbar_go_death_b.png']}
                  id="toolbar_go_death_b"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectedToolItemOnClickHandler('death_b');
                  }}
                  death_item=""
                  alt=''
                />
              </div>
            </Grid>
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '90px',
                  height: '90px',
                }}
              >
                <img
                  style={{
                    objectFit: 'none',
                    objectPosition: '100%',
                  }}
                  className={[
                    classes.imageHover,
                    selectedToolItem === 'death_m' ? classes.selectedItem : '',
                  ].join(' ')}
                  src={images['toolbar_go_death_m.png']}
                  id="toolbar_go_death_m"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectedToolItemOnClickHandler('death_m');
                  }}
                  death_item=""
                  alt=''
                />
              </div>
            </Grid>
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '80px',
                  height: '80px',
                }}
              >
                <img
                  style={{
                    objectFit: 'none',
                    objectPosition: '100%',
                  }}
                  className={[
                    classes.imageHover,
                    selectedToolItem === 'death_s' ? classes.selectedItem : '',
                  ].join(' ')}
                  src={images['toolbar_go_death_s.png']}
                  id="toolbar_go_death_s"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectedToolItemOnClickHandler('death_s');
                  }}
                  death_item=""
                  alt=''
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.clearBtn}>
          <img
            className={[classes.imageHover].join(' ')}
            src={images['toolbar_tool_clear.png']}
            id="toolbar_tool_clear"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clearOnClickHandler();
            }}
            alt=''
          />
        </div>
      </div>

      <div
        ref={toolBoardRef}
        className={classes.toolBoard}
        onClick={(e) => toolBoardOnClickHandler(e)}
        selected_tool_item={selectedToolItem}
      >
        <div className={classes.toolBoardContainer}>
          {boradItemList.map((it, idx) => (
            <img
              style={{ visibility: 'hidden' }}
              ref={it.ref}
              onLoad={() => boradItemOnLoadHandler(it)}
              src={images[`toolbar_${it.type}_i.png`]}
              key={it.type + idx}
              alt=''
            />
          ))}
          {!cursorIsEnterItem && selectedToolItem !== '' ? (
            <img
              style={{ visibility: 'hidden' }}
              ref={boradItemPointRef}
              src={
                images[
                  `toolbar_${
                    !selectedToolItem.includes('death')
                      ? selectedToolItem + '_' + turn
                      : selectedToolItem
                  }_i.png`
                ]
              }
              alt=''
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ student }) => ({
  student,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);
