import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useImagesContext } from '../../contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  frame: {
    // overflow: 'hidden',
    padding: 24,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0,
  },
  itemFrame: {
    display: 'flex',
    gap: 12,
  },
  itemType: {
    // objectFit: 'contain',
    height: 120,
    // width: '100%',
  },
  itemPower: {
    marginTop: 10,
    // objectFit: 'contain',
    height: 100,
    // width: '100%',
  },
  totalPowerInner: {
    margin: 'auto',
    marginTop: 54,
    width: 450,
    height: 180,
    textAlign: 'right',
    paddingTop: 15,    
  },
  oneDigit: {
    paddingRight: 95,
  },
  twoDigit: {
    paddingRight: 55,
  }
}));

function HitPower({ studentPower }) {
  const classes = useStyles();
  const { images } = useImagesContext();

  console.log('studentPower', studentPower);
  if (!studentPower.items) return null;

  const itemPower = studentPower.items.map((item, index) => {
    return (
      <div key={index} className={classes.item}>
        <img className={classes.itemType} src={images[`${item.item_key}.png`]} alt={item.item_key} />
        <img className={classes.itemPower} src={images[`number-item-${item.item_power}.png`]} alt="power" />
      </div>
    );
  });

  const totalPower = [];
  const twoDigit = Math.floor(studentPower.totalPower / 10) > 0;
  if (Math.floor(studentPower.totalPower / 10) > 0) {
    totalPower.push(<img key={0} src={images[`attack-number-${Math.floor(studentPower.totalPower / 10)}.png`]} alt="power" />);
  }
  totalPower.push(<img key={1} src={images[`attack-number-${studentPower.totalPower % 10}.png`]} alt="power" />);

  return (
    <div
      style={{
        backgroundImage: `url(${images['frame-background.png']})`,
        // backgroundColor: 'green',
        backgroundSize: '100% 100%',
      }}
      className={classes.frame}
    >
      <div className={classes.itemFrame}>
        {itemPower}
      </div>
      <div className={classes.totalPowerInner} style={{
        backgroundImage: `url(${images['total-power.png']})`,
        backgroundSize: '100% 100%',
      }}>
        <div className={twoDigit ? classes.twoDigit: classes.oneDigit}>
          {totalPower}
        </div>
      </div>
    </div>
  );
}

export default HitPower;
