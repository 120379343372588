import * as type from './types';

export const stateCompleted = (stateId, issueBonus, totalBonus) => async dispatch => {
  console.log('stateCompleted.stateId:', stateId);
  return dispatch({
    type: type.FIGHT_STATE_COMPLETED,
    payload: { completedStateId: stateId, issueBonus, totalBonus }
  });
};

export const stateCompletedClear = () => async dispatch => {
  console.log('stateClear');
  return dispatch({
    type: type.FIGHT_STATE_CLEAR,
    payload: {}
  });
};

