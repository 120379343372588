import React, { createContext, useState, useContext, useEffect } from 'react';

import imgLoading from '../images/circle-loading-animation.gif';

export const ImagesContext = createContext({
  images: {}
});

export const useImagesContext = () => useContext(ImagesContext);

export const ImagesProvider = props => {
  const [images, setImages] = useState({});
  const [imagesReadyCnt, setImagesReadyCnt] = useState(0);
  const { r } = props;

  // import and preload images
  useEffect(() => {
    const importedImages = {};
    let i = 0;
    r.keys().forEach(item => {
      const importedImg = r(item);
      const path = item.replace(/(.*\/).*/g, (m, g1) => g1);
      importedImages[item.replace(path, '')] = importedImg;
      i++;
      setImagesReadyCnt(i);
      // const img = new Image();
      // img.onload = () => {
      //   i++;
      //   setImagesReadyCnt(i);
      // };
      // console.debug(item, importedImg);
      // img.src = importedImg;
    });
    setImages(importedImages);
  }, [r]);

  if (Object.keys(images).length !== imagesReadyCnt || imagesReadyCnt < 1) {
    return (
      // <Background className={props.intro ? 'intro' : ''}>
      // <Loader />
      // </Background>
      // <h1>Loading</h1>
      <img src={imgLoading} onContextMenu={e => e.preventDefault()} alt='' />
    );
  }

  if (props.intro) {
    return (
      // <Background className="intro">
      <ImagesContext.Provider value={{ images }}>
        {props.children}
      </ImagesContext.Provider>
      // </Background>
    );
  }

  return (
    <ImagesContext.Provider value={{ images }}>
      {props.children}
    </ImagesContext.Provider>
  );
};

export const ImagesConsumer = ImagesContext.Consumer;
