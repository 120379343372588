import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../contexts/ImagesContext';
import { Fragment } from 'react';
import Sound from 'react-sound';
import { connect } from 'react-redux';

import * as configActions from '../../actions/config';
import soundIntoFight from '../../sounds/fight/into-fight.mp3';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '1920px',
    height: '1080px',
    overflow: 'hidden',
  },
  star: {
    widows: '100%',
    textAlign: 'center',
    marginTop: 54
  },
  ruleFrame: {
    marginTop: 100
  },
  ruleMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ruleText: {
    width: 750,
    fontFamily: "PSLxText",
    fontSize: '8em',
    color: '#5d4008',
    // textStrokeWidth: '1px',
    // textStrokeColor: 'white',
  },
  ruleTextSeleted: {
    width: 750,
    fontFamily: "PSLxText",
    // fontWeight: 'bold',
    fontSize: '8em',
    color: '#5d4008',
    textStrokeWidth: '1px',
    textStrokeColor: 'white',
  },
  secretButton: {
    width: 300,
    height: 300,
    // border: '8px solid red',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },

  starAnimation: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  starAnimationImage: {
    width: '1920px',
    height: '1080px',
  }
}));

const MAGIC_RULES = ['ตั้งใจเล่น คิดก่อนวาง', 'ชนะในเกมการเล่น', 'สกัดจุดเพื่อจับกิน', 'เดินรอด 2 ห้อง', 'บอกผ่านเมื่อจบเกม']
// const MAGIC_RULES = ['ตั้งใจเล่น คิดก่อนวาง'];

function MagicRule({ setHitCount, config, history }) {
  const { images } = useImagesContext();
  const classes = useStyles();
  const secretClicked = React.useRef(false);
  const [showStar, setShowStar] = React.useState(false);
  const [ruleSeleted, setRuleSeleted] = React.useState(MAGIC_RULES.map(() => false));

  const magicRules =
    MAGIC_RULES.map((rule, index) => {
      return (<Fragment key={index + 1}>
        <div className={classes.ruleMessage} >
          <div className={ruleSeleted[index] ? classes.ruleTextSeleted : classes.ruleText}>{rule}</div>
          <div onClick={
            () => {
              setRuleSeleted((prev) => {
                // console.debug('prev', prev);
                prev[index] = !prev[index];
                // console.debug('after', prev);
                return [...prev];
              });
            }
          }
          >
            {ruleSeleted[index] ? <img src={images['magic_rule_pressed_button.png']} alt='' /> : <img src={images['magic_rule_not_pressed_button.png']} alt='' />}
          </div>
        </div >
      </Fragment>
      )
    });

  return (
    <div
      style={{
        backgroundImage: `url(${images['magic_rule_background.jpg']})`,
        backgroundRepeat: 'no-repeat'
      }}
      className={classes.root}
    >
      <div className={classes.star} onClick={() => {
        if (secretClicked.current) {
          setShowStar(true);
        }
      }}>
        <img src={images['magic_rule_star.png']} alt='' />
      </div>
      <div className={classes.ruleFrame}>
        {magicRules}
      </div>
      <div className={classes.secretButton} onClick={() => {
        secretClicked.current = true;
      }}></div>
      {showStar && <>
        <div className={classes.starAnimation}>
          <img src={images['star-into.png'] + '?r=' + Date.now() } className={classes.starAnimationImage} alt='' />
        </div>
        <Sound
          url={soundIntoFight}
          playStatus='PLAYING'
          onFinishedPlaying={() => {
            const hitCount = ruleSeleted.filter(selected => selected == true).length
            console.debug('hitCount', hitCount);
            setHitCount(hitCount);

          }}
          volume={config.isMutedSound ? 0 : undefined}
        ></Sound>
      </>}
    </div>)
};


const mapStateToProps = ({ config }) => ({
  config,
});

const mapDispatchToProps = {
  setMuteSound: configActions.setMuteSound,
};

export default connect(mapStateToProps, mapDispatchToProps)(MagicRule);

