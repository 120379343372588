import { SET_SELECTED_BRANCH } from '../actions/types';

// const initialState = { selectedBranch: { id: 1 } };
const branch = JSON.parse(window.localStorage.getItem('branch'));
const initialState = { selectedBranch:  branch};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_BRANCH:
      //console.log('SET_SELECTED_BRANCH:', payload.value);
      return { ...state, selectedBranch: payload.value };
    default:
      return state;
  }
}
