import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import { joinClasses } from '../utils/utility';

const useStyles = makeStyles((theme) => ([
  // keyframes
  {
    effectFadeIn: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    effectFadeOut: {
      from: { opacity: 1 },
      to: { opacity: 0 },
    },
  },
  {
    effectIn: {
      // animation: '$effectFadeIn 5s infinite',
      animation: '$effectFadeIn 10s infinite',
    },
    effectOut: {
      animation: '$effectFadeOut 5s infinite',
    },
  }
]));

// const useStyles = makeAnimations({
//   root: {
//     keyframes: [{
//       from: { opacity: 0 },
//       to: { opacity: 1 },
//     }],
//     duration: '5s',
//     timingFunction: 'infinite',
//   },
//   foo: {
//     keyframes: [{
//       from: { opacity: 1 },
//       to: { opacity: 0 },
//     }],
//     duration: '5s',
//     timingFunction: 'infinite',
//   },
// });

const Test = () => {
  const c = joinClasses(useStyles());
  return (

    <Fade in={true} timeout={2000}>
      {/* Your component code here */}
      <img src={"http://localhost:5000/files/lessons/1_effect.png"} alt='' />
    </Fade>    
  );
};

export default Test;
