import axios from 'axios';
import config from './config';

const hostUrl = config.hostUrl;

function sleep(ms = 1000) {
  console.log('Kindly remember to remove `sleep`');
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: hostUrl
});

// axiosInstance.interceptors.response.use(async (response) => {
//   if (process.env.NODE_ENV === 'development') {
//     await sleep();
//   }
//   return response;
// });


export default axiosInstance;
