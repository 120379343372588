import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
// import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import imgLoading from '../../../../../images/circle-loading-animation1.gif';
import UIfx from 'uifx';
import {
  getChapterItems as getChapterItemsService,
  updateChapterState as updateChapterStateService,
  // successHandler,
  errorHandler,
} from '../../../../../services/fuseki';
import { fromEvent } from 'rxjs';
import YoutubeEmbed from '../../../../YoutubeEmbed/YoutubeEmbed';

import config from '../../../../../config';
import soundDisplaySummaryScoreFile from '../../../../../sounds/28.mp3';

const hostUrl = config.hostUrl;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  grid: {
    marginTop: '50px',
  },
  imageFusekiLabel: {
    filter: 'drop-shadow(0px 4px 2px black)',
    width: 'fit-content',
    transform: 'translateX(-15px)',
  },
  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
    '&[markstate="true"]': {
      //opacity: 0.75,
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
    '&[markoldpoint="true"]': {
      filter: 'grayscale(45%)',
    },
  },
  imageDisable: {
    // '&:hover': {
    //   transform: 'scale(1.05)',
    //   filter: 'drop-shadow(0px 4px 2px black)',
    // },
    // '&[markstate="true"]': {
    //   //opacity: 0.75,
    //   transform: 'scale(1.05)',
    //   filter: 'drop-shadow(0px 4px 2px black)',
    // },
    // '&[markoldpoint="true"]': {
    //   filter: 'grayscale(45%)',
    // },
    opacity: 0.1,
  },
  imageStar: {},
  imageGo: {
    marginLeft: '35px',
  },
  imageClock: {
    marginLeft: '35px',
  },
  imageHint: {},
  imageHintEffect: {
    position: 'absolute',
    left: '-32px',
  },
  imageWrongEffect: {
    position: 'absolute',
    left: '-32px',
  },
  imageClearEffect: {
    position: 'absolute',
    width: '1920px',
    height: '1080px',
    zIndex: '9999',
  },
  imageHintNum: {
    height: 'fit-content',
  },
  imageWrongNum: {
    height: 'fit-content',
  },
  imageWrong: {
    transform: 'translateX(-4px) rotate(-3deg)',
  },
  imageBack: {
    marginLeft: '20px',
  },
  controlBarContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '440px',
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      padding: '15px',
    },
  },
  starContainer: {
    padding: '0 60px',
  },
  displayBoxContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
  },
  imageDisplay: {
    position: 'absolute',
    top: '36px',
    left: '8px',
    width: '1438px',
    height: '1012px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  summaryScore: {
    position: 'absolute',
    zIndex: '9999',
    width: '1935px',
    height: '1083px',
    top: 0,
  },
  imageSumaryScoreEffect: {
    position: 'absolute',
    left: '530px',
    top: '110px',
    width: '820px',
    height: '600px',
  },
  score: {
    position: 'absolute',
    top: 'calc(50% - 235px)',
    '&[digit="1"]': {
      left: 'calc(50% - 80px)',
    },
    '&[digit="2"]': {
      left: 'calc(50% - 125px)',
    },
    transform: 'translateX(8px) scale(1.06)',
    fontSize: '10rem',
    textShadow:
      '2px 0px 0px #fff, 0px 2px 0px #fff, 0px -2px 0px #fff, -2px 0px 0px #fff',
  },
  footer: {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 170px)',
    left: 'calc(50% + 40px)',
  },
}));

const soundDispSummaryScore = new UIfx(soundDisplaySummaryScoreFile);

const initialStars = [...Array(20)].map(() => 0);

const Chapter = (props) => {
  const classes = useStyles();
  const { student, backHandler, fuseki, config } = props;
  const { images } = useImagesContext();

  const [chapter, setChapter] = useState(null);
  const [stars, setStars] = useState(initialStars);
  const [starsHistory, setStarsHistory] = useState([initialStars]);
  const [curScore, setCurScore] = useState(0);
  const [pictureIdx, setPictureIdx] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hoverPos, setHoverPos] = useState(-1);
  const [random, setRandom] = useState(0);
  // const [random2, setRandom2] = useState(0);
  const [isDispSummaryScore, setIsDispSummaryScore] = useState(false);
  // const [selectedIdx, setSelectedIdx] = useState(-1);
  const [isRenderChapter, setIsRenderChapter] = useState(false);
  const isInited = React.useRef(false);
  const refPoints = React.useRef({});
  const refBoardPoints = React.createRef();
  Array.from(Array(20).keys()).forEach((it, idx) => {
    refPoints.current[idx] = React.createRef();
  });

  React.useEffect(() => {
    let subscription = {};
    Array.from(Array(20).keys()).forEach((it, idx) => {
      const mouseenter$ = fromEvent(
        refPoints.current[idx].current,
        'mouseenter'
      );
      subscription[idx] = mouseenter$.subscribe((evt) => {
        // const idx = evt.target.attributes.getNamedItem('idx');
        //console.log('idx::', idx);
        setHoverPos(idx);
      });
    });

    return () => {
      Array.from(Array(20).keys()).forEach((it, idx) => {
        subscription[idx].unsubscribe();
      });
    };
  });

  React.useEffect(() => {
    const mouseleave$ = fromEvent(refBoardPoints.current, 'mouseleave');
    const subscription = mouseleave$.subscribe((evt) => {
      setHoverPos(-1);
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  React.useEffect(() => {
    //getChapterItems();
  }, []);

  const initial = () => {
    if (!isInited.current) {
      getChapterItems();
      isInited.current = true;
    }
  };

  const backOnClickHandler = () => {
    backHandler();
  };

  const getChapterItems = () => {
    getChapterItemsService(student.id, fuseki.id)
      .then((resp) => {
        console.debug('getChapterItems', resp);
        setChapter(resp.data);
        const stars = [...Array(20)].map((_, i) =>
          i + 1 <= (resp.data.point || 0) ? 1 : 0
        );
        setStars(stars);
        setStarsHistory([[...stars]]);
        setCurScore(resp.data.point || 0);
      })
      .catch((err) => {
        console.error(err);
        setChapter(null);
        errorHandler(err);
      });
  };

  const summaryScoreOnClickHandler = (val) => {
    setIsDispSummaryScore(val);
    if (!val) {
      backOnClickHandler();
    }
  };

  const nextPicture = () => {
    console.debug('nextPicture', pictureIdx);
    if (chapter && chapter.contents) {
      setPictureIdx((pictureIdx) =>
        pictureIdx + 1 <= (chapter.contents || []).length - 1
          ? pictureIdx + 1
          : pictureIdx
      );
      setRandom(Math.random());
    }
  };

  const prevPicture = () => {
    console.debug('prevPicture', pictureIdx);
    if (chapter && chapter.contents) {
      setPictureIdx((pictureIdx) =>
        pictureIdx - 1 >= 0 ? pictureIdx - 1 : pictureIdx
      );
      setRandom(Math.random());
    }
  };

  const starOnClickHandler = (selectedIdx) => {
    stars.forEach((_, idx) => (stars[idx] = 0));
    Array.from(Array(selectedIdx + 1).keys()).forEach((it, idx) => {
      stars[idx] = 1;
    });

    setStars([...stars]);
    setStarsHistory([[...stars], ...starsHistory]);
  };

  const starOnDblClickHandler = (selectedIdx) => {
    stars[selectedIdx] = 0;
    setStars([...stars]);
    setStarsHistory([[...stars], ...starsHistory]);
  };

  const replayImgOnClickHandler = () => {
    // console.log('starsHistory::', starsHistory);
    // if (starsHistory.length > 1) {
    //   starsHistory.shift();
    //   setStars([...starsHistory[0]]);
    //   setStarsHistory([...starsHistory]);
    // }
    setRandom(Math.random());
  };

  const saveOnClickHandler = () => {
    // Swal.fire({
    //   title: 'บันทึกข้อมูล',
    //   text: 'ต้องการบันทึกข้อมูลหรือไม่?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   confirmButtonText: 'บันทึก',
    //   cancelButtonColor: '#d33',
    //   cancelButtonText: 'ยกเลิก',
    // }).then((result) => {
    //   if (result.value) {
    updateChapterState();
    //   }
    // });
  };

  const sumaryScoreEffectHandler = () => {
    if (isDispSummaryScore) {
      !config.isMutedSound && soundDispSummaryScore.play(0.4);
    }
  };

  const updateChapterState = () => {
    if (stars.filter((it) => !!it).length === curScore) {
      setIsDispSummaryScore(true);
    } else {
      updateChapterStateService(
        student.id,
        fuseki.id,
        stars.filter((it) => !!it).length
      )
        .then((resp) => {
          setIsDispSummaryScore(true);
          setCurScore(resp.data.point || 0);
          //successHandler();
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  };

  // console.log('chapter::', chapter);
  // console.log('pictureIdx::', pictureIdx);
  // console.log('stars::', stars);
  // if (chapter.images) {
  //   console.log('chapter.images[pictureIdx]::', chapter.images[pictureIdx]);
  // }

  return (
    <div
      style={{
        backgroundImage: `url(${images['fsk_chapter_background.jpg']})`,
        overflow: 'hidden',
      }}
      className={classes.root}
      onLoad={initial}
    >
      <div className={classes.container}>
        <Grid container style={{ height: '100%' }}>
          <Grid item className={classes.controlBarContainer}>
            <img
              className={classes.imageFusekiLabel}
              src={images['fsk_fuseki_label.png']}
              id='fsk_fuseki_label'
              alt=''
            />
            {chapter && chapter.contents.length > 1 ? (
              <div className={classes.controlContainer}>
                <img
                  className={
                    pictureIdx !== 0 ? classes.imageHover : classes.imageDisable
                  }
                  src={images['fsk_chapter_previous.png']}
                  id='fsk_chapter_previous'
                  onClick={pictureIdx !== 0 ? prevPicture : null}
                  alt=''
                />
                <img
                  className={
                    pictureIdx !== chapter.contents.length - 1
                      ? classes.imageHover
                      : classes.imageDisable
                  }
                  src={images['fsk_chapter_play.png']}
                  id='fsk_chapter_play'
                  onClick={
                    pictureIdx !== chapter.contents.length - 1
                      ? nextPicture
                      : null
                  }
                  alt=''
                />
                <img
                  className={classes.imageHover}
                  src={images['fsk_chapter_replay.png']}
                  id='fsk_chapter_replay'
                  onClick={replayImgOnClickHandler}
                  alt=''
                />
              </div>
            ) : (
              <div style={{ height: 80 }}></div>
            )}
            <div className={classes.starContainer} ref={refBoardPoints}>
              <Grid container justify='center' spacing={1}>
                {Array.from(Array(20).keys()).map((it, idx) => (
                  <Grid item>
                    <img
                      ref={refPoints.current[idx]}
                      idx={idx}
                      className={classes.imageHover}
                      markoldpoint={
                        stars[idx] && hoverPos > -1 && hoverPos !== idx
                          ? 'true'
                          : 'false'
                      }
                      markstate={
                        stars[idx]
                          ? 'false'
                          : idx <= hoverPos
                          ? 'true'
                          : 'false'
                      }
                      src={
                        stars[idx] && hoverPos === -1
                          ? images['fsk_chapter_star.png']
                          : hoverPos !== -1 && idx <= hoverPos
                          ? images['fsk_chapter_star.png']
                          : images['fsk_chapter_starblank.png']
                      }
                      id='fsk_chapter_starblank'
                      onClick={() => starOnClickHandler(idx)}
                      onDoubleClick={() => starOnDblClickHandler(idx)}
                      alt=''
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
          <Grid item xs container justify='flex-start' alignItems='center'>
            <div
              className={classes.displayBoxContainer}
              // style={{
              //   backgroundImage: `url(${images['fsk_chapter_displaybox.png']})`,
              // }}
              id='fsk_chapter_displaybox'
            >
              <div style={{ height: 100 }}></div>
              {chapter &&
                chapter.contents[pictureIdx].mediaType === 'image' && (
                  <img
                    hidden={!isRenderChapter}
                    className={classes.imageDisplay}
                    src={`${
                      chapter.contents[pictureIdx].image + '?a=' + random
                    }`}
                    id='imgfsk_chapter_displaybox'
                    ref={(ref) => {
                      if (ref) {
                        //console.log('refff:::', ref.complete);
                        if (!ref.complete) {
                          setIsLoading(true);
                          ref.onload = function () {
                            setTimeout(() => {
                              setIsLoading(false);
                              setIsRenderChapter(true);
                            });
                          };
                        }
                      }
                    }}
                    alt=''
                  />
                )}
              {chapter &&
                chapter.contents[pictureIdx].mediaType === 'youtube' && (
                  <YoutubeEmbed
                    embedId={chapter.contents[pictureIdx].youtubeEmbedId}
                    onLoad={() => {
                      setIsLoading(false);
                    }}
                  />
                )}
              {chapter &&
                chapter.contents[pictureIdx].mediaType === 'video' && (
                  <video
                    id='videoPlayer'
                    width='100%'
                    controls
                    controlsList='nodownload'
                    // muted="muted"
                    // autoplay
                  >
                    <source
                      src={`${hostUrl}/api/fuseki/video/${chapter.contents[pictureIdx].video}`}
                      type='video/mp4'
                    />
                  </video>
                )}
            </div>
          </Grid>
        </Grid>
      </div>
      <footer id='footer' className={classes.footer} spacing={4}>
        <Grid container>
          <Grid style={{ zIndex: 5700 }} item xs={1} container>
            <img
              className={[classes.imageBack, classes.imageHover].join(' ')}
              src={images['back.png']}
              id='chapter_back'
              onClick={backOnClickHandler}
              alt=''
            />
          </Grid>
          <Grid
            style={{ zIndex: 5700, marginLeft: '150px' }}
            item
            xs={1}
            container
            alignItems='center'
          >
            <img
              style={{ height: 'fit-content' }}
              className={[classes.imageHover].join(' ')}
              src={images['fsk_chapter_save.png']}
              id='chapter_save'
              onContextMenu={(e) => e.preventDefault()}
              onClick={saveOnClickHandler}
              alt=''
            />
          </Grid>
        </Grid>
      </footer>
      {isLoading ? (
        <img className={classes.loading} src={imgLoading} alt='' />
      ) : null}
      {isDispSummaryScore ? (
        <div
          className={classes.summaryScore}
          style={{
            backgroundImage: `url(${images['fsk_summary_score.png']})`,
          }}
          onClick={() => summaryScoreOnClickHandler(false)}
        >
          <span
            digit={String(stars.filter((it) => !!it).length).length}
            className={classes.score}
          >
            {stars.filter((it) => !!it).length}
          </span>
          <img
            onLoad={() => sumaryScoreEffectHandler(isDispSummaryScore)}
            className={classes.imageSumaryScoreEffect}
            src={images['fsk_summary_score_effect.gif']}
            alt=''
          />
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter);
