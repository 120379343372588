import React from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Dashboard from '../components/dashboard/Dashboard';
import Home from '../components/home/Home';
import Login from '../components/login/Login';
import PrivateRoute from '../components/routing/PrivateRoute';
import SelectBranch from '../components/select-branch/SelectBranch';
import SelectStudent from '../components/select-student/SelectStudent';
import Hall from '../components/hall/Hall';
import { BookOpenBackward, BookOpenForward } from '../components/utils/book';
import { ImagesProvider, useImagesContext } from '../contexts/ImagesContext';


const HomeImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      '../components/home/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Home {...props} />
  </ImagesProvider>
);

const LoginImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      '../components/login/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Login {...props} />
  </ImagesProvider>
);

const DashboardImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      '../components/dashboard/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Dashboard {...props} />
  </ImagesProvider>
);

const SelectBranchImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      '../components/select-branch/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <SelectBranch {...props} />
  </ImagesProvider>
);

const SelectStudentImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      '../components/select-student/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <SelectStudent {...props} />
  </ImagesProvider>
);

const HallImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context('../components/hall/images', false, /\.(png|jpe?g|svg|gif)$/)}
  >
    <Hall {...props} />
  </ImagesProvider>
);

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 1920,
    height: 1080,
  },
}));


const BookRoute = ({ bookForward, bookBackward }) => {
  const classes = useStyles();
  const { images } = useImagesContext();
  console.debug('BookRoute:images', images);
  return (
    <div
      className={classes.container}
      style={{
        // backgroundImage: `url(${images['background.png']})`,
        backgroundImage: `url(${images['book.png']}), url(${images['background.png']})`,
        // backgroundImage: `url(${bg})`,
      }}
    >
      <BookOpenForward open={bookForward} />
      <BookOpenBackward open={bookBackward} />
      <Switch>
        <Route exact path='/login' component={LoginImages} />
        <PrivateRoute exact path='/'>
          <Redirect to='/home' />
        </PrivateRoute>
        <PrivateRoute exact path='/home' component={HomeImages} />
        <PrivateRoute exact path='/branch' component={SelectBranchImages} />
        <PrivateRoute
          exact
          path='/students'
          component={SelectStudentImages}
        />
        <PrivateRoute
          exact
          path='/dashboard'
          // studentRequired={true}
          component={DashboardImages}
        />
        <PrivateRoute path='/hall' component={HallImages} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ book }) => ({
  bookForward: book.bookForward,
  bookBackward: book.bookBackward,
});
export default connect(mapStateToProps, null)(BookRoute);