import React from 'react';
import { useImagesContext } from '../../contexts/ImagesContext';

export const NumberImageRed = ({ number, height }) => {
  const { images } = useImagesContext();
  const imageTagList = [];
  let dividedResult = number;
  let key = 1;
  while (dividedResult > 0) {
    const oneDigitNumber = dividedResult % 10;
    imageTagList.push(
      <img
        style={{ height, position: 'relative' }}
        key={key}
        src={images[`number-red-${oneDigitNumber}.png`]}
        alt=''
      />
    );
    dividedResult = Math.floor(dividedResult / 10);
    key += 1;
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {imageTagList.reverse()}
    </div>
  );
};

export const NumberImageYellow = ({ number, height }) => {
  const { images } = useImagesContext();
  const imageTagList = [];
  let dividedResult = number;
  let key = 1;
  // console.debug('NumberImageYellow:number', number);
  if (number == null || number < 0) {
    imageTagList.push(<div style={{ height, position: 'relative' }}></div>);
  }
  if (number === 0) {
    imageTagList.push(
      <img
        style={{ height, position: 'relative' }}
        key={key}
        src={images[`number-yellow-0.png`]}
        alt=''
      />
    );
  } else {
    while (dividedResult > 0) {
      const oneDigitNumber = dividedResult % 10;
      console.debug(`number-yellow-${oneDigitNumber}}.png`);
      imageTagList.push(
        <img
          style={{ height, position: 'relative' }}
          key={key}
          src={images[`number-yellow-${oneDigitNumber}.png`]}
          alt=''
        />
      );
      dividedResult = Math.floor(dividedResult / 10);
      key += 1;
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {imageTagList.reverse()}
    </div>
  );
};

export const TextImageYellow = ({ text, height }) => {
  const { images } = useImagesContext();
  const imageTagList = [];
  if (text == null || text == '') {
    imageTagList.push(<div style={{ height, position: 'relative' }}></div>);
  } else {
    for (let i = 0; i < text.length; i++) {
      const c = text.charAt(i);
      imageTagList.push(
        <img
          style={{ height, position: 'relative' }}
          key={i + 1}
          src={images[`number-yellow-${c}.png`]}
          alt=''
        />
      );
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {imageTagList}
    </div>
  );
};
