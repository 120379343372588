import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './fonts/PSLxText.ttf';
import './fonts/PSLxTextBold.ttf';
import './fonts/PSLxTextBoldItalic.ttf';
import './fonts/PSLxTextItalic.ttf';

import BonusDashboard from './components/bonus-dashboard/BonusDashboard';
import CheckTouchDevice from './components/check-touch-device/CheckTouchDevice';
import Fight from './components/fight/Fight';
import Player from './components/game-play/Player/Player';
import Lesson from './components/lesson/Lesson';
import Fuseki from './components/mission/Fuseki/Fuseki';
import Mission from './components/mission/Mission';
import Problem from './components/mission/Problem/Problem';
import Planet from './components/planet/Planet';
import PlayBoard from './components/play-board/PlayBoard';
import Register from './components/register/Register';
import PrivateRoute from './components/routing/PrivateRoute';
import State from './components/state/State';
import Wrapper from './components/wrapper/Wrapper';
import { ImagesProvider } from './contexts/ImagesContext';

import './App.css';
import Test from './components/Test';
import store from './store';
import BookRoute from './templates/BookRoute';
import setAuthToken from './utils/setAuthToken';
import config from './config';

if (localStorage.token) {
  //localStorage.removeItem('token');
  // console.debug(`localStorage.token:${localStorage.token}`);
  setAuthToken(localStorage.token);
}

const RegisterImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/register/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Register {...props} />
    </Wrapper>
  </ImagesProvider>
);

const PlanetImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/planet/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Planet {...props} />
    </Wrapper>
  </ImagesProvider>
);

const PlayBoardImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/play-board/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <PlayBoard {...props} />
    </Wrapper>
  </ImagesProvider>
);

const StateImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/state/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <State {...props} />
    </Wrapper>
  </ImagesProvider>
);

const LessonImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/lesson/images/',
      false,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Lesson {...props} />
    </Wrapper>
  </ImagesProvider>
);

const MissionImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/mission/images/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Mission {...props} />
    </Wrapper>
    {/* <Mission {...props} /> */}
  </ImagesProvider>
);

const ProblemImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/mission/Problem/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Problem {...props} />
    </Wrapper>
  </ImagesProvider>
);

const FusekiImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/mission/Fuseki/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Fuseki {...props} />
    </Wrapper>
  </ImagesProvider>
);

const GamePlayImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/game-play/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <Player {...props} />
    </Wrapper>
  </ImagesProvider>
);

// const GamePlayImages2 = (props) => (
//   <ImagesProvider
//     intro={false}
//     r={require.context(
//       './components/game-play/',
//       true,
//       /\.(png|jpe?g|svg|gif)$/
//     )}
//   >
//     <Wrapper>
//       <GamePlay2 {...props} />
//     </Wrapper>
//   </ImagesProvider>
// );

const BonusDashboardImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      './components/bonus-dashboard/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Wrapper>
      <BonusDashboard {...props} />
    </Wrapper>
  </ImagesProvider>
);

const FightImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context('./components/fight/', true, /\.(png|jpe?g|svg|gif)$/)}
  >
    <Wrapper>
      <Fight {...props} />
    </Wrapper>
  </ImagesProvider>
);

const BookRouteImages = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context('./templates/images', true, /\.(png|jpe?g|svg|gif)$/)}
  >
    <Wrapper>
      <BookRoute {...props} />
    </Wrapper>
  </ImagesProvider>
);

function App() {
  return (
    // <React.StrictMode>
    <Provider store={store}>
      <Router basename={config.homepage}>
        <Switch>
          <Route exact path='/test' component={Test} />
          <Route exact path='/check-device' component={CheckTouchDevice} />
          <Route exact path='/game-play' component={GamePlayImages} />
          {/* <Route exact path="/game-play2" component={GamePlayImages2} /> */}
          <PrivateRoute exact path='/register' component={RegisterImages} />

          <PrivateRoute
            exact
            path='/play-board'
            component={PlayBoardImages}
          />
          <PrivateRoute exact path='/planet' component={PlanetImages} />
          <PrivateRoute
            exact
            path='/state/:level'
            component={StateImages}
          />
          <PrivateRoute
            exact
            path='/lesson/:level/:state'
            component={LessonImages}
          />
          <PrivateRoute exact path='/mission' component={MissionImages} />
          <PrivateRoute
            exact
            path='/mission/problem'
            component={ProblemImages}
          />
          <PrivateRoute exact path='/mission/fuseki' component={FusekiImages} />
          <PrivateRoute exact path='/game-play' component={GamePlayImages} />
          <PrivateRoute
            exact
            path='/bonus-dashboard'
            component={BonusDashboardImages}
          />
          <PrivateRoute path='/fight' component={FightImages} />
          <Route path='/' component={BookRouteImages} />
        </Switch>
      </Router>
    </Provider>
    // </React.StrictMode>
  );
}

export default App;
