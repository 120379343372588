import * as type from './types';

export const setSelectedBranch = (val) => async (dispatch) => {
  if (!val) {
      window.localStorage.removeItem('branch');
  } else {
      window.localStorage.setItem('branch', JSON.stringify(val));
  }  
  return dispatch({
    type: type.SET_SELECTED_BRANCH,
    payload: { value: val },
  });
};
