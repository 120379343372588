export const joinClasses = (classes) => (sClasses) =>
    sClasses
        .split(' ')
        .map((c) => classes[c])
        .join(' ');

export function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function delay(fn, ms) {
    let timer = 0;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(fn.bind(this, ...args), ms || 0);
    };
}

export function extractErrorRespMsg(respMsg) {
    let errorMsg;
    if (typeof respMsg === 'object') {
        if (respMsg?.response?.data) {
            if (typeof respMsg.response.data === 'object') {
                errorMsg =
                    respMsg.response.data.error ||
                    respMsg.response.data.message ||
                    respMsg.response.data.msg;
            } else {
                errorMsg = respMsg.response.data;
            }
        } else if (respMsg.message || respMsg.warning) {
            errorMsg = respMsg.message || respMsg.warning;
        } else {
            errorMsg = respMsg.response;
        }
    } else {
        errorMsg = respMsg;
    }
    return JSON.stringify(errorMsg);
}
