import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Get Student Id
// export const getStudentId = (name) => {
//   const body = JSON.stringify({ name });
//   return axios.post('/api/student/id', body, config);
// };

// Get Student Id v2
export const getStudentId = (name, branchId) => {
  const body = JSON.stringify({ name, branchId: branchId });
  return axios.post('/api/v2/student-select', body, config);
  // return axios.post('/api/student/select', body, config);
};

// Get Student Id
export const getStudentAll = () => {
  return axios.get('/api/student');
};

// Get Student
export const getStudent = (id) => {
  return axios.get(`/api/v2/student/${id}`);
};

// Get Student
export const studentLogIn = (studentId) => {
  const body = JSON.stringify({ studentId });
  return axios.post('/api/v2/student-action/login', body, config);
};

export const studentLogOut = (studentId) => {
  const body = JSON.stringify({ studentId });
  return axios.post('/api/v2/student-action/logout', body, config);
};

// Edit Point
export const editPoint = (id, point, password) => {
  const body = JSON.stringify({ point, password });
  // console.debug('body', body);
  return axios.post(`/api/student/${id}/point`, body, config);
};

// Add Student
// export const addStudent = (name, level, image, branchId) => {
//   let formData = new FormData();
//   formData.append('name', name);
//   formData.append('level', level);
//   formData.append('image', image);
//   formData.append('branchId', branchId);
//   return axios.post('/api/v2/student', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   });
// };
export const addStudent = async (code, name, level, imageUpload, branchId) => {
  console.debug(`editStudent -> imageUpload: ${imageUpload}`);
  let image;
  if (imageUpload) {
    const formData = new FormData();
    formData.append('image', imageUpload);
    formData.append('imageType', 'student_images')
    const res = await axios.post('/api/v2/image-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    image = res.data.imageUrl;
  }
  return axios.post(`/api/v2/student`, { code, name, level, branchId ,image} , config);
};

// Get PlayBoard
export const getPlayBoard = (id) => {
  return axios.get(`/api/play-board/${id}`);
};

// // Add Result into Playboard
// export const addPlayBoard = (id, result) => {
//   const body = JSON.stringify({ result });
//   // console.debug('addPlayBoard', id, body);
//   return axios.post(`/api/play-board/${id}`, body, config);
// };

// Add Result into Playboard
export const addPlayBoard = (id, result) => {
  const body = JSON.stringify({ result });
  // console.debug('addPlayBoard', id, body);
  return axios.post(`/api/play-board/${id}`, body, config);
};

// Get State
export const getState = (id, level) => {
  return axios.get(`/api/state/${id}/${level}`);
};

// Get Planet
export const getPlanet = (id) => {
  return axios.get(`/api/planet/${id}`);
};

// Get Lesson
export const getLesson = (id, state) => {
  return axios.get(`/api/lesson/${id}/${state}`);
};

// // Pass Lesson
// export const passLesson = (id, lesson, pass) => {
//   const body = JSON.stringify({ pass });
//   // console.debug('body', body);
//   return axios.post(`/api/lesson/${id}/${lesson}/pass`, body, config);
// };
export const passLesson = (id, stateId, lessons) => {
  const body = JSON.stringify({ lessons });
  // console.debug('body', body);
  return axios.post(`/api/lesson/${id}/${stateId}/pass`, body, config);
};

export const bonusPointSettle = (id) => {
  return axios.post(`/api/student/${id}/bonus`, null, config);
};

export const redeemPoint = (id, point) => {
  const body = JSON.stringify({ point });
  return axios.post(`/api/student/${id}/redeem`, body, config);
};

export const finishState = (id, level, state) => {
  return axios.post(`/api/state/${id}/${level}/${state}/finish`, null, config);
};

export const getPreLevel = (_next = null, _catch = null) => {
  axios
    .get(`/api/v2/student-level`, config)
    .then((resp) => {
      _next && _next(resp.data);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    });
};

export const getCode = (branch, _next = null, _catch = null) => {
  axios
    .get(`/api/v2/student-code/` + branch, config)
    .then((resp) => {
      _next && _next(resp.data.code);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    });
};

export const unlock = (password) => {
  // console.debug('body', body);
  return axios.post(`/api/v2/unlock`, { password }, config);
};

export const getPowers = (studentId) => {
  return axios.get(`/api/v2/student-fight/${studentId}/powers`, config);
};

export const getRank = (studentId) => {
  return axios.get(`/api/v2/match-group/${studentId}/rank`, config);
};
