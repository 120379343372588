import {
  //   REGISTER_SUCCESS,
  //   REGISTER_FAIL,
  //   USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
  //   ACCOUNT_DELETED
} from '../actions/types';

// import delay from '../utils/delay';
import setAuthToken from '../utils/setAuthToken';

const token = localStorage.getItem('token');

const initialState = {
  token: token,
  isAuthenticated: token ? true : false,
  loading: false
  //   user: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      // console.debug('reducer LOGIN_SUCCESS wait 10 sec.');
      // await delay(10 * 1000);
      // console.debug('reducer LOGIN_SUCCESS ok');
      localStorage.setItem('token', payload.token);
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    default:
      return state;
  }
}
