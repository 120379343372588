import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';

const PrivateRoute = ({
  logout,
  component: Component,
  auth: { isAuthenticated, loading },
  student,
  studentRequired,
  ...rest
}) => {
  if (isAuthenticated && !localStorage.token) {
    logout();
  }
  console.log('isAuthenticated:', isAuthenticated);
  console.log('loading:', loading);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading) {
          return <Redirect to='/login' />;
        }
        if (studentRequired && student?.id == undefined) {
          return <Redirect to='/students' />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, student }) => ({
  auth,
  student,
});

export default connect(mapStateToProps, { logout })(PrivateRoute);
