import React from "react";

function Image({ url, onLoad, ...props }) {
  const onLoadLog = () => {
    console.log(`url: "${url}" loaded`);
    onLoad(url);
  };
  return <img src={url} onLoad={onLoadLog} {...props} />;
}
export default Image;
