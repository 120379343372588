import axios from '../host';
import Swal from 'sweetalert2';

// const config = {
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };

export const getBookLevelList = (studentId) => {
  return axios.get(`/api/fuseki/student/${studentId}/subjects`);
};

export const getLessonList = (studentId, level) => {
  return axios.get(`/api/fuseki/student/${studentId}/subjects/${level}`);
};

export const getChapterItems = (studentId, id) => {
  return axios.get(`/api/fuseki/student/${studentId}/items/${id}`);
};

export const updateChapterState = (studentId, id, point) => {
  return axios.post(`/api/fuseki/student/${studentId}/item`, {
    id,
    point,
  });
};

export const getLevelTimeout = (level) => {
  return axios.get('/api/problem/levelTimeout/' + level);
};

export const successHandler = () => {
  Swal.fire('บันทึกข้อมูล', 'ทำรายการสำเร็จ', 'success');
};

export const errorHandler = (err) => {
  let errmsg = err;
  if (err.response.data.errors && err.response.data.errors.length > 0) {
    errmsg = err.response.data.errors.map((e) => e.msg).join(', ');
  }
  Swal.fire({
    title: 'เกิดข้อผิดพลาด',
    text: JSON.stringify(errmsg),
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'ตกลง',
  }).then((result) => {});
};
