import * as type from './types';

export const bookOpenForward = uri => async dispatch => {
    return dispatch({
        type: type.BOOK_OPEN_FORWARD,
    });
};

export const bookOpenBackward = uri => async dispatch => {
    return dispatch({
        type: type.BOOK_OPEN_BACKWARD,
    });
};

export const bookOpenDisappear = uri => async dispatch => {
    return dispatch({
        type: type.BOOK_OPEN_DISAPPEAR,
    });
};
