import { JUMP_SAVE, JUMP_CLEAR } from '../actions/types';

const initialState = { jumpUri: undefined };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JUMP_SAVE:      
      return { ...state, jumpUri: payload.jumpUri };
    case JUMP_CLEAR:      
      return { ...state, jumpUri: undefined };
    default:
      return state;
  }
}
