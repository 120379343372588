import { FIGHT_STATE_CLEAR, FIGHT_STATE_COMPLETED } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FIGHT_STATE_COMPLETED:
      const { completedStateId, issueBonus, totalBonus } = payload;
      return { completedStateId, issueBonus, totalBonus };
    case FIGHT_STATE_CLEAR:
      return {};
    default:
      return state;
  }
}
