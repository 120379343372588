import axios from '../host';
import * as type from './types';

export const setSelectStudent = (student) => async (dispatch) => {
  if (!student) {
    window.localStorage.removeItem('student');
  } else {
    window.localStorage.setItem('student', JSON.stringify(student));
  }
  dispatch({
    type: type.STUDENT_SELECT,
    payload: student,
  });
};

export const deselectStudent = () => async (dispatch) => {
  window.localStorage.removeItem('student');
  dispatch({
    type: type.STUDENT_DESELECT,
  });
};

// Get Student
export const getStudentId = (name) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ name });

    const res = await axios.post('/api/student/id', body, config);
    dispatch({
      type: type.SELECT_STUDENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: type.SELECT_STUDENT_FAILED,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get Student
export const getStudent = (id) => async (dispatch) => {
  try {
    console.debug('getStudent', id);
    const res = await axios.get(`/api/student/${id}`);
    console.debug('getStudent', res);
    dispatch({
      type: type.LOAD_STUDENT_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: type.LOAD_STUDENT_DATA_FAILED,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setStudentProblemState =
  (problemId, state) => async (dispatch) => {
    dispatch({
      type: type.STUDENT_PROBLEM_STATE,
      payload: { problemId, state },
    });
  };

export const deductMissionProblemHint = () => async (dispatch) => {
  dispatch({
    type: type.STUDENT_DEDUCT_MISSION_PROB_HINT,
  });
};
