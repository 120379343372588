import axios from '../host';

export function getStudentList(branchId, _next = null, _catch = null) {
  // setTimeout(() => {
  //   _next && _next([{ value: 'item1' }, { value: 'item2' }, { value: 'item3' }, { value: 'item4' }]);
  // }, 1000);
  axios
    .get('/api/v2/student?branchId=' + branchId)
    .then((resp) => {
      _next && _next(resp.data);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    });
}
