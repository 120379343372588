import React, { useState } from 'react';
// import Img from 'react-image';
import { useImagesContext } from '../../contexts/ImagesContext';

// import imgKey from '../../images/planet-key.png';

// import imgEffect from '../../images/effect-03.png';

import './PlanetItem.css';

const PlanetItem = ({
  imgPlanet,
  onClick,
  access,
  unlocked,
  timeEffect = 600
}) => {
  const [unlockedStop, setUnlockStop] = useState(false);

  const { images } = useImagesContext();

  if (unlocked) {
    setTimeout(() => {
      setUnlockStop(true);
    }, timeEffect);
  }

  return (
    <>
      {access === true ? (
        <img
          src={imgPlanet}
          style={{ userSelect: 'none' }}
          onClick={onClick}
          className="my-btn"
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
      ) : (
        <img src={imgPlanet} style={{ userSelect: 'none' }} onContextMenu={(e) => e.preventDefault()} alt='' />
      )}
      {(access === false || (unlocked && !unlockedStop)) && (
        <img
          src={images['planet-key.png']}
          className="img_center"
          style={{ userSelect: 'none' }}
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
      )}
      {unlocked && !unlockedStop && (
        <img
          src={images['effect-03.png']}
          className="img_center"
          style={{ userSelect: 'none' }}
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
      )}
    </>
  );
};

export default PlanetItem;
