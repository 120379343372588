import React, { useState, useEffect, Fragment } from 'react';
import Img from 'react-image';
import Sound from 'react-sound';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useImagesContext } from '../../contexts/ImagesContext';
import { getPlanet as getPlanetService } from '../../services/student';
import { saveStateData } from '../../actions/state';
import PlanetItem from './PlanetItem';
import { joinClasses } from '../../utils/utility';
import { makeStyles } from '@material-ui/core/styles';

import soundPlanetBackground from '../../sounds/06.mp3';
import soundToState from '../../sounds/11.mp3';
import './Planet.css';
// import imgBackground from '../../images/planet-background.jpg';
// import imgLevel13 from '../../images/planet-level13.png';
// import imgLevel12 from '../../images/planet-level12.png';
// import imgLevel11 from '../../images/planet-level11.png';
// import imgLevel15 from '../../images/planet-level15.png';
// import imgLevel14 from '../../images/planet-level14.png';
// import imgBack from '../../images/back.png';
// import imgKey from '../../images/planet-key.png';

// import imgInto from '../../images/effect-02.png';
const useStyles = makeStyles((theme) => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));
const Planet = ({ history, match, saveStateData, config, student }) => {
  // const studentId = match.params.id;
  const studentId = student.id;
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();

  console.debug('images', images);

  const [planet, setPlanet] = useState([]);
  // const [into, setInto] = useState(true);
  const [effect02, setEffect02] = useState(false);
  // const [effectSrc02, setEffectSrc02] = useState('');
  const [sound02, setSound02] = useState('');
  // const [chooseLevel, setChooseLevel] = useState(null);

  let query = new URLSearchParams(useLocation().search);

  const levelup = query.get('levelup');

  useEffect(() => {
    console.debug('useEffect', studentId);
    getPlanetService(studentId)
      .then((res) => {
        console.debug(res);
        setPlanet(res.data);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
          if (error.response.status === 404) {
            history.push('/home');
          }
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error('Error', error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const toStateLink = () => {
  //   history.push(`/state/${chooseLevel}`);
  // };

  const toState = (level) => {
    if (checkAccess(level)) {
      setSound02(`/state/${level}`);
      setEffect02(true);
      saveStateData({});
      // setSound02(true);
      // setTimeout(() => history.push(`/state/${level}`), 1600);
    }
  };

  // console.debug(JSON.stringify(student));

  const checkAccess = (level) => {
    if (planet === null || planet.length === 0) {
      return null;
    }
    for (let i = 0; i < planet.length; i++) {
      if (planet[i].level === level) {
        console.debug('checkAccess', planet[i].access);
        return planet[i].access;
      }
    }
    console.debug('checkAccess not found');
    return false;
  };

  const goBack = () => {
    // history.push(`/hall`);
    history.goBack();
  };

  return (
    <Fragment>
      <Sound
        url={soundPlanetBackground}
        playStatus='PLAYING'
        loop={true}
        volume={config.isMutedSound ? 0 : 50}
      ></Sound>
      <div style={{ position: 'relative' }}>
        <div
          id='wb_Image1'
          style={{
            position: 'relative',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 0,
          }}
        >
          <img
            src={images['planet-background.jpg']}
            id='Image1'
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

        {sound02 && (
          <Sound
            url={soundToState}
            playStatus='PLAYING'
            onFinishedPlaying={() => {
              // setSound02(false);
              history.push(sound02);
              // toStateLink();
            }}
            volume={config.isMutedSound ? 0 : undefined}
          ></Sound>
        )}
        {effect02 && (
          <div
            id='wb_Into'
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: 1920,
              height: 1080,
              zIndex: 20,
            }}
          >
            <img
              src={images['effect-02.png']}
              id='Image1'
              alt=''
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            // left: 973,
            // top: 528
          }}
          className={c(checkAccess(15) ? 'point' : '')}
        >
          <PlanetItem
            imgPlanet={images['planet-level15.png']}
            access={checkAccess(15)}
            unlocked={levelup === 15}
            onClick={() => toState(15)}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            left: 0,
            // top: 445
            bottom: 0,
          }}
          className={c(checkAccess(14) ? 'point' : '')}
        >
          <PlanetItem
            imgPlanet={images['planet-level14.png']}
            access={checkAccess(14)}
            unlocked={levelup === 14}
            onClick={() => toState(14)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 1045,
            top: 0,
          }}
          className={c(checkAccess(13) ? 'point' : '')}
        >
          <PlanetItem
            imgPlanet={images['planet-level13.png']}
            access={checkAccess(13)}
            unlocked={levelup === 13}
            onClick={() => toState(13)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 200,
            top: 0,
          }}
          className={c(checkAccess(12) ? 'point' : '')}
        >
          <PlanetItem
            imgPlanet={images['planet-level12.png']}
            access={checkAccess(12)}
            unlocked={levelup === 12}
            onClick={() => toState(12)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: 660,
            top: 285,
          }}
          className={c(checkAccess(11) ? 'point' : '')}
        >
          <PlanetItem
            imgPlanet={images['planet-level11.png']}
            access={checkAccess(11)}
            unlocked={levelup === 11}
            onClick={() => toState(11)}
          />
        </div>

        <div
          id='wb_Image_back'
          style={{
            position: 'absolute',
            // left: 60,
            // top: 890,
            left: 30,
            bottom: 30,
            // width: 186,
            // height: 130,
            zIndex: 10,
          }}
          className={c('point')}
        >
          <Img
            className='my-btn'
            src={images['back.png']}
            id='Image_back'
            onClick={goBack}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      </div>
    </Fragment>
  );
};

Planet.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  saveStateData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ config, student }) => ({
  student,
  config,
});

export default connect(mapStateToProps, { saveStateData })(Planet);
