import {
  // REGISTER_SUCCESS,
  // REGISTER_FAIL,
  // USER_LOADED,
  // AUTH_ERROR,
  LOGIN_SUCCESS,
  // LOGIN_FAIL,
  LOGOUT
  // CLEAR_PROFILE
} from './types';
// import setAuthToken from '../utils/setAuthToken';

// import delay from '../utils/delay';

// Login User
// export const login = (name, password) => async dispatch => {
//   const config = {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   };

//   const body = JSON.stringify({ name, password });

//   try {
//     const res = await axios.post('/api/auth', body, config);

//     // console.debug('dispatch : login success start');
//     // await delay(10 * 1000);
//     // console.debug('dispatch : login success sleep 10 secs 1');
//     dispatch({
//       type: LOGIN_SUCCESS,
//       payload: res.data
//     });
//     // console.debug('dispatch : login success sleep 10 secs 2');
//     // await delay(10 * 1000);
//     // console.debug('dispatch : login success end');
//     // dispatch(loadUser());
//   } catch (err) {
//     const errors = err.response.data.errors;
//     // if (errors) {
//     //   errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//     // }
//     dispatch({
//       type: LOGIN_FAIL
//     });
//   }
// };

// Login User
export const login = token => async dispatch => {
  window.localStorage.removeItem('branch');
  window.localStorage.removeItem('student');
  dispatch({
    type: LOGIN_SUCCESS,
    payload: token
  });
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  // dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
