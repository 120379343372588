import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import UIfx from 'uifx';
import { getstudentInfo as getStudentInfoService } from '../../../services/game-play';
import { errorHandler } from '../../../utils/messageUtil';
import imgLoading from '../../../images/circle-loading-animation.gif';
import GamePlay from '../GamePlay';
import soundSelectFile from '../../../sounds/29.wav';

// import { useRouteMatch } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  leftContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '500px',
    '& [pic]': {
      width: '400px',
      height: '400px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginTop: '30px',
    },
    '& [capture]': {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      marginTop: '30px',
      marginBottom: '10px',
      '& [imgcapture]': {
        marginBottom: '10px',
      },
    },
    '& [win]': {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      marginTop: '20px',
      '& [imgwin]': {
        marginBottom: '10px',
      },
    },
    '& [capture],[win]': {
      '& [spacebox]': {
        '& img[spaceimg]': {},
        '& img:not([spaceimg])': {
          marginTop: '10px',
        },
      },
    },
  },
  rightContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '500px',
    '& [imgbackground]': {
      width: 'fit-content',
      zIndex: 3000,
    },
  },
  centerContent: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around',
    width: '1000px',
    marginTop: '55px',
    '& [player1icon],[player2icon]': {
      display: 'flex',
      flexFlow: 'column',
      '& div:not(:last-child)': {
        marginBottom: '30px',
      },
    },
    '& [levelinfo]': {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      '& [imglevel]': {
        position: 'absolute',
        width: 'fit-content',
        left: 'calc(50% - 119px)',
        top: 130,
      },
      '& [imglevelup]': {
        position: 'absolute',
        width: 'fit-content',
        left: 'calc(50% - 138px)',
        top: 396,
      },

      '& [imgstart]': {
        position: 'absolute',
        width: 'fit-content',
        left: 'calc(50% - 164px)',
        top: 690,
      },
    },
  },
  imageBack: {
    width: 152,
    marginLeft: '60px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  iconHover: {
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  iconSelected: {
    transform: 'scale(1.3)',
    filter: 'drop-shadow(0px 5px 8px black)',
  },
  bgHover: {
    '&:hover': {
      transform: 'scale(1.2,1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  bgSelected: {
    transform: 'scale(1.2,1.05)',
    filter: 'drop-shadow(0px 6px 4px black)',
  },
  footer: {
    position: 'absolute',
    bottom: '40px',
    left: '0px',
    width: '20%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundSelect = new UIfx(soundSelectFile);

const img_locked2 = 'player_lock2.png';
const img_locked = 'player_lock.png';

const p1_1 = 'player_bird.png';
const p1_2 = 's_icon_01.png';
const p1_3 = 's_icon_02.png';
const p1_4 = 's_icon_03.png';
const p1_5 = 's_icon_04.png';

const p2_1 = 'player_crock.png';
const p2_2 = 't_icon_01.png';
const p2_3 = 't_icon_02.png';
const p2_4 = 't_icon_03.png';
const p2_5 = 't_icon_04.png';

const bg_1 = 'b_icon_01.png';
const bg_2 = 'b_icon_02.png';
const bg_3 = 'b_icon_03.png';
const bg_4 = 'b_icon_04.png';
const bg_5 = 'b_icon_05.png';

// game-play

const g_p1_1 = 'birdplay.png';
const g_p1_2 = 's_nor_01.png';
const g_p1_3 = 's_nor_02.png';
const g_p1_4 = 's_nor_03.png';
const g_p1_5 = 's_nor_04.png';

const g_p2_1 = 'crocoplay.png';
const g_p2_2 = 't_nor_01.png';
const g_p2_3 = 't_nor_02.png';
const g_p2_4 = 't_nor_03.png';
const g_p2_5 = 't_nor_04.png';

const g_cur_p1_1 = 'bcur.png';
const g_cur_p1_2 = 's_cur_01.png';
const g_cur_p1_3 = 's_cur_02.png';
const g_cur_p1_4 = 's_cur_03.png';
const g_cur_p1_5 = 's_cur_04.png';

const g_cur_p2_1 = 'ccur.png';
const g_cur_p2_2 = 't_cur_01.png';
const g_cur_p2_3 = 't_cur_02.png';
const g_cur_p2_4 = 't_cur_03.png';
const g_cur_p2_5 = 't_cur_04.png';

const g_big_p1_1 = 'birdbig.png';
const g_big_p1_2 = 's_big_01.png';
const g_big_p1_3 = 's_big_02.png';
const g_big_p1_4 = 's_big_03.png';
const g_big_p1_5 = 's_big_04.png';

const g_big_p2_1 = 'crocobig.png';
const g_big_p2_2 = 't_big_01.png';
const g_big_p2_3 = 't_big_02.png';
const g_big_p2_4 = 't_big_03.png';
const g_big_p2_5 = 't_big_04.png';

const g_bg_1 = 'gameplay_bg_01.jpg';
const g_bg_2 = 'gameplay_bg_02.jpg';
const g_bg_3 = 'gameplay_bg_03.jpg';
const g_bg_4 = 'gameplay_bg_04.jpg';
const g_bg_5 = 'gameplay_bg_05.jpg';

const g_bg_b_1 = 'gameplay_bg_b_01.png';
const g_bg_b_2 = 'gameplay_bg_b_02.png';
const g_bg_b_3 = 'gameplay_bg_b_03.png';
const g_bg_b_4 = 'gameplay_bg_b_04.png';
const g_bg_b_5 = 'gameplay_bg_b_05.png';

const g_point_20 = 'player_20p.png';
const g_point_30 = 'player_30p.png';
const g_point_40 = 'player_40p.png';
const g_point_50 = 'player_50p.png';

const Player = (props) => {
  const classes = useStyles();
  const { student } = props;
  const { images } = useImagesContext();
  const [studentInfo, setStudentInfo] = useState({});
  const [playerInfo, setPlayerInfo] = useState({ selectedBigP1: g_big_p1_1 });
  const [selectedPlayer1, setSelectedPlayer1] = useState('');
  const [selectedPlayer2, setSelectedPlayer2] = useState('');
  const [selectedBackground, setSelectedBackground] = useState('');
  const [isDispGamePlay, setIsDispGamePlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDispUpdateLevelEffect, setIsDispUpdateLevelEffect] = useState(false);
  const [isDispUnlockIconEffect, setIsDispUnlockIconEffect] = useState(false);
  const [isBackToPlayer, setIsBackToPlayer] = useState(false);
  const prevStudentInfo = useRef({});
  console.log('student:', student);

  React.useEffect(() => {
    getStudentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { url } = useRouteMatch();
  React.useEffect(() => {
    if (!props.student || !props.student.id) {
      props.history.push(`/home`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.student]);

  React.useEffect(() => {
    if (isBackToPlayer) {
      getStudentInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBackToPlayer]);

  React.useEffect(() => {
    let selectedP1 = '';
    let selectedCurP1 = '';
    let selectedBigP1 = '';
    let selectedP2 = '';
    let selectedCurP2 = '';
    let selectedBigP2 = '';
    let selectedBg = '';
    let selectedBgId = '';
    let selectedBgBoard = '';

    if (
      selectedPlayer1 === '' ||
      selectedPlayer2 === '' ||
      selectedBackground === ''
    ) {
      return;
    }

    switch (selectedPlayer1) {
      case p1_1:
        selectedP1 = g_p1_1;
        selectedCurP1 = g_cur_p1_1;
        selectedBigP1 = g_big_p1_1;
        break;
      case p1_2:
        selectedP1 = g_p1_2;
        selectedCurP1 = g_cur_p1_2;
        selectedBigP1 = g_big_p1_2;
        break;
      case p1_3:
        selectedP1 = g_p1_3;
        selectedCurP1 = g_cur_p1_3;
        selectedBigP1 = g_big_p1_3;
        break;
      case p1_4:
        selectedP1 = g_p1_4;
        selectedCurP1 = g_cur_p1_4;
        selectedBigP1 = g_big_p1_4;
        break;
      case p1_5:
        selectedP1 = g_p1_5;
        selectedCurP1 = g_cur_p1_5;
        selectedBigP1 = g_big_p1_5;
        break;
      default:
        break;
    }

    switch (selectedPlayer2) {
      case p2_1:
        selectedP2 = g_p2_1;
        selectedCurP2 = g_cur_p2_1;
        selectedBigP2 = g_big_p2_1;
        break;
      case p2_2:
        selectedP2 = g_p2_2;
        selectedCurP2 = g_cur_p2_2;
        selectedBigP2 = g_big_p2_2;
        break;
      case p2_3:
        selectedP2 = g_p2_3;
        selectedCurP2 = g_cur_p2_3;
        selectedBigP2 = g_big_p2_3;
        break;
      case p2_4:
        selectedP2 = g_p2_4;
        selectedCurP2 = g_cur_p2_4;
        selectedBigP2 = g_big_p2_4;
        break;
      case p2_5:
        selectedP2 = g_p2_5;
        selectedCurP2 = g_cur_p2_5;
        selectedBigP2 = g_big_p2_5;
        break;
      default:
        break;
    }

    switch (selectedBackground) {
      case bg_1:
        selectedBg = g_bg_1;
        selectedBgBoard = g_bg_b_1;
        selectedBgId = '01';
        break;
      case bg_2:
        selectedBg = g_bg_2;
        selectedBgBoard = g_bg_b_2;
        selectedBgId = '02';
        break;
      case bg_3:
        selectedBg = g_bg_3;
        selectedBgBoard = g_bg_b_3;
        selectedBgId = '03';
        break;
      case bg_4:
        selectedBg = g_bg_4;
        selectedBgBoard = g_bg_b_4;
        selectedBgId = '04';
        break;
      case bg_5:
        selectedBg = g_bg_5;
        selectedBgBoard = g_bg_b_5;
        selectedBgId = '05';
        break;
      default:
        break;
    }
    setPlayerInfo({
      selectedP1,
      selectedCurP1,
      selectedBigP1,
      selectedP2,
      selectedCurP2,
      selectedBigP2,
      selectedBg,
      selectedBgBoard,
      selectedBgId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer1, selectedPlayer2, selectedBackground]);

  React.useEffect(() => {
    let selectedBigP1 = '';
    //console.log('***selectedPlayer1:', selectedPlayer1);
    switch (selectedPlayer1) {
      case p1_1:
        selectedBigP1 = g_big_p1_1;
        break;
      case p1_2:
        selectedBigP1 = g_big_p1_2;
        break;
      case p1_3:
        selectedBigP1 = g_big_p1_3;
        break;
      case p1_4:
        selectedBigP1 = g_big_p1_4;
        break;
      case p1_5:
        selectedBigP1 = g_big_p1_5;
        break;
      default:
        break;
    }

    setPlayerInfo((playerInfo) => {
      return { ...playerInfo, selectedBigP1 };
    });
  }, [selectedPlayer1]);

  const checkCompleteSelectPlayerInfo = () => {
    //console.log('playerInfo::', playerInfo);
    return (
      playerInfo.selectedP1 && playerInfo.selectedP2 && playerInfo.selectedBg
    );
  };

  // const getDigit = (number, digitPos) => {
  //   let ndigitPos = digitPos;
  //   if (number < 0) {
  //     ++ndigitPos;
  //   }
  //   if (ndigitPos < 1 || ndigitPos > String(number).length) {
  //     return 0;
  //   }
  //   return +String(number)[ndigitPos - 1];
  // };

  // const getDigitLen = (number) => {
  //   return String(Math.abs(number)).length;
  // };

  const spaceBox = (point) => {
    if (!Number.isFinite(point)) {
      return '-';
    }
    return (
      <div spacebox=''>
        {/* <img spaceimg="" src={images['player_space.png']}></img> */}
        <div
          spaceimg=''
          style={{
            backgroundImage: `url(${images['player_space.png']})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: 397,
            height: 135,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {Array.from(String(point)).map((it) => {
            if (/^\d$/.test(it)) {
              return <img src={images[`player_${+it}.png`]} alt='' />;
            } else if (it === '.') {
              return (
                <img
                  dot=''
                  src={images[`dot.png`]}
                  style={{
                    marginLeft: '-20px',
                    marginRight: '-20px',
                    marginTop: '50px',
                  }}
                  alt=''
                />
              );
            } else return null;
          })}
        </div>
      </div>
    );
  };
  const getLevelLabel = (level) => {
    switch (level) {
      case 1:
        return 'D';
      case 2:
        return 'C';
      case 3:
        return 'B';
      case 4:
        return 'A';
      case 5:
        return 'S';
      default:
        return 'D';
    }
  };

  const problemBackHandler = () => {
    props.history.push(`/hall`);
  };

  const gamePlayBackHandler = () => {
    setIsDispGamePlay(false);
    setIsBackToPlayer(true);
    //console.log('setIsBackToPlayer:');
    // setTimeout(() => {
    //   getStudentInfo();
    // }, 100);
  };

  const startEffectHandler = () => {
    if (!props.config.isMutedSound) {
      soundSelect.play(0.3);
    }

    setTimeout(() => {
      setIsDispUpdateLevelEffect(false);
    }, 1500);
  };

  const selectedEffectHandler = () => {
    setTimeout(() => {
      setIsDispUnlockIconEffect(false);
    }, 550);
  };

  const getStudentInfo = () => {
    setIsLoading(true);
    prevStudentInfo.current = studentInfo;
    getStudentInfoService(student.id)
      .then((resp) => {
        //resp.data.level = 1;
        // resp.data.capture = 4;
        // resp.data.win = 4.4;
        //console.log('isBackToPlayer::', isBackToPlayer);
        if (isBackToPlayer) {
          //resp.data.level = 4;
          if (+resp.data.level > +prevStudentInfo.current.level) {
            // console.log('start effecttt');
            setTimeout(() => {
              setIsDispUpdateLevelEffect(true);
            }, 100);

            setTimeout(() => {
              setIsDispUnlockIconEffect(true);
            }, 1450);
          }
          setIsBackToPlayer(false);
        }
        setStudentInfo(resp.data);

        setIsLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
        setIsLoading(false);
      });
  };

  const getPointImgByLevel = (level) => {
    switch (getLevelLabel(level)) {
      case 'D':
        return g_point_20;
      case 'C':
        return g_point_30;
      case 'B':
        return g_point_40;
      case 'A':
        return g_point_50;
      case 'S':
        return '';
      default:
        return '';
    }
  };

  const getPlayer1Icon = (num) => {
    switch (num) {
      case 1:
        return p1_1;
      case 2:
        return p1_2;
      case 3:
        return p1_3;
      case 4:
        return p1_4;
      case 5:
        return p1_5;
      default:
        return '';
    }
  };

  const getPlayer2Icon = (num) => {
    switch (num) {
      case 1:
        return p2_1;
      case 2:
        return p2_2;
      case 3:
        return p2_3;
      case 4:
        return p2_4;
      case 5:
        return p2_5;
      default:
        return '';
    }
  };

  const getBackgroungIcon = (num) => {
    switch (num) {
      case 1:
        return bg_1;
      case 2:
        return bg_2;
      case 3:
        return bg_3;
      case 4:
        return bg_4;
      case 5:
        return bg_5;
      default:
        return '';
    }
  };

  //console.log('playerInfo:', playerInfo);

  return (
    <React.Fragment>
      {isLoading ? (
        <img
          className={classes.loading}
          src={imgLoading}
          //src={process.env.PUBLIC_URL + '/circle-loading-animation_.gif'}
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
      ) : isDispGamePlay ? (
        <GamePlay playerInfo={playerInfo} backHandler={gamePlayBackHandler} />
      ) : (
        <div
          style={{
            backgroundImage: `url(${images['player_background.jpg']})`,
          }}
          className={classes.root}
        >
          <div className={classes.container}>
            <div className={classes.leftContent}>
              <div
                pic=''
                style={{
                  backgroundImage: `url(${images[playerInfo.selectedBigP1]})`,
                }}
              ></div>
              <div capture=''>
                <img imgcapture='' src={images['player_capture.png']} alt='' />
                {spaceBox(studentInfo.capture)}
              </div>
              <div win=''>
                <img imgwin='' src={images['player_win.png']} alt='' />
                {spaceBox(studentInfo.win)}
              </div>
            </div>
            <div className={classes.centerContent}>
              <div player1icon=''>
                {Array.from(Array(5).keys()).map((_, i) => (
                  <div key={'player1icon' + i} style={{ position: 'relative' }}>
                    <img
                      className={[
                        studentInfo.level >= i + 1
                          ? selectedPlayer1 === getPlayer1Icon(i + 1)
                            ? classes.iconSelected
                            : classes.iconHover
                          : '',
                      ].join(' ')}
                      src={
                        images[
                          (isDispUnlockIconEffect || isDispUpdateLevelEffect) &&
                          i + 1 > prevStudentInfo.current.level &&
                          i + 1 <= studentInfo.level
                            ? img_locked
                            : studentInfo.level >= i + 1
                            ? getPlayer1Icon(i + 1)
                            : img_locked
                        ]
                      }
                      onClick={() => {
                        if (studentInfo.level >= i + 1) {
                          setSelectedPlayer1(getPlayer1Icon(i + 1));
                        }
                      }}
                      alt=''
                    />
                    {isDispUnlockIconEffect &&
                    i + 1 > prevStudentInfo.current.level &&
                    i + 1 <= studentInfo.level ? (
                      <img
                        style={{
                          position: 'absolute',
                          zIndex: 2000,
                          left: '-90px',
                          top: '-90px',
                        }}
                        onLoad={selectedEffectHandler}
                        src={images['play_effect_selected_item.png'] + '?a=R'}
                        alt=''
                      />
                    ) : null}
                  </div>
                ))}
              </div>
              <div levelinfo=''>
                <img src={images['player_level.png']} alt='' />
                <img
                  imglevel=''
                  src={
                    images[
                      `player_level_${getLevelLabel(studentInfo.level)}.png`
                    ]
                  }
                  alt=''
                />
                {getPointImgByLevel(studentInfo.level) ? (
                  <img
                    imglevelup=''
                    src={images[getPointImgByLevel(studentInfo.level)]}
                    alt=''
                  />
                ) : null}

                <img
                  imgstart=''
                  className={classes.iconHover}
                  src={images['player_start.png']}
                  onClick={() => {
                    if (checkCompleteSelectPlayerInfo()) {
                      //setIsDispUpdateLevelEffect(true);
                      //setTimeout(() => {
                      setIsDispGamePlay(true);
                      //}, 3000);
                    }
                  }}
                  alt=''
                />
              </div>

              <div player2icon=''>
                {Array.from(Array(5).keys()).map((_, i) => (
                  <div key={'player2icon' + i} style={{ position: 'relative' }}>
                    <img
                      className={[
                        studentInfo.level >= i + 1
                          ? selectedPlayer2 === getPlayer2Icon(i + 1)
                            ? classes.iconSelected
                            : classes.iconHover
                          : '',
                      ].join(' ')}
                      src={
                        images[
                          (isDispUnlockIconEffect || isDispUpdateLevelEffect) &&
                          i + 1 > prevStudentInfo.current.level &&
                          i + 1 <= studentInfo.level
                            ? img_locked
                            : studentInfo.level >= i + 1
                            ? getPlayer2Icon(i + 1)
                            : img_locked
                        ]
                      }
                      onClick={() => {
                        if (studentInfo.level >= i + 1) {
                          setSelectedPlayer2(getPlayer2Icon(i + 1));
                        }
                      }}
                      alt=''
                    />
                    {isDispUnlockIconEffect &&
                    i + 1 > prevStudentInfo.current.level &&
                    i + 1 <= studentInfo.level ? (
                      <img
                        style={{
                          position: 'absolute',
                          zIndex: 2000,
                          left: '-90px',
                          top: '-90px',
                        }}
                        onLoad={selectedEffectHandler}
                        src={images['play_effect_selected_item.png'] + '?a=R'}
                        alt=''
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.rightContent}>
              {Array.from(Array(5).keys()).map((_, i) => (
                <div
                  key={'backgroundicon' + i}
                  style={{ position: 'relative' }}
                >
                  <img
                    imgbackground=''
                    className={[
                      studentInfo.level >= i + 1
                        ? selectedBackground === getBackgroungIcon(i + 1)
                          ? classes.bgSelected
                          : classes.bgHover
                        : '',
                    ].join(' ')}
                    src={
                      images[
                        (isDispUnlockIconEffect || isDispUpdateLevelEffect) &&
                        i + 1 > prevStudentInfo.current.level &&
                        i + 1 <= studentInfo.level
                          ? img_locked2
                          : studentInfo.level >= i + 1
                          ? getBackgroungIcon(i + 1)
                          : img_locked2
                      ]
                    }
                    onClick={() => {
                      if (studentInfo.level >= i + 1) {
                        setSelectedBackground(getBackgroungIcon(i + 1));
                      }
                    }}
                    alt=''
                  />
                  {isDispUnlockIconEffect &&
                  i + 1 > prevStudentInfo.current.level &&
                  i + 1 <= studentInfo.level ? (
                    <img
                      style={{
                        position: 'absolute',
                        zIndex: 2000,
                        left: '-20px',
                        top: '-50px',
                      }}
                      onLoad={selectedEffectHandler}
                      src={images['play_effect_selected_item.png'] + '?a=R'}
                      alt=''
                    />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <footer id='footer' className={classes.footer}>
            <Grid container justify='space-between'>
              <Grid item xs container>
                <img
                  className={classes.imageBack}
                  src={images['player_back.png']}
                  id='back'
                  onClick={() => {
                    problemBackHandler();
                  }}
                  alt=''
                />
              </Grid>
            </Grid>
          </footer>
          {isDispUpdateLevelEffect ? (
            <img
              style={{ position: 'absolute', top: '-35px', left: '-10px' }}
              onLoad={startEffectHandler}
              src={images['play_effect_start_game.png'] + '?a=R'}
              alt=''
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config,
});

export default connect(mapStateToProps, null)(Player);
