import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Sound from 'react-sound';
// import UIfx from 'uifx';

import { useImagesContext } from '../../contexts/ImagesContext';
import { TextImageYellow } from '../utils/NumberImage';

import * as configActions from '../../actions/config';
import { getStudent } from '../../services/student';
import { clearJumpUri } from '../../actions/jump';
import { setSelectStudent } from '../../actions/student';
import { LEVEL_DISPLAY } from '../../constant';
import configApp from '../../config';

import backgroundSound from '../../sounds/01.mp3';
import startToPlanetSound from '../../sounds/02.mp3';
import gotoPlanetSound from '../../sounds/12.mp3';
import goToFightSound from '../../sounds/goto-fight.mp3';
import pointUpSound from '../../sounds/point-up.mp3';
import levelUpSound from '../../sounds/level-up.ogg';

// const LEVEL_UP_DURATION = 6000;
// const POINT_UP_DURATION = 2000;
const COMING_SOON_DURATION = 1000;

const useStyles = makeStyles(() => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  background: {
    position: 'relative',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },
  leftDownBlock: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  level: {
    position: 'absolute',
    left: 4,
    bottom: 12,
    height: 76,
    width: 160,
    textAlign: 'center',
  },
  studentName: {
    position: 'absolute',
    bottom: 6,
    left: 150,
    width: 100,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 48,
    textStroke: '1px white',
    paintOrder: 'stroke fill',
    overflow: 'hidden',
    paddingLeft: 20,
    paddingRight: 20,
    // height: 70,
    textAlign: 'center',
    // padding: '0px 40px 0px 40px',
  },
  sound: {
    position: 'absolute',
    left: 290,
    bottom: 6,
  },
  logout: {
    position: 'absolute',
    bottom: 4,
    left: 350,
  },
  book: {
    position: 'absolute',
    bottom: 56,
    left: 880,
  },
  luckyWheel: {
    position: 'absolute',
    left: 250,
    top: 670,
  },
  star: {
    position: 'absolute',
    left: 240,
    bottom: 100,
  },
  cup: {
    position: 'absolute',
    right: 220,
    bottom: 230,
  },
  exp: {
    position: 'absolute',
    right: 200,
    bottom: 90,
  },
  mission: {
    position: 'absolute',
    right: 45,
    bottom: 150,
  },
  collectionRoom: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  mysterious: {
    position: 'absolute',
    left: 0,
    top: 145,
  },
  wanderland: {
    position: 'absolute',
    left: 350,
    top: 0,
  },
  space: {
    position: 'absolute',
    left: 580,
    top: 0,
  },
  skyPlatform: {
    position: 'absolute',
    left: 1040,
    top: 160,
  },
  twilightZone: {
    position: 'absolute',
    right: 322,
    top: 0,
  },
  battleField: {
    position: 'absolute',
    right: 0,
    top: 292,
  },
  comingSoon: {
    position: 'relative',
    top: 30,
    margin: 'auto',
    display: 'block',
  },
  hiddenButton: {
    position: 'absolute',
    top: 660,
    left: 830,
    width: 320,
    height: 200,
    // borderRadius: '50%',
    // border: '3px solid yellow',
  },
  jump: {
    position: 'absolute',
    top: 230,
    left: 660,
  },
  pointUp: {
    position: 'absolute',
    bottom: -60,
    left: 760,
  },
  levelUp: {
    position: 'absolute',
    bottom: 280,
    left: 740,
  },
}));
const Hall = ({
  match,
  history,
  config,
  setMuteSound,
  clearJumpUri,
  jump,
  student,
  setSelectStudent,
}) => {
  const classes = useStyles();
  const { images } = useImagesContext();
  // const [student, setStudent] = useState({});
  const [soundToPlanet, setSoundToPlanet] = useState(false);
  const [effect01, setEffect01] = useState(false);
  const [sound01, setSound01] = useState(false);
  const [gotoFight, setGotoFight] = useState(false);
  const [isPass, setIsPass] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [levelUp, setLevelUp] = useState(false);
  const [pointUp, setPointUp] = useState(false);

  const studentId = student.id;
  console.debug('studentId', studentId);
  console.debug('student', student);

  useEffect(() => {
    console.debug('useEffect', studentId);
    async function _getData() {
      try {
        let res = await getStudent(studentId);
        console.debug('res.data', res.data);
        let updateStudent = false;
        const newStudent = res.data;
        console.debug('student.level', student.level);
        console.debug('newStudent.level', newStudent.level);
        console.debug(
          'student.level !== newStudent.level',
          student.level !== newStudent.level
        );
        if (student.level !== newStudent.level) {
          console.debug('level up');
          setLevelUp(true);
          updateStudent = true;
          // setTimeout(() => {
          //   setLevelUp(false);
          // }, LEVEL_UP_DURATION);
        }
        function _pointUp() {
          setPointUp(true);
          updateStudent = true;
          // setTimeout(() => {
          //   setPointUp(false);
          // }, POINT_UP_DURATION);
        }
        if (student.point !== newStudent.point) {
          console.debug('point up');
          _pointUp();
        } else if (student.pointExtra !== newStudent.pointExtra) {
          console.debug('pointExtra up');
          _pointUp();
        } else if (student.bonus !== newStudent.bonus) {
          console.debug('bonus up');
          _pointUp();
        }
        if (updateStudent) {
          setSelectStudent(newStudent);
        }
      } catch (err) {
        console.error(err);
      }
    }
    _getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSound() {
    setMuteSound(!config.isMutedSound);
  }

  function handleLogout() {
    clearJumpUri();
    history.push(`/home`);
  }

  function handleBookClick() {
    history.push(`/dashboard`);
  }

  function handleLuckyWheelClick() {
    if (isPass) {
      history.push(`/bonus-dashboard/`);
      setIsPass(false);
    }
  }

  function handleStarClick() {
    if (isPass) {
      history.push(`/play-board`);
      setIsPass(false);
    }
  }

  function handleMissionClick() {
    history.push(`/mission`);
  }

  function handleMysteriousClick() {
    setGotoFight(true);
  }

  const gotoPlanet = () => {
    history.push(`/planet`);
  };

  function handleSpaceClick() {
    setSoundToPlanet(true);
  }

  function handleBattleFieldClick() {
    history.push(`/game-play`);
  }

  function handleComingSoon() {
    setIsComingSoon(true);
    setTimeout(() => {
      setIsComingSoon(false);
    }, COMING_SOON_DURATION);
  }

  function handleJump() {
    const jumpUri = jump.jumpUri;
    clearJumpUri();
    history.push(jumpUri);
  }

  return (
    <div
      className={classes.background}
      style={{
        backgroundImage: `url(${images['background.jpg']})`,
      }}
    >
      <Sound
        url={backgroundSound}
        playStatus='PLAYING'
        loop={true}
        volume={config.isMutedSound ? 0 : 15}
      ></Sound>
      {soundToPlanet && (
        <Sound
          url={startToPlanetSound}
          playStatus='PLAYING'
          onFinishedPlaying={() => {
            setSoundToPlanet(false);
            setSound01(true);
            setEffect01(true);
          }}
          volume={config.isMutedSound ? 0 : undefined}
        ></Sound>
      )}
      {sound01 && (
        <Sound
          url={gotoPlanetSound}
          playStatus='PLAYING'
          onFinishedPlaying={() => {
            gotoPlanet();
          }}
          volume={config.isMutedSound ? 0 : undefined}
        ></Sound>
      )}
      {effect01 && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 20,
          }}
        >
          <img
            src={images['effect-01.png']}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}
      {gotoFight && (
        <>
          <Sound
            url={goToFightSound}
            playStatus='PLAYING'
            onFinishedPlaying={() => {
              history.push(`/fight`);
            }}
            volume={config.isMutedSound ? 0 : undefined}
          ></Sound>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: 1920,
              height: 1080,
              zIndex: 20,
            }}
          >
            <img
              src={images['goto-fight.png'] + '?r=' + Date.now()}
              alt=''
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </>
      )}
      <img
        className={classes.leftDownBlock}
        src={images['levelin.png']}
        alt=''
      />
      <div className={classes.level}>
        {student?.level == 16 ? (
          <img src={images['pre.png']} alt='' />
        ) : (
          <TextImageYellow height={76} text={LEVEL_DISPLAY[student?.level]} />
        )}
      </div>
      <div
        className={classes.studentName}
        style={{
          backgroundImage: `url(${images['name.png']})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
        }}
      >
        {student?.name}
      </div>
      <img
        onClick={handleSound}
        className={`${classes.sound} ${classes.point}`}
        src={
          config.isMutedSound ? images['sound-off.png'] : images['sound-on.png']
        }
        alt=''
      />
      <img
        onClick={handleLogout}
        className={`${classes.logout} ${classes.point}`}
        src={images['logout.png']}
        alt=''
      />
      <img
        onClick={handleBookClick}
        className={`${classes.book} ${classes.point}`}
        src={images['book.png']}
        alt=''
      />
      <div
        onClick={() => setIsPass(true)}
        className={classes.hiddenButton}
      ></div>
      <img
        onClick={handleLuckyWheelClick}
        className={`${classes.luckyWheel} ${classes.point}`}
        src={images['lucky-wheel.png']}
        alt=''
      />
      <img
        onClick={handleStarClick}
        className={`${classes.star} ${classes.point}`}
        src={images['star.png']}
        alt=''
      />
      {student && student.level <= 13 && (
        // <a
        //   // className={`${classes.point}`}
        //   href={`${configApp.matchUrl}?back=/hall`}
        // >
        <img
          className={`${classes.cup} ${classes.point}`}
          src={images['cup.png']}
          alt=''
          onClick={
            () =>
              (window.location.href = `${configApp.matchUrl}?back=${configApp.appUrl}/hall`)
            // history.push(`${configApp.matchUrl}?back=/hall`)
          }
        />
        // </a>
      )}
      {student && student.level <= 10 && (
        <>
          <img
            onClick={handleComingSoon}
            className={`${classes.exp} ${classes.point}`}
            src={images['exp.png']}
            alt=''
          />
          <img
            onClick={handleMissionClick}
            className={`${classes.mission} ${classes.point}`}
            src={images['mission.png']}
            alt=''
          />
          <img
            onClick={handleComingSoon}
            className={`${classes.collectionRoom} ${classes.point}`}
            src={images['collection-room.png']}
            alt=''
          />
        </>
      )}
      {student && student.level <= 13 && (
        <img
          onClick={handleMysteriousClick}
          className={`${classes.mysterious} ${classes.point}`}
          src={images['mysterious.png']}
          alt=''
        />
      )}
      {student && student.level <= 10 && (
        <img
          onClick={handleComingSoon}
          className={`${classes.wanderland} ${classes.point}`}
          src={images['wanderland.png']}
          alt=''
        />
      )}
      <img
        onClick={handleSpaceClick}
        className={`${classes.space} ${classes.point}`}
        src={images['space.png']}
        alt=''
      />
      {student && student.level <= 10 && (
        <img
          onClick={handleComingSoon}
          className={`${classes.skyPlatform} ${classes.point}`}
          src={images['sky-platform.png']}
          alt=''
        />
      )}
      {student && student.level <= 10 && (
        <img
          onClick={handleComingSoon}
          className={`${classes.twilightZone} ${classes.point}`}
          src={images['twilight-zone.png']}
          alt=''
        />
      )}
      <img
        onClick={handleBattleFieldClick}
        className={`${classes.battleField} ${classes.point}`}
        src={images['battle-field.png']}
        alt=''
      />
      {isComingSoon && (
        <img
          className={`${classes.comingSoon}`}
          src={images['coming-soon.png']}
          alt=''
        />
      )}
      {jump?.jumpUri && (
        <img
          className={`${classes.jump} ${classes.point}  `}
          src={images['jump.png']}
          alt=''
          onClick={() => handleJump()}
        />
      )}
      {pointUp && (
        <>
          <img
            className={`${classes.pointUp}`}
            src={images['point-up.png'] + '?r=' + Date.now()}
            alt=''
          />
          <Sound
            url={pointUpSound}
            playStatus='PLAYING'
            volume={config.isMutedSound ? 0 : undefined}
            onFinishedPlaying={() => {
              setPointUp(false);
            }}
          />
        </>
      )}
      {levelUp && (
        <>
          <img
            className={`${classes.levelUp}`}
            src={images['level-up.png'] + '?r=' + Date.now()}
            alt=''
          />
          <Sound
            url={levelUpSound}
            playStatus='PLAYING'
            volume={config.isMutedSound ? 0 : undefined}
            onFinishedPlaying={() => {
              console.debug('level up sound');
              setLevelUp(false);
            }}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ config, jump, student }) => ({
  config,
  jump,
  student,
});

const mapDispatchToProps = {
  setMuteSound: configActions.setMuteSound,
  clearJumpUri,
  setSelectStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hall);
