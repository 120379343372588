import axios from '../host';

export function getBranch(_next = null, _catch = null) {
  // setTimeout(() => {
  //   _next &&
  //     _next([
  //       { value: 'item1' },
  //       { value: 'item2' },
  //       { value: 'item3' },
  //       { value: 'item4' },
  //       { value: 'item5' },
  //       { value: 'item6' },
  //       { value: 'item7' },
  //       { value: 'item8' },
  //       { value: 'item9' },
  //       { value: 'item10' },
  //       { value: 'item11' },
  //       { value: 'item12' },
  //       { value: 'item13' },
  //       { value: 'item14' },
  //       { value: 'item15' },
  //     ]);
  // }, 1000);
  axios
    .get('/api/v2/branch')
    .then((resp) => {
      console.log('resp::', resp.data);
      _next && _next(resp.data);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    });
}
