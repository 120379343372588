import axios from '../host';

const STUDENT_PASS_FIGHT_STAGE = [
  [
    {
      id: 1,
      number: 1,
    }
  ]
]

const FIGHT_MAPS = [
  {
    id: 1,
    number: 1,
    background: 'http://localhost:5000/files/fight/maps/background.jpg',
    stages: [
      {
        id: 1,
        number: 1,
      },
      {
        id: 2,
        number: 2,
      },
      {
        id: 3,
        number: 3,
      },
      {
        id: 4,
        number: 4,
      },
      {
        id: 5,
        number: 5,
      },
    ]
  }
];

// const STUDENTS_FIGHT_MAPS = {
//   id: 1,
//   number: 1,
//   background: 'http://localhost:5000/files/fight/stages/background.png',
//   bossHealth: 25,
// };

export const getFightMaps = (studentId) => {
  return axios.get(`/api/v2/student-fight/${studentId}/fights`);
  // return Promise.resolve(STUDENTS_FIGHT_MAPS);
};

// export const getFightMaps = () => {
// return axios.get(`/api/v2/student-fight/1/fights`);
// return new Promise(resolve => {
//   setTimeout(() => {
//     resolve(FIGHT_MAPS);
//   }, 100);
// })
// };

export const getFightStage = (student, stageId) => {
  return axios.get(`/api/v2/student-fight/${student}/fight-stages/${stageId}`).then((res) => {
    return res.data;
  });
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve(STUDENTS_FIGHT_MAPS);
  //   }, 100);
  // })
};

export const passFightStage = (studentId, fightStageId) => {
  const body = JSON.stringify({ fightStageId });
  return axios.post(`/api/v2/student-fight/${studentId}/fight-stage/pass`, body).then(res => res.data);
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve(STUDENT_PASS_FIGHT_STAGE);
  //   }, 100);
  // })
};
