import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import Chapter from './Chapter/Chapter';
import imgLoading from '../../../../images/circle-loading-animation.gif';
import {
  getLessonList as getLessonListService,
  // getLevelTimeout as getLevelTimeoutService,
  errorHandler,
} from '../../../../services/fuseki';
import UIfx from 'uifx';
// import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import soundUnLockedFile from '../../../../sounds/13.mp3';
import soundClearFile from '../../../../sounds/14.wav';
import soundEnterLessonFile from '../../../../sounds/15.wav';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '1790px',
    height: '1120px',
  },
  grid: {
    width: '1450px',
    height: '900px',
    marginTop: '50px',
  },
  imageBook: {
    width: 240,
    height: 195,
  },
  imageLock: {
    position: 'absolute',
    top: '2px',
    left: '70px',
  },
  imageClear: {
    position: 'absolute',
    top: '70px',
    left: '-10px',
  },
  imageLockEffect: {
    position: 'absolute',
    top: '-145px',
    left: '-60px',
  },
  imageClearEffect: {
    position: 'absolute',
    zIndex: '9999',
    width: '480px',
    height: '280px',
    top: '8px',
    left: '-50px',
  },
  imgContainer: {
    display: 'flex',
    width: 280,
    height: 235,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      '& [lock="false"]': {
        transform: 'scale(1.05)',
        filter: 'drop-shadow(-4px 18px 18px black)',
        '& + [imageclear]': {
          transform: 'scale(1.05)',
          filter: 'drop-shadow(-4px 8px 2px black)',
        },
      },
      '& [booktitle]': {
        transform: 'translate(8px,4px) scale(1.1)',
        zIndex: '7000',
      },
    },
  },
  imageBack: {
    marginLeft: '20px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  numberCircle: {
    position: 'absolute',
    left: '290px',
    top: '320px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    padding: '8px',

    background: '#fff',
    border: '4px solid #345673',
    color: '#2d2d2d',
    textAlign: 'center',
    font: '36px Arial, sans-serif',
  },
  imageFusekiLabel: {
    position: 'absolute',
    right: '20px',
    filter: 'drop-shadow(0px 4px 2px black)',
  },
  bookTitle: {
    position: 'absolute',
    bottom: '33px',
    transform: 'translateX(8px) scale(1.06)',
    margin: 'auto',
    fontSize: '2.5rem',
    textShadow:
      '2px 0px 0px #fff, 0px 2px 0px #fff, 0px -2px 0px #fff, -2px 0px 0px #fff',
  },
  footer: {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundUnLocked = new UIfx(soundUnLockedFile);
const soundClear = new UIfx(soundClearFile);
const soundEnterLesson = new UIfx(soundEnterLessonFile);

const Lesson = (props) => {
  const classes = useStyles();
  const { number, student, config } = props;
  const [item, setItem] = useState([]);
  const [colItem, setColItem] = useState([]);
  const [isChapterDisp, setIsChapterDisp] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [levelTimeout, setLevelTimeout] = useState(0);
  const [backToLesson, setBackToLesson] = useState(false);
  const [startUnlockedEffect, setStartUnlockedEffect] = useState(false);
  const [startClearEffect, setStartClearEffect] = useState(false);
  const isInited = React.useRef(false);
  //const [refClearEffect, setRefClearEffect] = useState(false);
  const refClearEffect = useRef();
  const [isRenderTitle, setIsRenderTitle] = useState(false);
  const { images } = useImagesContext();

  React.useEffect(() => {
    // setIsLoading(true);
    // getLessonList();
  }, []);

  React.useEffect(() => {
    if (backToLesson) {
      console.log('setBackToLesson!:');
      setIsLoading(true);
      getLessonList();
      setBackToLesson(false);
      setIsRenderTitle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToLesson]);

  React.useEffect(() => {
    if (item.length > 0) {
      setColItem([
        [[item[0], item[1]], [item[2], item[3]], [item[4]]],
        [[item[8], item[9]], [item[6], item[7]], [item[5]]],
      ]);
    }
  }, [item]);

  const initial = () => {
    if (!isInited.current) {
      setIsLoading(true);
      getLessonList();
      isInited.current = true;
    }
  };

  const chapterBackHandler = () => {
    setIsChapterDisp(false);
    setBackToLesson(true);
  };

  const getLessonList = () => {
    getLessonListService(student.id, number)
      .then((resp) => {
        setIsLoading(false);
        const updateItem = resp.data;
        // updateItem[5].locked = true;
        //updateItem[4].locked = true;
        if (backToLesson) {
          // updateItem[5].locked = false;
          // updateItem[5].clear = true;
          updateItem.forEach((updateIt) => {
            item.forEach((prevIt) => {
              if (updateIt.id === prevIt.id) {
                if (prevIt.locked && !updateIt.locked) {
                  updateIt.unlockedEffect = true;
                }
                if (!prevIt.clear && updateIt.clear) {
                  updateIt.clearEffect = true;
                }
              }
            });
          });
          setTimeout(() => {
            setStartUnlockedEffect(true);
            setStartClearEffect(true);
          }, 300);
        }
        setItem(updateItem);
      })
      .catch((err) => {
        setIsLoading(false);
        errorHandler(err);
      });
  };

  const unlockedEffectHandler = (id) => {
    const idx = item.findIndex((it) => it.id === id);
    if (item[idx].unlockedEffect) {
      !config.isMutedSound && soundUnLocked.play(0.4);
      setTimeout(() => {
        setItem((item) => {
          const tItem = item;
          tItem[idx].unlockedEffect = false;
          return [...tItem];
        });
        setStartUnlockedEffect(false);
      }, 500);
    }
  };

  const clearEffectHandler = (id) => {
    const idx = item.findIndex((it) => it.id === id);
    console.log('clearEffectHandler:', idx, item);
    if (item[idx].clearEffect && !refClearEffect.current) {
      //setRefClearEffect(true);
      refClearEffect.current = true;

      !config.isMutedSound && soundClear.play(0.4);
      setTimeout(() => {
        setItem((item) => {
          const tItem = item;
          tItem[idx].clearEffect = false;
          return [...tItem];
        });
        //setRefClearEffect(false);
        refClearEffect.current = false;
        setStartClearEffect(false);
      }, 600);
    }
  };

  return (
    <React.Fragment>
      {isChapterDisp ? (
        <Chapter backHandler={chapterBackHandler} fuseki={selectItem} />
      ) : (
          <div
            style={{
              backgroundImage: `url(${images['fsk_lesson_background.jpg']})`,
            }}
            className={classes.root}
            onLoad={initial}
          >
            <div
              style={{
                backgroundImage: `url(${images['fsk_lesson_bookbig.png']})`,
              }}
              className={classes.container}
            >
              <img
                className={classes.imageFusekiLabel}
                src={images['fuseki.png']}
                id="fuseki"
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
              <Grid container className={classes.grid} spacing={10}>
                {colItem.map((rowItem, colIdx) => (
                  <Grid
                    key={'col' + colIdx}
                    item
                    xs
                    container
                    direction={colIdx === 0 ? 'column' : 'column-reverse'}
                    justify="center"
                    alignItems="center"
                    spacing={6}
                  >
                    {(rowItem || []).map((rit, rowIdx) => (
                      <Grid
                        key={'row' + rowIdx}
                        item
                        container
                        justify="center"
                        spacing={5}
                      >
                        {rit.map((it, colIdx) => (
                          <Grid
                            key={it.fusekiId + colIdx}
                            className={classes.imgContainer}
                            item
                            onClick={() => {
                              if (!it.locked) {
                                !config.isMutedSound && soundEnterLesson.play(0.4);
                                setSelectItem(it);
                                setTimeout(() => {
                                  setIsChapterDisp(true);
                                }, 200);
                                setStartUnlockedEffect(false);
                                setStartClearEffect(false);
                              }
                            }}
                          >
                            {it.unlockedEffect && startUnlockedEffect ? (
                              <img
                                ref={() => unlockedEffectHandler(it.id)}
                                className={classes.imageLockEffect}
                                src={images['effect04.png']}
                                id={'lockeffect_' + it.fusekiId}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}

                            {it.clearEffect && startClearEffect ? (
                              <img
                                ref={() => clearEffectHandler(it.id)}
                                className={classes.imageClearEffect}
                                src={
                                  images['s_effect01.png'] + '?a=' + Math.random()
                                }
                                id={'cleareffect_' + it.fusekiId}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}

                            <img
                              className={classes.imageBook}
                              src={images[`fsk_lesson_bookopen.png`]}
                              id={it.fusekiId}
                              lock={it.locked ? 'true' : 'false'}
                              onContextMenu={(e) => e.preventDefault()}
                              // onLoad={() => {
                              //   isRenderTitle[colIdx + '_' + rowIdx] = true;
                              //   setIsRenderTitle({ ...isRenderTitle });
                              // }}
                              ref={(ref) => {
                                if (ref) {
                                  //console.log('refff:::', ref.complete);
                                  if (!ref.complete && it.number === 6) {
                                    ref.onload = function () {
                                      setTimeout(() => {
                                        setIsRenderTitle(true);
                                      }, 50);

                                      // setTimeout(() => {
                                      //   isRenderTitle[colIdx + '_' + rowIdx] = true;
                                      //   setIsRenderTitle({ ...isRenderTitle });
                                      // }, 100);
                                    };
                                  } else if (ref.complete && it.number === 6) {
                                    setIsRenderTitle(true);
                                  }
                                }
                              }}
                              alt=''
                            />
                            {isRenderTitle ? (
                              <span booktitle="" className={classes.bookTitle}>
                                {it.title}
                              </span>
                            ) : null}
                            {it.locked ? (
                              <img
                                className={classes.imageLock}
                                src={images['state_lock.png']}
                                id={'lock_' + it.fusekiId}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                            {!it.locked && it.clear ? (
                              <img
                                imageclear=""
                                className={classes.imageClear}
                                src={images['fsk_lesson_clear.png']}
                                id={'clear_' + it.fusekiId}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </div>
            <footer id="footer" className={classes.footer}>
              <Grid container justify="space-between">
                <Grid item xs container>
                  <img
                    className={classes.imageBack}
                    src={images['back.png']}
                    id="back"
                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => props.backHandler()}
                    alt=''
                  />
                </Grid>
              </Grid>
            </footer>
            {isLoading ? (
              <img
                className={classes.loading}
                src={imgLoading}
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
            ) : null}
          </div>
        )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

export default connect(mapStateToProps, null)(Lesson);
