import axios from '../host';

export function getBonus(_next = null, _catch = null) {
  axios
    .get('/api/v2/lucky-wheel')
    .then((resp) => {
      // console.log('resp::', resp.data);
      _next && _next(resp.data);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    });
}

export function updateBonus(
  studentId,
  _next = null,
  _catch = null,
  _complete = null
) {
  axios
    .post('/api/v2/lucky-wheel/draw', { studentId })
    .then((resp) => {
      _next && _next(resp.data);
      return;
    })
    .catch((error) => {
      _catch && _catch(error);
    })
    .finally(() => {
      _complete && _complete();
    });
}
