import {
  STUDENT_SELECT,
  STUDENT_DESELECT,
  STUDENT_PROBLEM_STATE,
  STUDENT_DEDUCT_MISSION_PROB_HINT,
} from '../actions/types';

const student = JSON.parse(window.localStorage.getItem('student')) || {};
const initialState = { problem: {}, missionProblemHint: 3, ...student };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STUDENT_SELECT:
      console.log('STUDENT_SELECT:', payload);
      return { ...payload, missionProblemHint: 3 };
    case STUDENT_DESELECT:
      return {};
    case STUDENT_PROBLEM_STATE:
      return {
        ...state,
        problem: { ...state.problem, [payload.problemId]: payload.state },
      };
    case STUDENT_DEDUCT_MISSION_PROB_HINT:
      return {
        ...state,
        missionProblemHint:
          state.missionProblemHint === 0
            ? state.missionProblemHint
            : state.missionProblemHint - 1,
      };
    default:
      return state;
  }
}
