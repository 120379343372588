import axios from '../host';

// Login User
export const login = async (name, password) => {
  return await axios.post('/api/v2/auth/teacher', JSON.stringify({ name, password }), {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
