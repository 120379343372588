import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import Swal from 'sweetalert2';
import { interval } from 'rxjs';
import { connect } from 'react-redux';
import imgLoading from '../../../../../images/circle-loading-animation.gif';
import UIfx from 'uifx';
import {
  getChapterStateList as getChapterStateListService,
  updateChapterState as updateChapterStateService,
} from '../../../../../services/problem';

import * as studentActions from '../../../../../actions/student';
import ToolBar from '../../../../toolbar/ToolBar';
import soundStartCountDownFile from '../../../../../sounds/17.ogg';
import soundHintFile from '../../../../../sounds/18.mp3';
import soundWrongFile from '../../../../../sounds/19.mp3';
import soundTimeoutFile from '../../../../../sounds/20.ogg';
import soundClearFile from '../../../../../sounds/14.wav';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  grid: {
    marginTop: '50px',
  },
  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageStar: {},
  imageGo: {
    marginLeft: '35px',
  },
  imageClock: {
    marginLeft: '35px',
  },
  imageHint: {},
  imageHintEffect: {
    position: 'absolute',
    left: '-32px',
  },
  imageWrongEffect: {
    position: 'absolute',
    left: '-32px',
  },
  imageClearEffect: {
    position: 'absolute',
    width: '1920px',
    height: '1080px',
    zIndex: '9999',
  },
  imageHintNum: {
    height: 'fit-content',
  },
  imageWrongNum: {
    height: 'fit-content',
  },
  imageWrong: {
    transform: 'translateX(-4px) rotate(-3deg)',
  },
  imageFooter: {
    marginLeft: '60px',
  },
  goContainer: {
    position: 'relative',
    display: 'flex',
  },
  clockContainer: {
    position: 'relative',
    display: 'flex',
  },
  timeCountDown: {
    position: 'absolute',
    top: '70px',
    left: '124px',
    color: 'white',
    fontSize: '5rem',
    fontWeight: '600',
  },
  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundStartCountDown = new UIfx(soundStartCountDownFile);
const soundHint = new UIfx(soundHintFile);
const soundWrong = new UIfx(soundWrongFile);
const soundTimeout = new UIfx(soundTimeoutFile);
const soundClear = new UIfx(soundClearFile);

const Chapter = (props) => {
  const classes = useStyles();
  const {
    problem,
    student,
    setStudentProblemState,
    levelTimeout,
    deductMissionProblemHint,
    config
  } = props;
  const { images } = useImagesContext();

  const [timeCountDown, setTimeCountDown] = useState(0);
  // const [hintCount, setHintCount] = useState(3);
  const [wrongCount, setWrongCount] = useState(3);
  const [trickCountDown, setTrickCountDown] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [curChapterIdx, setCurChapterIdx] = useState(null);
  const [isTryagainDisp, setIsTryagainDisp] = useState(false);
  const [isClearDisp, setIsClearDisp] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [warningMsg, setWarningMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [changeState, setChangeState] = useState(null);
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isSuccessUpdateScore, setIsSuccessUpdateScore] = useState(false);
  const [changeClockImg, setChangeClockImg] = useState(false);
  const [hintEffect, setHintEffect] = useState(false);
  const [wrongEffect, setWrongEffect] = useState(false);
  const [clearEffect, setClearEffect] = useState(false);
  const [disableFooter, setDisableFooter] = useState(false);
  const [toolState, setToolState] = useState('init');
  const isInited = React.useRef(false);  
  const [subscription, setSubscription] = useState(null);

  React.useEffect(() => {
    //getChapterStateList();
  }, []);

  React.useEffect(() => {
    if (trickCountDown && !isWarningShown) {
      const count$ = interval(1000);
      const subscription = count$.subscribe(() => {
        setTimeCountDown((timeCountDown) => {
          let integer = Math.floor(+timeCountDown);
          let decimal = timeCountDown - integer;
          if (decimal.toFixed(2) === '0.00') {
            integer -= 1;
            decimal = 0.6;
          }
          const time = (integer + decimal - 0.01).toFixed(2);
          if (time === '0.00') {
            !config.isMutedSound && soundTimeout.play(0.4);
            setIsTryagainDisp(true);
          }
          return time;
        });
      });
      setSubscription(subscription);
      return () => {
        subscription.unsubscribe();
      };
    } else {
    }
  }, [trickCountDown, isWarningShown, config.isMutedSound]);

  React.useEffect(() => {
    if (typeof curChapterIdx === 'number') {
      if (chapters[curChapterIdx].tryagain) {
        setIsTryagainDisp(true);
      } else if (chapters[curChapterIdx].pass) {
        console.log('case 1:::');
        setIsClearDisp(true);
      } else if (chapters[curChapterIdx].time) {
        setTimeCountDown(chapters[curChapterIdx].time.toFixed(2));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curChapterIdx]);

  React.useEffect(() => {
    if (isClearDisp) {
      // soundClear.play(0.4);
    }
  }, [isClearDisp]);

  React.useEffect(() => {
    if (clearEffect) {
      !config.isMutedSound && soundClear.play(0.4);
      console.log('case 2:::');
      setIsClearDisp(true);
      setTimeout(() => {
        setClearEffect(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearEffect]);

  React.useEffect(() => {
    if (isTryagainDisp) {
      notPass();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTryagainDisp]);

  React.useEffect(() => {
    if (wrongCount === 0) {
      !config.isMutedSound && soundTimeout.play(0.4);
      setIsTryagainDisp(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrongCount]);

  React.useEffect(() => {
    if (isSuccessShown) {
      showSuccessAlert();
    } else if (isWarningShown) {
      showWarningAlert();
    } else if (isErrorShown) {
      showErrorAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessShown, isWarningShown, isErrorShown]);

  React.useEffect(() => {
    if (scores.length > 0) {
      updateScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores.length]);

  React.useEffect(() => {
    if (hintEffect) {
      hintEffectHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintEffect]);

  React.useEffect(() => {
    if (wrongEffect) {
      wrongEffectHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrongEffect]);

  const initial = () => {
    if (!isInited.current) {
      getChapterStateList();
      isInited.current = true;
    }
  };

  const hintEffectHandler = () => {
    !config.isMutedSound && soundHint.play(0.4);
    setTimeout(() => {
      setHintEffect(false);
    }, 300);
  };

  const wrongEffectHandler = () => {
    !config.isMutedSound && soundWrong.play(0.4);
    setTimeout(() => {
      setWrongEffect(false);
    }, 300);
  };

  const showSuccessAlert = () => {
    Swal.fire({
      title: 'สรุปคะแนน',
      html: successMsg,
      icon: 'success',
    }).then((result) => {
      setIsSuccessShown(false);
      if (isLastChapter && scores.length === 0) {
        props.backHandler();
      } else {
        //getChapterStateList(curChapterIdx);
      }
    });
  };

  const showWarningAlert = () => {
    Swal.fire({
      title: 'แจ้งเตือน',
      text: warningMsg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        setIsWarningShown(false);
        changeState && changeState();
      } else {
        setIsWarningShown(false);
      }
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      title: 'สรุปคะแนน',
      text: errorMsg,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ตกลง',
    }).then((result) => {
      setIsErrorShown(false);
    });
  };

  const timeFormat = (time) => {
    return (
      Math.trunc(time) +
      ':' +
      (Math.floor(time.split('.')[1]) + '').padStart(2, '0')
    );
  };

  const getChapterStateList = (curIdx = 0) => {
    setIsLoading(true);
    getChapterStateListService(student.id, problem.problemId)
      .then((resp) => {
        setIsLoading(false);
        const chapters = (resp.data && resp.data.length > 0
          ? resp.data
          : []
        ).map((it) => {
          const tryagain = (
            (student.problem && student.problem[problem.problemId]) ||
            []
          ).find((f) => f.id === it.id && f.tryagain);

          const s = levelTimeout || 0;
          const timeout = +(Math.floor(s / 60) + (s % 60) / 100).toFixed(2);
          return { ...it, time: timeout, tryagain };
        });
        setChapters(chapters);
        if (chapters[curIdx]) {
          if (chapters[curIdx].tryagain) {
            setCurChapterIdx(curIdx);
            setIsTryagainDisp(true);
          } else {
            setCurChapterIdx(curIdx);
            if (chapters[curIdx].pass) {
              if (scores.length > 0) {
                // for recent clear
                setScores([]);
                setTimeout(() => {
                  setClearEffect(true);
                }, 200);
              }
            }
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMsg(err);
        setIsErrorShown(true);
      });
  };

  const updateScore = (next = null) => {
    setIsLoading(true);
    updateChapterStateService(student.id, problem.problemId, scores)
      .then((resp) => {
        setIsLoading(false);
        // setIsSuccessUpdateScore(true);
        //setScores([]);
        getChapterStateList(curChapterIdx);
        if (isLastChapter) {
          setSuccessMsg(
            `<div style="display: flex;flex-flow: column">
                <span>score : ${resp.data.score || ''}</span>
                <span>level : ${resp.data.level || ''}</span>
                <span>bonus : ${resp.data.bonus || ''}</span>
              </div>`
          );
          //setIsSuccessShown(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMsg(err);
        setIsErrorShown(true);
      });
  };

  const notPass = () => {
    subscription && subscription.unsubscribe();
    setTrickCountDown(false);
    setChangeClockImg(false);
    setChapters((chapters) => {
      const tnsfChapter = chapters;
      tnsfChapter[curChapterIdx].tryagain = true;
      setStudentProblemState(problem.problemId, tnsfChapter);
      return tnsfChapter;
    });
  };

  const reset = () => {
    setTrickCountDown(false);
    setChangeClockImg(false);
    setIsTryagainDisp(false);
    setIsClearDisp(false);
    //setHintCount(3);
    setWrongCount(3);
  };

  const forwardState = () => {
    setCurChapterIdx((curChapterIdx) => {
      if (chapters[curChapterIdx + 1]) {
        reset();
        resetToolBar();
        return curChapterIdx + 1;
      } else {
        return curChapterIdx;
      }
    });
  };

  const backState = () => {
    setCurChapterIdx((curChapterIdx) => {
      if (chapters[curChapterIdx - 1]) {
        reset();
        resetToolBar();
        return curChapterIdx - 1;
      } else {
        props.backHandler();
        return curChapterIdx;
      }
    });
  };

  const isLastChapter = (chapters || []).length === curChapterIdx + 1;

  const forwardOnClickHandler = () => {
    if (disableFooter) {
      return;
    }
    if (trickCountDown) {
      setWarningMsg('การเปลี่ยนแปลงขณะทดสอบจะถือว่าไม่ผ่านการทดสอบในด่านนี้');
      setIsWarningShown(true);
      subscription && subscription.unsubscribe();
      console.log('isLastChapter:', isLastChapter, scores);
      if (isLastChapter && scores.length > 0) {
        setChangeState(() => () => {
          !config.isMutedSound && soundTimeout.play(0.4);
          setIsTryagainDisp(true);
          //notPass();
          setDisableFooter(true);
          setTimeout(() => {
            updateScore();
            setDisableFooter(false);
          }, 500);
          setChangeState(null);
        });
      } else {
        setChangeState(() => () => {
          !config.isMutedSound && soundTimeout.play(0.4);
          setIsTryagainDisp(true);
          //notPass();
          setDisableFooter(true);
          setTimeout(() => {
            if (isLastChapter && scores.length === 0) {
              props.backHandler();
            } else {
              forwardState();
              setDisableFooter(false);
            }
          }, 2500);
          setChangeState(null);
        });
      }
    } else if (isLastChapter && scores.length > 0) {
      updateScore();
    } else if (isLastChapter && scores.length === 0) {
      //updateScore()
      props.backHandler();
    } else {
      forwardState();
    }
  };

  const backOnClickHandler = () => {
    if (disableFooter) {
      return;
    }
    if (trickCountDown) {
      setWarningMsg('การเปลี่ยนแปลงขณะทดสอบจะถือว่าไม่ผ่านการทดสอบในด่านนี้');
      setIsWarningShown(true);
      subscription && subscription.unsubscribe();
      setChangeState(() => () => {
        !config.isMutedSound && soundTimeout.play(0.4);
        setIsTryagainDisp(true);
        setDisableFooter(true);
        setTimeout(() => {
          backState();
          setDisableFooter(false);
        }, 2500);
        //notPass();
        setChangeState(null);
      });
    } else if (curChapterIdx === 0 && scores.length > 0) {
      setWarningMsg(
        'ยังไม่มีการส่งผลสรุปคะแนน หากออกจากหน้านี้จะสูญเสียคะแนนที่ถูกบันทึกไว้'
      );
      setIsWarningShown(true);
      subscription && subscription.unsubscribe();
      setChangeState(() => backState);
    } else {
      backState();
    }
  };

  const starOnClickHandler = () => {
    if (trickCountDown) {
      subscription.unsubscribe();
      setScores((scores) => [...scores, chapters[curChapterIdx].id]);
      setChangeClockImg(false);
      setTimeout(() => {
        setTrickCountDown(false);
      }, 200);

      // setIsSuccessUpdateScore(false);
    }
  };

  const resetToolBar = () => {
    setToolState('');
    setTimeout(() => {
      setToolState('init');
    }, 10);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${
          chapters[curChapterIdx]
            ? chapters[curChapterIdx].image
            : images['backgroundall.jpg']
          })`,
        overflow: 'hidden',
      }}
      className={classes.root}
      onLoad={initial}
    >
      <div className={classes.container}>
        <Grid container className={classes.grid}>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item style={{ zIndex: 5500 }}>
              <img
                src={images['chapter_star.png'] || null}
                className={[
                  classes.imageStar,
                  trickCountDown ? classes.imageHover : '',
                ].join(' ')}
                id="chapter_star"
                onContextMenu={(e) => e.preventDefault()}
                onClick={starOnClickHandler}
                alt=''
              />
            </Grid>
            <Grid style={{ zIndex: 5500 }} item>
              {changeClockImg ? (
                <div className={classes.clockContainer}>
                  <img
                    className={[classes.imageClock, classes.imageHover].join(
                      ' '
                    )}
                    src={images['chapter_clock.png']}
                    id="chapter_clock"
                    onContextMenu={(e) => e.preventDefault()}
                    alt=''
                  />
                  {trickCountDown ? (
                    <span className={classes.timeCountDown}>
                      {timeFormat(timeCountDown)}
                    </span>
                  ) : null}
                </div>
              ) : (
                  <div className={classes.goContainer}>
                    <img
                      className={[classes.imageGo, classes.imageHover].join(' ')}
                      src={images['chapter_go.png']}
                      id="chapter_go"
                      onContextMenu={(e) => e.preventDefault()}
                      onClick={() => {
                        if (chapters[curChapterIdx].time) {
                          !config.isMutedSound && soundStartCountDown.play(0.4);
                          setChangeClockImg(true);
                          setTimeout(() => {
                            setTrickCountDown(true);
                          }, 200);
                        }
                      }}
                      alt=''
                    />
                  </div>
                )}
            </Grid>
            <Grid item container spacing={2}>
              <Grid style={{ zIndex: 5500 }} item>
                <img
                  className={[
                    classes.imageHint,
                    trickCountDown ? classes.imageHover : '',
                  ].join(' ')}
                  src={images['chapter_hint.png']}
                  id="chapter_hint"
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={() => {
                    if (trickCountDown) {
                      if (student.missionProblemHint > 0) {
                        setHintEffect(true);
                      }
                      deductMissionProblemHint();
                    }
                  }}
                  alt=''
                />
              </Grid>
              {student.missionProblemHint >= 0 ? (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  {hintEffect ? (
                    <img
                      className={classes.imageHintEffect}
                      src={images['effect03.png']}
                      id={'hinteffect'}
                      onContextMenu={(e) => e.preventDefault()}
                      alt=''
                    />
                  ) : null}
                  {student.missionProblemHint > 0 ? (
                    <img
                      className={classes.imageHintNum}
                      src={images[`chapter_${student.missionProblemHint}.png`]}
                      id="hintCount"
                      onContextMenu={(e) => e.preventDefault()}
                      alt=''
                    />
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
            <Grid item container spacing={2}>
              <Grid style={{ zIndex: 5500 }} item>
                <img
                  className={[
                    classes.imageWrong,
                    trickCountDown ? classes.imageHover : '',
                  ].join(' ')}
                  src={images['chapter_wrong.png']}
                  id="chapter_wrong"
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={() => {
                    if (trickCountDown) {
                      if (wrongCount >= 0) {
                        setWrongEffect(true);
                      }
                      setWrongCount((wrongCount) =>
                        wrongCount === 0 ? wrongCount : wrongCount - 1
                      );
                    }
                  }}
                  alt=''
                />
              </Grid>
              {wrongCount >= 0 ? (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  {wrongEffect ? (
                    <img
                      className={classes.imageWrongEffect}
                      src={images['effect03.png']}
                      id={'wrongeffect'}
                      onContextMenu={(e) => e.preventDefault()}
                      alt=''
                    />
                  ) : null}
                  {wrongCount > 0 ? (
                    <img
                      className={classes.imageWrongNum}
                      src={images[`chapter_${wrongCount}.png`]}
                      id="wrongCount"
                      onContextMenu={(e) => e.preventDefault()}
                      alt=''
                    />
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        {isClearDisp ? (
          <img
            style={{ position: 'absolute', zIndex: 5600 }}
            src={images['chapter_clearbig.png']}
            alt=''
          />
        ) : null}
        {clearEffect ? (
          <img
            //ref={() => clearEffectHandler(idx)}
            className={classes.imageClearEffect}
            src={images['effect02.gif']}
            id={'cleareffect'}
            onContextMenu={(e) => e.preventDefault()}
            alt=''
          />
        ) : null}
        {isTryagainDisp ? (
          <img
            style={{ position: 'absolute', zIndex: 5600 }}
            src={images['chapter_lock.png']}
            alt=''
          />
        ) : null}
      </div>
      <footer id="footer" className={classes.footer} spacing={4}>
        <Grid container>
          <Grid style={{ zIndex: 5700 }} item xs={1} container>
            <img
              className={[classes.imageFooter, classes.imageHover].join(' ')}
              src={images['chapter_back.png']}
              id="chapter_back"
              onContextMenu={(e) => e.preventDefault()}
              onClick={backOnClickHandler}
              alt=''
            />
          </Grid>
          <Grid style={{ zIndex: 5700 }} item xs={1} container>
            <img
              className={[classes.imageFooter, classes.imageHover].join(' ')}
              src={images['chapter_forward.png']}
              id="chapter_forward"
              onContextMenu={(e) => e.preventDefault()}
              onClick={forwardOnClickHandler}
              alt=''
            />
          </Grid>
        </Grid>
      </footer>
      {isLoading ? (
        <img
          className={classes.loading}
          src={imgLoading}
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
      ) : null}
      <ToolBar toolstate={toolState} />
    </div>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

const mapDispatchToProps = {
  setStudentProblemState: studentActions.setStudentProblemState,
  deductMissionProblemHint: studentActions.deductMissionProblemHint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter);
