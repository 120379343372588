import { combineReducers } from 'redux';
// import alert from './alert';
import auth from './auth';
import state from './state';
import student from './student';
import config from './config';
import branch from './branch';
// import dashboard from './dashboard';
import fight from './fightReducer';
import jump from './jump';
import book from './book';

export default combineReducers({
  // alert,
  auth,
  state,
  student,
  config,
  branch,
  fight,
  jump,
  book
  // dashboard
});
