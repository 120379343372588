import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import cursorImage from '../../images/mouse.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100vw',
    height: '100vh',
    cursor: `url(${cursorImage}),auto`,  
  },
  container: {
    display: 'table',
    height: '100%',
    width: '100%',
  },
  verticalHelper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: 'auto',
    width: 'fit-content',
  },
}));

export default function Wrapper(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} onContextMenu={(e) => e.preventDefault()}>
      <div className={classes.container}>
        <div className={classes.verticalHelper}>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}
