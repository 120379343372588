import React, { Fragment, useState } from 'react';
import { useImagesContext } from '../../contexts/ImagesContext';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from '../../utils/utility';
// import imgState from '../../images/state-state.png';
// import imgLock from '../../images/state-lock.png';
// import imgEffect03 from '../../images/effect-03.png';
const useStyles = makeStyles((theme) => ({
  'state-btn': {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));
const StateItem = ({
  onClick,
  score,
  access,
  unlocked,
  effectTime = 600,
  redBackGround,
}) => {
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();

  const [hideUnlock, setHideUnlock] = useState(false);
  if (unlocked) {
    setTimeout(() => {
      setHideUnlock(true);
    }, effectTime);
  }

  const canAccess =
    (access === true && !unlocked) || (access && unlocked && hideUnlock);

  return (
    <div style={{ position: 'relative', left: 0, top: 0 }}>
      <div
        onClick={() => {
          if (canAccess) {
            onClick();
          }
        }}
        style={{
          position: 'absolute',
          left: 109,
          top: 111,
          width: 83,
          height: 86,
          textAlign: 'center',
        }}
      >
        <img
          src={
            redBackGround
              ? images['state-state2.png']
              : images['state-state.png']
          }
          className={canAccess ? c('state-btn') : null}
          onContextMenu={(e) => e.preventDefault()}
          alt=''
        />
        <div
          onClick={onClick}
          className={canAccess ? 'my-btn state_number' : 'state_number'}
          style={{
            transform: 'translate(-2px,-61px)',
            userSelect: 'none',
            pointerEvents: 'auto',
            height: 0,
          }}
        >
          <strong style={{ pointerEvents: 'auto' }}>
            {score ? score : ''}
          </strong>
        </div>
        {!canAccess && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              src={images['state-lock.png']}
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
          </div>
        )}
        {unlocked && !hideUnlock && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              src={images['effect-03.png']}
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StateItem;
