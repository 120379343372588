import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useImagesContext } from '../../contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  frame: {
    // overflow: 'hidden',
    padding: 36,    
  },
  item: {
    height: 170,
  }
}));

function BossHealth({ bossHealth }) {
  const classes = useStyles();
  const { images } = useImagesContext();

  let health = bossHealth;
  let digit = 0;
  const bossImages = [];
  while (Math.floor(health / 10) > 0) {
    digit++;
    const number = health % 10;
    bossImages.push(<img className={classes.item} key={digit} src={images[`red-number-${number}.png`]} alt="power" />);
    health = Math.floor(health / 10);
  }
  bossImages.push(<img className={classes.item} key={digit+1} src={images[`red-number-${health % 10}.png`]} alt="power" />);
  bossImages.reverse();
  return <div
    style={{
      backgroundImage: `url(${images['frame-background.png']})`,
      backgroundSize: '100% 100%',
    }}
    className={classes.frame}
  >
    {bossImages}
  </div>;
}

export default BossHealth;