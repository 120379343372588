import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { joinClasses } from '../../utils/utility';

import { getBranch as getBranchService } from '../../services/branch';
import { useImagesContext } from '../../contexts/ImagesContext';
import { setSelectedBranch } from '../../actions/branch';
import { handleError } from '../../services/handleResponse';
import { logout } from '../../actions/auth';
import { BOOK_BACKWARD_DURATION, BOOK_FORWARD_DURATION } from '../../constant';
import { bookOpenBackward, bookOpenDisappear, bookOpenForward } from '../../actions/book';
import book from '../../reducers/book';

const ITEM_AMOUNT = 8;

const useStyles = makeStyles((theme) => ({
  book: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
  },

  '@global': {
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },

  branchesContainer: {
    position: 'relative',
    top: 220,
    left: 400,
    height: 600,
    width: 1200,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    zIndex: 1,
    animation: 'fadeInAnimation .2s ease-in-out',
  },

  nav: {
    position: 'absolute',
    padding: '6px',
    bottom: '22px',
    zIndex: 2000,
    '& [class~=MuiSvgIcon-root]': {
      fontSize: 44,
      color: '#bbbbbb82',
    },
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  'nav-next': {
    width: 80,
    bottom: 270,
    right: 220,
  },
  'nav-prev': {
    left: 250,
    bottom: 250,
    // transform: 'rotate(180deg)',
  },
  'branch-item': {
    width: 388,
    height: 117,
    marginBottom: 10,
    textAlign: 'center',
    '&[is-selected="false"]:hover': {
      filter: 'drop-shadow(0px 4px 4px white)',
      transform: 'scale(1.06)',
    },
  },
  'branch-item-selected': {
    '-webkit-animation': '$selected 0.17s',
    animation: '$selected 0.17s',
  },
  '@-webkit-keyframes selected': {
    '0%': {
      transform: 'scale(1.06)',
    },
    '50%': {
      transform: 'scale(1.15)',
    },
    '100%': {
      transform: 'scale(1.06)',
    },
  },
  '@keyframes selected': {
    '0%': {
      transform: 'scale(1.06)',
    },
    '50%': {
      transform: 'scale(1.15)',
    },
    '100%': {
      transform: 'scale(1.06)',
    },
  },
  branchText: {
    paddingLeft: 60,
    paddingTop: 10,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'black',
    textTransform: 'uppercase',
    fontSize: '3.5rem',
  },
}));

const BOOK_STATE = {
  NORMAL: 1,
  OPEN_FORWARD: 2,
  OPEN_BACKWARD: 3,
};

const SelectBranch = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [branchFilter, setIssuesFilter] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const [branch, setBranch] = useState([]);
  const [triggerRender, setTriggerRender] = useState(0);
  const [pageIdx, setPageIdx] = useState(0);
  const [bookState, setBookState] = useState(BOOK_STATE.NORMAL);

  React.useEffect(() => {
    if (!props.auth.isAuthenticated) {
      props.history.push('/login');
    } else {
      getBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setIssuesFilter([...branch]);
  }, [branch]);

  const handleSelectBranch = (selected) => {
    setSelectedBranch(selected);
    props.setSelectedBranch(selected);
    setTriggerRender(1);
    props.bookOpenForward();
    setTimeout(() => {
      setTriggerRender(0);
      props.history.push('/home');
      props.bookOpenDisappear();
    }, BOOK_FORWARD_DURATION);
  };

  const getBranch = () => {
    getBranchService(
      (resp) => {
        setBranch(
          (resp || []).map((it) => {
            return { ...it, id: it.id };
          })
        );
      },
      (error) => {
        setBranch([]);
        handleError(error);
        if (error.response.status === 401) {
          props.logout();
        }
      }
    );
  };

  const getPageSize = (branchFilter, pageIdx) => {
    return branchFilter.slice(
      pageIdx * ITEM_AMOUNT,
      (pageIdx + 1) * ITEM_AMOUNT
    ).length;
  };

  const checkIsWarpReverse = (branchFilter, pageIdx) => {
    const pageSize = getPageSize(branchFilter, pageIdx);
    return pageSize % 2 !== 0 && pageSize !== 3;
  };

  const getTransformItemIdx = (branchFilter, pageIdx, itemIdx) => {
    const pageSize = getPageSize(branchFilter, pageIdx);
    if (checkIsWarpReverse(branchFilter, pageIdx)) {
      const orgOrder = Array.from(Array(pageSize).keys());
      const firstHalfPageSize = Math.floor(pageSize / 2);
      const trnsformOrder = orgOrder
        .slice(firstHalfPageSize)
        .concat(orgOrder.slice(0, firstHalfPageSize));
      return trnsformOrder[itemIdx];
    } else {
      return itemIdx;
    }
  };

  function handleForward() {
    if (pageIdx < branchFilter.length / ITEM_AMOUNT - 1) {
      props.bookOpenForward();
      setTimeout(() => {
        setPageIdx(pageIdx + 1);
        props.bookOpenDisappear();
      }, BOOK_FORWARD_DURATION);
    }
  }

  function handleBack() {
    props.bookOpenBackward();
    setTimeout(() => {
      if (pageIdx > 0) {
        setPageIdx(pageIdx - 1);
      } else {
        props.logout();
      }
      props.bookOpenDisappear();
    }, BOOK_BACKWARD_DURATION);
  }

  console.debug('render: bookState:', bookState);
  console.debug('render: pageIdx:', pageIdx);

  return (
    <div
      className={c('book')}
      style={{
        backgroundImage: `url(${images['book.png']})`,
      }}
    >
      {bookState === BOOK_STATE.NORMAL && (
        <>
          {branchFilter.length > (pageIdx + 1) * ITEM_AMOUNT && (
            <img
              src={images['forward.png']}
              className={c('nav nav-next')}
              onClick={handleForward}
              alt=''
            />
          )}
          <img
            src={images['back.png']}
            className={c('nav nav-prev')}
            onClick={handleBack}
            alt=''
          />
          <div className={c('branchesContainer')}>
            {branchFilter
              .slice(pageIdx * ITEM_AMOUNT, (pageIdx + 1) * ITEM_AMOUNT)
              // .slice(0, ITEM_AMOUNT)
              .map((branch, idx) => {
                const pageSize = getPageSize(branchFilter, pageIdx);
                console.debug('pageSize:', pageSize);
                return (
                  <div
                    key={branch.id}
                    style={{
                      backgroundImage: `url(${images[`branch.png`]})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      width: 500,
                      // height: 80,
                    }}
                    className={c(
                      'branch-item branchText ' +
                      (triggerRender &&
                        selectedBranch &&
                        selectedBranch.id === branch.id
                        ? 'branch-item-selected'
                        : '')
                    )}
                    is-selected={Boolean(
                      selectedBranch && selectedBranch.id === branch.id
                    ).toString()}
                    onClick={() => handleSelectBranch(branch)}
                  >
                    {branch.name}
                  </div>
                );
              })}
          </div>
        </>)}
    </div>
  );
};

const mapStateToProps = ({ config, auth }) => ({
  auth,
  config,
});

const mapDispatchToProps = { setSelectedBranch, logout, bookOpenForward, bookOpenBackward, bookOpenDisappear };

export default connect(mapStateToProps, mapDispatchToProps)(SelectBranch);
