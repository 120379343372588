import { STATE_DATA_SAVE } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STATE_DATA_SAVE:
      return { ...payload };
    default:
      return state;
  }
}
