import React, { useEffect, useState } from 'react';
import { useImagesContext } from '../../contexts/ImagesContext';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  book: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
  },
}));

export const BookOpenForward = ({ open }) => {
  const [png, setPng] = useState(null);
  const { images } = useImagesContext();
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setPng(images['book-forward.png']);
    } else {
      setPng(null);
    }
  }, [open]);

  return (<>
    {open && (
      <img
        className={classes.book}
        style={{ zIndex: 9999 }}
        // src={images['book-forward.png'] + '?r=' + Date.now()}
        src={png}
        alt=''
      />
    )}</>
  );
};


export const BookOpenBackward = ({ open }) => {
  const [png, setPng] = useState(null);
  const { images } = useImagesContext();
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setPng(images['book-backward.png']);
    } else {
      setPng(null);
    }
  }, [open]);

  return (<>
    {open && (
      <img
        className={classes.book}
        style={{ zIndex: 9999 }}
        // src={images['book-backward.png'] + '?r=' + Date.now()}
        src={png}
        alt=''
      />
    )}</>
  );
};
