import * as type from './types';

export const setMuteSound = val => async dispatch => {
    console.log('setMuteSound:', val)
    if (!val) {
        window.localStorage.removeItem('isMutedSound');
    } else {
        window.localStorage.setItem('isMutedSound', !!val);
    }

    return dispatch({
        type: type.SET_MUTE_SOUND,
        payload: { value: !!val }
    });
};

