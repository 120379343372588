import axios from '../host';

// const config = {
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };

export const getstudentInfo = (studentId) => {
  return axios.get(`/api/play/students/${studentId}/data/`);
};

export const getProblemStateList = (studentId, level) => {
  return axios.get(`/api/student/problem/${studentId}/state/${level}`);
};

export const getChapterStateList = (studentId, problemId) => {
  return axios.get(`/api/student/problem/${studentId}/items/${problemId}`);
};

export const updateChapterState = (studentId, problemId, scores) => {
  return axios.post(`/api/student/problem/${studentId}/items/${problemId}`, {
    items: scores,
  });
};

export const getLevelTimeout = (level) => {
  return axios.get('/api/problem/levelTimeout/' + level);
};

export const playFinish = (studentId, capture, win) => {
  return axios.post(`/api/play/students/${studentId}/finish`, {
    capture,
    win,
  });
};
