import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import Swal from 'sweetalert2';

import Chapter from './Chapter/Chapter';
import imgLoading from '../../../../images/circle-loading-animation.gif';
import {
  getProblemStateList as getProblemStateListService,
  getLevelTimeout as getLevelTimeoutService,
} from '../../../../services/problem';
import UIfx from 'uifx';
import { connect } from 'react-redux';
import soundUnLockedFile from '../../../../sounds/13.mp3';
import soundClearFile from '../../../../sounds/14.wav';
import soundEnterLevelFile from '../../../../sounds/15.wav';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '1790px',
    height: '1120px',
  },
  grid: {
    width: '1250px',
    height: '900px',
    marginTop: '50px',
  },
  imageBook: {
    height: 'fit-content',
  },
  imageLock: {
    position: 'absolute',
    top: '2px',
    left: '70px',
  },
  imageClear: {
    position: 'absolute',
    top: '90px',
    left: '10px',
  },
  imageLockEffect: {
    position: 'absolute',
    top: '-145px',
    left: '-60px',
  },
  imageClearEffect: {
    position: 'absolute',
    zIndex: '9999',
    width: '480px',
    height: '280px',
    top: '8px',
    left: '-50px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '&:hover': {
      '& [lock="false"]': {
        transform: 'scale(1.05)',
        filter: 'drop-shadow(-4px 18px 18px black)',
        '& + [imageclear]': {
          transform: 'scale(1.05)',
          filter: 'drop-shadow(-4px 8px 2px black)',
        },
      },
      '& [title]': {
        transform: 'scale(1.05)',
        filter: 'drop-shadow(0px 4px 2px black)',
      },
    },
  },
  imageBack: {
    marginLeft: '60px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  numberCircle: {
    position: 'absolute',
    left: '290px',
    top: '320px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    padding: '8px',

    background: '#fff',
    border: '4px solid #345673',
    color: '#2d2d2d',
    textAlign: 'center',
    font: '36px Arial, sans-serif',
  },
  imageProblemLabel: {
    position: 'absolute',
    left: '20px',
    filter: 'drop-shadow(0px 4px 2px black)',
  },
  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundUnLocked = new UIfx(soundUnLockedFile);
const soundClear = new UIfx(soundClearFile);
const soundEnterState = new UIfx(soundEnterLevelFile);

const State = (props) => {
  const classes = useStyles();
  const { level, student, config } = props;
  const [item, setItem] = useState([]);
  const [isChapterDisp, setIsChapterDisp] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [levelTimeout, setLevelTimeout] = useState(0);
  const [backToState, setBackToState] = useState(false);
  const [startUnlockedEffect, setStartUnlockedEffect] = useState(false);
  const [startClearEffect, setStartClearEffect] = useState(false);
  //const [refClearEffect, setRefClearEffect] = useState(false);
  const refClearEffect = useRef();
  const { images } = useImagesContext();
  const isInited = React.useRef(false);
  React.useEffect(() => {
    // setIsLoading(true);
    // getProblemStateList();
    // getLevelTimeout();
  }, []);

  React.useEffect(() => {
    if (backToState) {
      console.log('setBackToState:');
      setIsLoading(true);
      getProblemStateList();
      setBackToState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToState]);

  const initial = () => {
    if (!isInited.current) {
      setIsLoading(true);
      getProblemStateList();
      getLevelTimeout();
      isInited.current = true;
    }
  };

  const chapterBackHandler = () => {
    console.log('chapterBackHandler:');
    setIsChapterDisp(false);
    setBackToState(true);
  };

  const getProblemStateList = () => {
    getProblemStateListService(student.id, level)
      .then((resp) => {
        setIsLoading(false);
        const updateItem = resp.data;
        // updateItem[5].locked = true;
        //updateItem[4].locked = true;
        if (backToState) {
          // updateItem[5].locked = false;
          // updateItem[5].clear = true;
          updateItem.forEach((updateIt) => {
            item.forEach((prevIt) => {
              if (updateIt.problemId === prevIt.problemId) {
                if (prevIt.locked && !updateIt.locked) {
                  updateIt.unlockedEffect = true;
                }
                if (!prevIt.clear && updateIt.clear) {
                  updateIt.clearEffect = true;
                }
              }
            });
          });
          setTimeout(() => {
            setStartUnlockedEffect(true);
            setStartClearEffect(true);
          }, 300);
        }
        setItem(updateItem);
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire({
          text: `${err}`,
          icon: "error"
        });  
        console.error('getProblemStateListService:', err);
      });
  };

  const getLevelTimeout = () => {
    getLevelTimeoutService(level)
      .then((resp) => {
        setLevelTimeout(resp.data.timeout);
      })
      .catch((error) => { });
  };

  const unlockedEffectHandler = (idx) => {
    if (item[idx].unlockedEffect) {
      !config.isMutedSound && soundUnLocked.play(0.4);
      setTimeout(() => {
        setItem((item) => {
          const tItem = item;
          tItem[idx].unlockedEffect = false;
          return [...tItem];
        });
        setStartUnlockedEffect(false);
      }, 500);
    }
  };

  const clearEffectHandler = (idx) => {
    if (item[idx].clearEffect && !refClearEffect.current) {
      //setRefClearEffect(true);
      refClearEffect.current = true;
      !config.isMutedSound && soundClear.play(0.4);
      setTimeout(() => {
        setItem((item) => {
          const tItem = item;
          tItem[idx].clearEffect = false;
          return [...tItem];
        });
        //setRefClearEffect(false);
        refClearEffect.current = false;
        setStartClearEffect(false);
      }, 600);
    }
  };

  return (
    <React.Fragment>
      {isChapterDisp ? (
        <Chapter
          problem={selectItem}
          levelTimeout={levelTimeout}
          backHandler={chapterBackHandler}
        />
      ) : (
          <div
            style={{
              backgroundImage: `url(${images['state_backgroundall.jpg']})`,
            }}
            className={classes.root}
            onLoad={initial}
          >
            <div
              style={{
                backgroundImage: `url(${images['book2.png']})`,
              }}
              className={classes.container}
              // className={classes.container}
            >
              <img
                className={classes.imageProblemLabel}
                src={images['problem.png']}
                id="problem"
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
              <Grid
                container
                className={classes.grid}
                direction="column"
                alignItems="center"
              >
                {item.map((it, idx) => (
                  <Grid
                    key={it.problemId}
                    className={classes.imgContainer}
                    item
                    xs={4}
                    onClick={() => {
                      if (!it.locked) {
                        !config.isMutedSound && soundEnterState.play(0.4);
                        setSelectItem(it);
                        setTimeout(() => {
                          setIsChapterDisp(true);
                        }, 200);
                        setStartUnlockedEffect(false);
                        setStartClearEffect(false);
                      }
                    }}
                  >
                    {it.unlockedEffect && startUnlockedEffect ? (
                      <img
                        ref={() => unlockedEffectHandler(idx)}
                        className={classes.imageLockEffect}
                        src={images['effect04.png']}
                        id={'lockeffect_' + it.problemId}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=''
                      />
                    ) : null}

                    {it.clearEffect && startClearEffect ? (
                      <img
                        ref={() => clearEffectHandler(idx)}
                        className={classes.imageClearEffect}
                        src={images['s_effect01.png']}
                        id={'cleareffect_' + it.problemId}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=''
                      />
                    ) : null}
                    <img
                      className={classes.imageBook}
                      src={images[`state_bookopen${idx + 1}.png`]}
                      id={it.problemId}
                      lock={it.locked ? 'true' : 'false'}
                      onContextMenu={(e) => e.preventDefault()}
                      alt=''
                    />
                    {it.locked ? (
                      <img
                        className={classes.imageLock}
                        src={images['state_lock.png']}
                        id={'lock_' + it.problemId}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=''
                      />
                    ) : null}
                    {!it.locked && it.clear ? (
                      <img
                        imageclear=""
                        className={classes.imageClear}
                        src={images['clearsmall.png']}
                        id={'clear_' + it.problemId}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=''
                      />
                    ) : null}
                  </Grid>
                ))}
              </Grid>
            </div>
            <footer id="footer" className={classes.footer}>
              <Grid container justify="space-between">
                <Grid item xs container>
                  <img
                    className={classes.imageBack}
                    src={images['state_back.png']}
                    id="back"
                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => props.backHandler()}
                    alt=''
                  />
                </Grid>
              </Grid>
            </footer>
            {isLoading ? (
              <img
                className={classes.loading}
                src={imgLoading}
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
            ) : null}
          </div>
        )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

export default connect(mapStateToProps, null)(State);
