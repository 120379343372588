import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import UIfx from 'uifx';
import Swal from 'sweetalert2';

import State from './State/State';
import { getProblemLevelList as getProblemLevelListService } from '../../../services/problem';
import imgLoading from '../../../images/circle-loading-animation.gif';
// import { useRouteMatch } from 'react-router-dom';
import soundUnLockedFile from '../../../sounds/13.mp3';
import soundClearFile from '../../../sounds/14.wav';
import soundEnterLevelFile from '../../../sounds/15.wav';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '1790px',
    height: '1120px',
  },
  grid: {
    width: '1700px',
    height: '1080px',
  },
  imageBook: {},
  imageLockEffect: {
    position: 'absolute',
    top: '-150px',
    left: '-90px',
  },
  imageClearEffect: {
    // position: 'absolute',
    position: 'absolute',
    zIndex: '9999',
    width: '400px',
    height: '240px',
    top: '8px',
    left: '-50px',
  },
  imageLock: {
    position: 'absolute',
    top: '2px',
    left: '50px',
  },
  imageClear: {
    position: 'absolute',
    top: '80px',
    left: '-8px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    '&:hover': {
      '& [lock="false"]': {
        transform: 'scale(1.05)',
        filter: 'drop-shadow(-4px 12px 6px black)',
        '& + [imageclear]': {
          transform: 'scale(1.05)',
          filter: 'drop-shadow(-4px 8px 2px black)',
        },
      },
    },
  },
  imageBack: {
    marginLeft: '60px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageProblemLabel: {
    position: 'absolute',
    left: '20px',
    filter: 'drop-shadow(0px 4px 2px black)',
  },
  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundUnLocked = new UIfx(soundUnLockedFile);
const soundClear = new UIfx(soundClearFile);
const soundEnterLevel = new UIfx(soundEnterLevelFile);

const Problem = (props) => {
  const { config } = props;
  const classes = useStyles();
  const [item, setItem] = useState([]);
  const [colItem, setColItem] = useState([]);
  const [isChapterDisp, setIsChapterDisp] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [backToLevel, setBackToLevel] = useState(false);
  const [startUnlockedEffect, setStartUnlockedEffect] = useState(false);
  const [startClearEffect, setStartClearEffect] = useState(false);
  const [refClearEffect, setRefClearEffect] = useState(false);
  const { images } = useImagesContext();
  const isInited = React.useRef(false);

  React.useEffect(() => {
    //initial();
  }, []);
  // const { url } = useRouteMatch();
  React.useEffect(() => {
    if (!props.student || !props.student.id) {
      props.history.push(`/home`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.student]);

  React.useEffect(() => {
    if (backToLevel) {
      setIsLoading(true);
      getProblemLevel();
      setBackToLevel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToLevel]);

  const initial = () => {
    if (!isInited.current) {
      setIsLoading(true);
      getProblemLevel();
      isInited.current = true;
    }
  };

  const unlockedEffectHandler = (colIdx, rowIdx) => {
    if (colItem[colIdx][rowIdx].unlockedEffect) {
      !config.isMutedSound && soundUnLocked.play(0.4);
      setTimeout(() => {
        setColItem((colItem) => {
          const tcolItem = colItem;
          tcolItem[colIdx][rowIdx].unlockedEffect = false;
          return [...tcolItem];
        });
        setStartUnlockedEffect(false);
      }, 500);
    }
  };

  const clearEffectHandler = (colIdx, rowIdx) => {
    if (colItem[colIdx][rowIdx].clearEffect && !refClearEffect) {
      setRefClearEffect(true);
      !config.isMutedSound && soundClear.play(0.4);
      setTimeout(() => {
        setColItem((colItem) => {
          const tcolItem = colItem;
          tcolItem[colIdx][rowIdx].clearEffect = false;
          return [...tcolItem];
        });
        setRefClearEffect(false);
        setStartClearEffect(false);
      }, 600);
    }
  };

  const getProblemLevel = () => {
    getProblemLevelListService(props.student.id)
      .then((resp) => {
        setIsLoading(false);        
        const updateItem = (resp.data || []).map((it) => ({
          ...it,
          id: it.level,
          src: images[`${it.level}.png`],
        }));

        //hardcode for test
        //updateItem[8].clear = false;
        //updateItem[9].locked = true;
        if (backToLevel) {
          //updateItem[3].clear = true;
          //updateItem[8].clear = true;
          //updateItem[9].locked = false;
          updateItem.forEach((updateIt) => {
            item.forEach((prevIt) => {
              if (updateIt.level === prevIt.level) {
                if (prevIt.locked && !updateIt.locked) {
                  updateIt.unlockedEffect = true;
                }
                if (!prevIt.clear && updateIt.clear) {
                  updateIt.clearEffect = true;
                }
              }
            });
          });
          setTimeout(() => {
            setStartClearEffect(true);
            setStartUnlockedEffect(true);
          }, 300);
        }

        setItem(updateItem);
        setColItem([
          [updateItem[0], updateItem[1]],
          [updateItem[2], updateItem[3], updateItem[4]],
          [updateItem[5], updateItem[6], updateItem[7]],
          [updateItem[8], updateItem[9]],
        ]);
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire({
          text: `${err}`,
          icon: "error"
        });        
      });
  };

  const chapterBackHandler = () => {
    setIsChapterDisp(false);
    setBackToLevel(true);
  };

  return (
    <React.Fragment>
      {isChapterDisp ? (
        <State level={selectItem.level} backHandler={chapterBackHandler} />
      ) : (
        <div
          style={{
            backgroundImage: `url(${images['backgroundall.jpg']})`,
          }}
          className={classes.root}
          onLoad={initial}
        >
          <div
            style={{
              backgroundImage: `url(${images['book2.png']})`,
            }}
            className={classes.container}
          >
            <img
              className={classes.imageProblemLabel}
              src={images['problem.png']}
              id="problem"
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
            <Grid container className={classes.grid} spacing={10}>
              <Grid item xs={1}></Grid>
              {colItem.map((item, colIdx) => (
                <Grid
                  key={'col' + colIdx}
                  item
                  xs
                  container
                  direction="column"
                  justify="center"
                  spacing={3}
                >
                  {(item || []).map((it, rowIdx) => (
                    <Grid
                      key={'row' + it.id}
                      className={classes.imgContainer}
                      item
                      container
                      onClick={() => {
                        if (!it.locked) {
                          !config.isMutedSound && soundEnterLevel.play(0.4);
                          setIsChapterDisp(true);
                          setSelectItem(it);
                          setStartClearEffect(false);
                          setStartUnlockedEffect(false);
                        }
                      }}
                    >
                      <img
                        className={classes.imageBook}
                        src={it.src}
                        id={it.id}
                        lock={it.locked ? 'true' : 'false'}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=''
                      />
                      {it.unlockedEffect && startUnlockedEffect ? (
                        <img
                          ref={() => unlockedEffectHandler(colIdx, rowIdx)}
                          className={classes.imageLockEffect}
                          src={images['effect04.png']}
                          id={'lockeffect_' + it.id}
                          onContextMenu={(e) => e.preventDefault()}
                          alt=''
                        />
                      ) : null}
                      {it.clearEffect && startClearEffect ? (
                        <img
                          ref={() => clearEffectHandler(colIdx, rowIdx)}
                          className={classes.imageClearEffect}
                          src={images['p_effect01.png']}
                          id={'cleareffect_' + it.id}
                          onContextMenu={(e) => e.preventDefault()}
                          alt=''
                        />
                      ) : null}
                      {it.locked ? (
                        <img
                          className={classes.imageLock}
                          src={images['lock.png']}
                          id={'lock_' + it.id}
                          onContextMenu={(e) => e.preventDefault()}
                          alt=''
                        />
                      ) : null}
                      {!it.locked && it.clear ? (
                        <img
                          imageclear=""
                          className={classes.imageClear}
                          src={images['clearsmall.png']}
                          id={'clear_' + it.id}
                          onContextMenu={(e) => e.preventDefault()}
                          alt=''
                        />
                      ) : null}
                    </Grid>
                  ))}
                </Grid>
              ))}

              <Grid item xs={1}></Grid>
            </Grid>
          </div>
          <footer id="footer" className={classes.footer}>
            <Grid container justify="space-between">
              <Grid item xs container>
                <img
                  className={classes.imageBack}
                  src={images['back.png']}
                  id="back"
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={() => {
                    props.history.goBack();
                    //props.backHandler()
                  }}
                  alt=''
                />
              </Grid>
            </Grid>
          </footer>
          {isLoading ? (
            <img
              className={classes.loading}
              src={imgLoading}
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

export default connect(mapStateToProps, null)(Problem);
