export const cardColors = {
  category0: '#c5c1c1',
  category1: '#f3577a',
  category2: '#ff9595',
  category3: '#FFA36C',
  category4: '#f7e57f',
  category5: '#80eca9',
  category6: '#69b9a1',
  category7: '#80daec',
  category8: '#80b7ec',
  category9: '#c580ec'
};

export const mapCategoryClass = {
  Retention: 'category1',
  Unmatch: 'category2',
  Enquiry: 'category3',
  'Verification Welcome call': 'category4',
  Sale: 'category5',
  Complaint: 'category6',
  'Account Maintenance': 'category7',
  Suggestion: 'category8',
  Service: 'category9'
};
