import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { login } from '../../actions/auth';
import Loading from '../layout/Loading';

import { useImagesContext } from '../../contexts/ImagesContext';
import { joinClasses } from '../../utils/utility';
import { makeStyles } from '@material-ui/core/styles';
import { login as loginService } from '../../services/login';

import { handleError } from '../../services/handleResponse';
import { BOOK_FORWARD_DURATION } from '../../constant';
import { BookOpenForward } from '../utils/book';
import { bookOpenDisappear, bookOpenForward } from '../../actions/book';

const useStyles = makeStyles((theme) => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },
  book: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
  },
  ok: {
    position: 'absolute',
    left: 1210,
    top: 715,
    width: 82,
    height: 82,
  },
  input: {
    position: 'relative',
    // width: 428,
    left: 750,
    width: 380,
    height: 87,
    paddingLeft: 65,
    display: 'block',
    // margin: 'auto',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 60,
    textAlign: 'center',
    border: 'None',
    '&:focus': {
      borderColor: '#66afe9',
      boxShadow:
        'inset 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(102, 175, 233, 0.6)',
      outline: 0,
    },
  },
}));
const Login = ({
  login,
  loading,
  history,
  bookOpenForward,
  bookOpenDisappear,
}) => {
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();

  const [formData, setFormData] = useState({
    name: '',
    password: '',
  });

  const { name, password } = formData;

  const onChange = (e) => {
    console.debug('onChange');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    loginService(name, password)
      .then((res) => {
        console.debug('loginService', res.data);
        bookOpenForward();
        setTimeout(() => {
          login(res.data);
          history.push('/branch');
          bookOpenDisappear();
        }, BOOK_FORWARD_DURATION);
      })
      .catch((err) => {
        console.error(err);
        handleError(err);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      className={c('background')}
      style={{
        backgroundImage: `url(${images['login-content.png']})`,
      }}
    >
      <form onSubmit={onSubmit}>
        <input
          type='text'
          className={c('input')}
          style={{
            background: `url(${images['name.png']}) no-repeat left top`,
            top: 600,
          }}
          name='name'
          onChange={onChange}
          autoComplete='off'
          spellCheck='false'
          placeholder='NAME'
        />
        <input
          type='password'
          className={c('input')}
          style={{
            background: `url(${images['pass.png']}) no-repeat left top`,
            top: 620,
          }}
          name='password'
          onChange={onChange}
          autoComplete='off'
          spellCheck='false'
          placeholder='PASSWORD'
        />
        <button type='submit' hidden>
          OK
        </button>
      </form>
      <div className={c('point ok')}>
        <img src={images['ok.png']} alt='' onClick={onSubmit} />
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  bookOpenForward: PropTypes.func.isRequired,
  bookOpenDisappear: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  login,
  bookOpenForward,
  bookOpenDisappear,
})(Login);
