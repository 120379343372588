import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import UIfx from 'uifx';
import { from, fromEvent, race, interval } from 'rxjs';
import { switchMap, debounceTime, zip } from 'rxjs/operators';
import { initBoard } from './mock';
import Score from './Score/Score';
import 'wgo';
// import imgLoading from '../../images/circle-loading-animation.gif';

import soundStartFile from '../../sounds/30.ogg';
import soundTripleFile from '../../sounds/31.mp3';
import soundFantasticFile from '../../sounds/32.mp3';
import soundExcellentFile from '../../sounds/33.mp3';
import soundDeathFile from '../../sounds/23.mp3';
import soundMoveFile from '../../sounds/26.mp3';
import soundFireShootFile from '../../sounds/27.mp3';

import soundMainCase01 from '../../sounds/34.mp3';
import soundMainCase02 from '../../sounds/35.mp3';
import soundMainCase03 from '../../sounds/36.wav';
import soundMainCase04 from '../../sounds/37.wav';
import soundMainCase05 from '../../sounds/38.ogg';
import soundMainCaseDefault from '../../sounds/21.ogg';

const soundStart = new UIfx(soundStartFile);
const soundTriple = new UIfx(soundTripleFile);
const soundFantastic = new UIfx(soundFantasticFile);
const soundExcellent = new UIfx(soundExcellentFile);

const soundDeath = new UIfx(soundDeathFile);
const soundMove = new UIfx(soundMoveFile);
const soundFireShoot = new UIfx(soundFireShootFile);

// const soundFireShoot = new UIfx(soundFireShootFile), {
//   volume: 0.3,
//   throttleMs: 1000,
// });

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1920px',
    height: '1080px',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  boardContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    //width: '100%',
    width: '1020px',
    display: 'flex',
    alignItems: 'center',
  },
  board: {
    width: '100%',
    height: 'fit-content',
    transform: 'translate(-1.5px,-5px)',
    zIndex: '5000',
  },
  boardPointWrapper: {
    // display: 'flex',
    // height: 'fit-content',
    // width: 'fit-content',
    cursor: 'pointer !important',
  },
  boardPoint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100px',
    height: '100px',
    padding: '2px',
    // minWidth: '100px',
    // minHeight: '100px',
  },
  fireBoardTop: {
    position: 'absolute',
    transform: 'translate(-8px) rotate(180deg)',
    left: 'calc(50% - 488px)',
    top: '46px',
    '& img': {
      width: '960px;',
      height: '110px',
    },
  },
  fireBoardBottom: {
    position: 'absolute',
    transform: 'translate(-5px)',
    left: 'calc(50% - 474px)',
    bottom: '46px',
    '& img': {
      width: '960px;',
      height: '110px',
    },
  },
  boardHover: {
    position: 'absolute',
    // left: 'calc(50% - 35px)',
    // top: 'calc(50% - 36.6px)',
    // width: '100px',
    // height: '100px',
    '& img': {
      display: 'none',
    },
    '&:hover': {
      //cursor: 'pointer',
      '& img': {
        display: 'block',
        opacity: '0.65',
        // width: '100px',
        // height: '100px',
      },
    },
  },
  leftTool: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '55px',
    marginLeft: '68px',
    height: '122px',
    '& img:not(:last-child)': {
      marginRight: '16px',
    },
    '& [passl="enable"]:hover, [undo="enable"]:hover, [back]:hover': {
      //cursor: 'pointer',
      transform: 'scale(1.05)',
      filter: 'drop-shadow(-4px 8px 8px black)',
    },
    '& [passl="clicked"]': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(-4px 8px 9px black)',
    },
  },
  rightTool: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '55px',
    marginRight: '68px',
    height: '122px',
    '& img:not(:last-child)': {
      marginRight: '16px',
    },
    '& [passr="enable"]:hover, [finish="enable"]:hover, [playagain]:hover': {
      //cursor: 'pointer',
      transform: 'scale(1.05)',
      filter: 'drop-shadow(-4px 7px 7px black)',
    },
    '& [passr="clicked"]': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(-4px 8px 9px black)',
    },
  },

  leftPlayer: {
    position: 'relative',
    width: '100%',
    height: '360px',
    marginBottom: '55px',
    '& [playerstarimg]': {
      position: 'absolute',
      zIndex: '5001',
      top: '-145px',
      left: '15px',
    },
    '& [playerframeimg]': {
      position: 'absolute',
      left: '5px',
    },
    '& [playerimg]': {
      position: 'absolute',
      zIndex: '5000',
      left: 'calc(50% - 159px)',
      top: 'calc(50% - 112px)',
    },
    '& [playerminstarimg]': {
      position: 'absolute',
      zIndex: '5001',
      bottom: '-8px',
      left: '130px',
    },
    '& [playerminstarimg="enable"]:hover, [playerminstarimg="clicked"], [playerminstarimg="clicked"]:hover': {
      //cursor: 'pointer',
      transform: 'scale(1.1)',
      filter: 'drop-shadow(-4px 6px 6px black)',
    },
    '& [atarinum]': {
      position: 'absolute',
      zIndex: '5100',
      display: 'flex',
      justifyContent: 'center',
      width: '250px',
      height: '100px',
      left: '20px',
      top: '-68px',
      '& [atarinumdigit1],[atarinumdigit2],[minus]': {
        filter: 'drop-shadow(-2px 4px 3px black)',
      },
      '& img:not(:last-child)': {
        marginRight: '5px',
      },
    },
    '& [summarypoint]': {
      position: 'absolute',
      zIndex: '5100',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: '50px',
      top: '-430px',
      '& [summarypointdigit1],[summarypointdigit2],[minus]': {
        filter: 'drop-shadow(-2px 4px 3px black)',
      },
      '& [minus]': {
        height: 'fit-content',
      },
      '& img:not(:last-child)': {
        marginRight: '5px',
      },
    },
    '& [getpointeffectl]': {
      position: 'absolute',
      zIndex: '6000',
      right: '58px',
      top: '-300px',
    },
    '& [fireshootl]': {
      position: 'absolute',
      zIndex: '7000',
      top: '-664px',
    },
  },
  rightPlayer: {
    position: 'relative',
    width: '100%',
    height: '360px',
    marginBottom: '55px',
    '& [playerstarimg]': {
      position: 'absolute',
      zIndex: '5001',
      top: '-145px',
      right: '15px',
    },
    '& [playerframeimg]': {
      position: 'absolute',
      right: '5px',
    },
    '& [playerimg]': {
      position: 'absolute',
      zIndex: '5000',
      left: 'calc(50% - 61px)',
      top: 'calc(50% - 112px)',
    },
    '& [playerminstarimg]': {
      position: 'absolute',
      zIndex: '5001',
      bottom: '-8px',
      right: '130px',
    },
    '& [playerminstarimg="enable"]:hover, [playerminstarimg="clicked"], [playerminstarimg="clicked"]:hover': {
      //cursor: 'pointer',
      transform: 'scale(1.1)',
      filter: 'drop-shadow(-4px 6px 6px black)',
    },
    '& [atarinum]': {
      position: 'absolute',
      zIndex: '5100',
      display: 'flex',
      justifyContent: 'center',
      width: '250px',
      height: '100px',
      right: '20px',
      top: '-68px',
      '& [atarinumdigit1],[atarinumdigit2],[minus]': {
        filter: 'drop-shadow(-2px 4px 3px black)',
      },
      '& img:not(:last-child)': {
        marginRight: '5px',
      },
    },
    '& [summarypoint]': {
      position: 'absolute',
      zIndex: '5100',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      right: '50px',
      top: '-430px',
      '& [summarypointdigit1],[summarypointdigit2],[minus]': {
        filter: 'drop-shadow(-2px 4px 3px black)',
      },
      '& [minus]': {
        height: 'fit-content',
      },
      '& img:not(:last-child)': {
        marginRight: '5px',
      },
    },
    '& [getpointeffectr]': {
      position: 'absolute',
      zIndex: '6000',
      left: '58px',
      top: '-300px',
    },
    '& [fireshootr]': {
      position: 'absolute',
      //'-webkit-transform': 'scaleX(-1)',
      //transform: 'scaleX(-1)',
      zIndex: '7000',
      top: '-664px',
      left: '-1520px',
    },
  },
  turn: {
    zIndex: '5500 !important',
    transform: 'scale(1.22)',
    filter: 'drop-shadow(-4px 6px 4px black)',
  },
  imgHover: {
    '&:hover': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    top: 0,
    //top: '132px',
    //margin: 'auto',
    // top: 'calc(50% - 250px)',
    // left: 'calc(50% - 250px)',
  },
}));

const GamePlay = (props) => {
  const classes = useStyles();
  const { student, history, playerInfo } = props;
  const { images } = useImagesContext();
  const [board, setBoard] = useState([...initBoard]);
  const [boardHistory, setBoardHistory] = useState([[...board]]);
  const [turn, setTurn] = useState('L');
  const [isFinished, setIsFinished] = useState(false);
  const [actionType, setActionType] = useState('MOVE');
  const [isPassL, setIsPassL] = useState(false);
  const [isPassR, setIsPassR] = useState(false);
  const [isMinStarL, setIsMinStarL] = useState(false);
  const [isMinStarR, setIsMinStarR] = useState(false);
  const [isShowFinishBtn, setIsShowFinishBtn] = useState(0);
  const [playLAreaPoint, setPlayLAreaPoint] = useState(0);
  const [playLAtariPoint, setPlayLAtariPoint] = useState(0);
  const [playRAreaPoint, setPlayRAreaPoint] = useState(0);
  const [playRAtariPoint, setPlayRAtariPoint] = useState(0);
  const [
    playLAreaPointSurrenderCase,
    setPlayLAreaPointSurrenderCase,
  ] = useState(0);

  const [
    playRAreaPointSurrenderCase,
    setPlayRAreaPointSurrenderCase,
  ] = useState(0);
  // const [
  //   playLAtariPointSurrenderCase,
  //   setPlayLAtariPointSurrenderCase,
  // ] = useState(0);
  // const [
  //   playRAtariPointSurrenderCase,
  //   setPlayRAtariPointSurrenderCase,
  // ] = useState(0);
  const [
    playLAtariScoreSurrenderCase,
    setPlayLAtariScoreSurrenderCase,
  ] = useState(0);
  const [playRAtariCnt, setPlayRAtariCnt] = useState(0);
  const [playLAtariCnt, setPlayLAtariCnt] = useState(0);
  const [playLAtariScore, setPlayLAtariScore] = useState(0);
  const [playRAtariScore, setPlayRAtariScore] = useState(0);
  const [countStep, setCountStep] = useState(0);
  const [isFinishedCalPoint, setIsFinishedCalPoint] = useState(false);
  const [trickInterval, setTrickInterval] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isStartCalPointLPlayer, setIsStartCalPointLPlayer] = useState(false);
  const [isStartCalPointRPlayer, setIsStartCalPointRPlayer] = useState(false);
  const [actionPos, setActionPos] = useState([null, null]);
  const [deathEffect, setDeathEffect] = useState({});
  const [playerGetPointEffect, setPlayerGetPointEffect] = useState('N');
  const [endPointEffectPos, setEndPointEffectPos] = useState(null);
  const [playerLFireShootEffect, setPlayerLFireShootEffect] = useState(false);
  const [playerRFireShootEffect, setPlayerRFireShootEffect] = useState(false);
  const [dispScoreSummary, setDispScoreSummary] = useState(false);
  const [curPos, setCurPos] = useState([null, null]);
  const [captures, setCaptures] = useState([]);
  const [isDispStartEffect, setIsDispStartEffect] = useState(true);
  const [isDispBoard, setIsDispBoard] = useState(false);
  const [isDispTripleEffect, setIsDispTripleEffect] = useState(false);
  const [isDispFantasticEffect, setIsDispFantasticEffect] = useState(false);
  const [isDispExcellentEffect, setIsDispExcellentEffect] = useState(false);
  const [retrigNumber, setRetrigNumber] = useState(0);

  const isSurrender = useRef(false);

  const getMainSound = (selectedBgId) => {
    switch (selectedBgId) {
      case '01':
        return soundMainCase01;
      case '02':
        return soundMainCase02;
      case '03':
        return soundMainCase03;
      case '04':
        return soundMainCase04;
      case '05':
        return soundMainCase05;
      default:
        return soundMainCaseDefault;
    }
  };

  const soundMain = new UIfx(getMainSound(playerInfo.selectedBgId));

  const pointRef = React.useRef({});
  const boardPointWrapRef = React.useRef({});
  board.forEach((rIt, rIdx) => {
    rIt.forEach((cIt, cIdx) => {
      pointRef.current[rIdx + '_' + cIdx] = React.createRef();
      boardPointWrapRef.current[rIdx + '_' + cIdx] = React.createRef();
    });
  });
  //boardPointWrapRef.current = React.createRef();
  const initialWGO = () => {
    return new window.WGo.Game(9, 'KO', true, false);
  };
  const Game = React.useRef(initialWGO());
  const isPlayedDeathSound = React.useRef(false);

  React.useEffect(() => {
    //setIsDispStartEffect(true);
    playagainOnClickHandler();
    setRetrigNumber(Math.random());
  }, []);

  React.useEffect(() => {
    let subscription = {};
    board.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        const eleRef = pointRef.current[rIdx + '_' + cIdx].current;
        const mousedown$ = fromEvent(eleRef, 'mousedown');
        const click$ = fromEvent(eleRef, 'click');
        const dblclick$ = fromEvent(eleRef, 'dblclick');
        subscription[rIdx + '_' + cIdx] = mousedown$
          .pipe(
            switchMap(() => race(dblclick$, click$.pipe(debounceTime(120))))
          )
          .subscribe((evt) => {
            //console.log('evt:', evt);
            const attrPoint = evt.target.attributes.getNamedItem('point');
            const point =
              attrPoint && attrPoint.value ? attrPoint.value.split(',') : null;
            point && setActionPos([+point[0], +point[1]]);
            if (point && evt.type === 'click') {
              boardOnClickHandler(point[0], point[1]);
            } else if (point && evt.type === 'dblclick') {
              // boardOnDblClickHandler(point[0], point[1]);
            }
          });
      });
    });
    return () => {
      board.forEach((rIt, rIdx) => {
        rIt.forEach((cIt, cIdx) => {
          subscription[rIdx + '_' + cIdx].unsubscribe();
        });
      });
    };
  });

  React.useEffect(() => {
    if (isPassL && isPassR) {
      //console.log('setIsShowFinishBtn');
      setIsShowFinishBtn(true);
    }
  }, [isPassL, isPassR]);

  React.useEffect(() => {
    let lp = 0;
    let rp = 0;
    board.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        cIt === 'LP' && lp++;
        cIt === 'RP' && rp++;
      });
    });
    //console.log('LP : ', lp);
    //console.log('RP : ', rp);
    setPlayLAreaPoint(lp);
    setPlayRAreaPoint(rp);
  }, [board]);

  React.useEffect(() => {
    // if (actionType === 'ATARI') {
    //   console.log('board bef::', [...board]);
    //   //const atariCount = countAtariPoint(turn);
    //   //(turn === 'R' ? playRAtariPoint : playLAtariPoint);
    //   setPlayRAtariCnt(countAtariPoint('R'));
    //   setPlayLAtariCnt(countAtariPoint('L'));
    // }
  }, [countStep]);

  React.useEffect(() => {
    //atariHandler(captures);
    // captures.forEach(({ x, y }) => {
    //   boardOnDblClickHandler(x, y);
    // });
    // const subscription = from(captures)
    //   .pipe(zip(interval(100), (a, b) => a))
    //   .subscribe((pos) => {
    //     //console.log('pos:', [pos.x, pos.y]);
    //     //boardOnDblClickHandler(pos.x, pos.y);
    //     atariHandler(pos);
    //   });
    // return () => {
    //   subscription.unsubscribe();
    // };
  }, [captures]);

  React.useEffect(() => {
    // clear atari point
    if (turn === 'R' || turn === 'L') {
      //setBoard(clearAtariPos(board));
      //console.log(`turn: R , atari: ${playRAtariPoint + playRAtariCnt}`);
      //console.log(`turn: L , atari: ${playLAtariPoint + playLAtariCnt}`);
      setPlayLAtariPoint(playLAtariPoint + playLAtariCnt);
      setPlayRAtariPoint(playRAtariPoint + playRAtariCnt);
      setPlayLAtariScore(playLAtariPoint + playLAtariCnt);
      setPlayRAtariScore(playRAtariPoint + playRAtariCnt);
      setPlayRAtariCnt(0);
      setPlayLAtariCnt(0);
    }
  }, [turn]);

  React.useEffect(() => {
    if (isSurrender.current && !isFinishedCalPoint) {
      const upudatePlayLAreaPoint = playLAreaPoint - playRAtariPoint;
      const upudatePlayRAreaPoint = playRAreaPoint - playLAtariPoint;
      // setPlayLAreaPoint(upudatePlayLAreaPoint);
      // setPlayRAreaPoint(upudatePlayRAreaPoint);
      // setPlayLAtariPoint(0);
      // setPlayRAtariPoint(0);
      setPlayLAreaPointSurrenderCase(upudatePlayLAreaPoint);
      setPlayRAreaPointSurrenderCase(upudatePlayRAreaPoint);
      // setPlayLAtariPointSurrenderCase(0);
      // setPlayRAtariPointSurrenderCase(0);
      setPlayLAtariScoreSurrenderCase(playLAtariScore);

      setIsFinishedCalPoint(true);
      setTimeout(() => {
        setDispScoreSummary(true);
      }, 100);
    } else if (
      (isStartCalPointLPlayer || isStartCalPointRPlayer) &&
      !isFinishedCalPoint
    ) {
      const count$ = interval(isSurrender.current ? 0 : 200);
      const subscription = count$.subscribe(() => {
        setTrickInterval((val) => !val);
      });
      setSubscription(subscription);
      return () => {
        subscription.unsubscribe();
      };
    } else if (isFinishedCalPoint) {
      subscription && subscription.unsubscribe();
    }
  }, [isStartCalPointLPlayer, isStartCalPointRPlayer, isFinishedCalPoint]);

  React.useEffect(() => {
    if (isStartCalPointLPlayer && !isSurrender.current) {
      setPlayRAtariPoint((playRAtariPoint) => {
        if (playRAtariPoint > 0) {
          setPlayLAreaPoint(playLAreaPoint - 1);
        } else {
          setIsFinishedCalPoint(true);
          setIsStartCalPointLPlayer(false);

          setTimeout(() => {
            if (playLAtariPoint > 0) {
              setPlayerLFireShootEffect(true);
            }
            setTimeout(
              () => {
                setIsFinishedCalPoint(false);
                setIsStartCalPointRPlayer(true);
              },
              playLAtariPoint > 0 ? 2000 : 0
            );
          }, 800);

          return 0;
        }
        return playRAtariPoint - 1;
      });
    }
  }, [isStartCalPointLPlayer, trickInterval]);

  React.useEffect(() => {
    if (isStartCalPointRPlayer && !isSurrender.current) {
      setPlayLAtariPoint((playLAtariPoint) => {
        if (playLAtariPoint > 0) {
          setPlayRAreaPoint(playRAreaPoint - 1);
        } else {
          setIsFinishedCalPoint(true);
          setIsStartCalPointRPlayer(false);
          setTimeout(() => {
            setDispScoreSummary(true);
          }, 500);
          return 0;
        }
        return playLAtariPoint - 1;
      });
    }
  }, [isStartCalPointRPlayer, trickInterval]);

  const isTouchDevice = () => {
    var isTouchCapable =
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
    return isTouchCapable;
  };

  // const initialBoardPointWrap = () => {
  //   if (!isTouchDevice() && boardPointWrapRef.current) {
  //     console.log('initialBoardPointWrap:', boardPointWrapRef.current);
  //     boardPointWrapRef.current.style.display = 'flex';
  //   }
  // };

  const boardPointWrapRefCall = (ref, rIdx, cIdx) => {
    //console.log('boardPointWrapRefCall:', ref);
    if (ref) {
      boardPointWrapRef.current[rIdx + '_' + cIdx].current = ref;
      // ref.style.visibility = 'visible';
      if (!isTouchDevice()) {
        ref.style.cursor = 'pointer';
      } else {
        ref.style.cursor = 'inherit';
      }
    }
  };

  // const boardPointOnClick = (rIdx, cIdx) => {
  //   pointRef.current[rIdx + '_' + cIdx].current.style.visibility = 'hidden';
  // };

  const boardPointWrapOnClick = (rIdx, cIdx) => {
    // console.log(
    //   'boardPointWrapClick:',
    //   boardPointWrapRef.current[rIdx + '_' + cIdx].current
    // );
    boardPointWrapRef.current[rIdx + '_' + cIdx].current.style.visibility =
      'hidden';
    setTimeout(() => {
      boardPointWrapRef.current[rIdx + '_' + cIdx].current.style.visibility =
        'visible';
    }, 50);
  };

  const switchTurn = (curTurn) => {
    return curTurn === 'L' ? 'R' : curTurn === 'R' ? 'L' : 'N';
  };

  const getTurn = () => {
    return turn === 'L' ? 1 : -1;
  };

  const checkSuicide = (pos, captures) => {
    return !!(captures || []).find((it) => it.x === pos[0] && it.y === pos[1]);
  };

  const captureEffectHandler = (captureAmount) => {
    if (captureAmount >= 7) {
      setIsDispFantasticEffect(true);
    } else if (captureAmount >= 5) {
      setIsDispExcellentEffect(true);
    } else if (captureAmount >= 3) {
      setIsDispTripleEffect(true);
    }
  };

  const boardOnClickHandler = (rIdx, cIdx) => {
    const curPosValue = board[rIdx][cIdx];
    let isSuicide = false;
    let isHaveCaptures = false;
    let playerGetPointEffect = '';
    if (curPosValue === null || curPosValue === 'LA' || curPosValue === 'RA') {
      let actionType = '';
      if (!isShowFinishBtn) {
        actionType = 'MOVE';
        setIsPassL(false);
        setIsPassR(false);
        soundMove.play(!props.config.isMutedSound && 0.3);

        setCurPos([+rIdx, +cIdx]);
        const captures = Game.current.play(+rIdx, +cIdx, getTurn(), false);
        isHaveCaptures = Array.isArray(captures) && captures.length > 0;
        //console.log('captures::', captures);
        //console.log('position::', Game.current.getPosition());
        //setTimeout(() => {
        // setCaptures([...captures]);
        // console.log('isHaveCaptures:', captures);
        if (isHaveCaptures) {
          isSuicide = checkSuicide([+rIdx, +cIdx], captures); // is code is alway return false if initial as not allow suicide
          // playerGetPointEffect = switchTurn(
          //   board[captures[0].x][captures[0].y]
          // );
          if (isSuicide) {
            board[rIdx][cIdx] = turn;
          }
          atariHandler(captures);
          setTimeout(() => {
            captureEffectHandler((captures || []).length);
          }, 50);

          //console.log('isSuicide::', isSuicide, [+rIdx, +cIdx]);
        } else if (!Array.isArray(captures)) {
          setPlayerGetPointEffect('');
          return;
        }

        //});
      } else if ((isMinStarL || isMinStarR) && !isFinished) {
        actionType = 'COUNT';
      } else {
        actionType = 'NONE';
        setActionType(actionType);
        return;
      }
      setTurn((turn) => {
        return switchTurn(turn);
      });
      const updatePos = isMinStarL
        ? 'LP'
        : isMinStarR
          ? 'RP'
          : !isShowFinishBtn
            ? isSuicide
              ? board[rIdx][cIdx]
              : turn
            : curPosValue;
      board[rIdx] = [...board[rIdx]]; // this line is changing reference point of row board for update.
      board[rIdx][cIdx] = updatePos;

      // if (isSuicide) {
      //   setActionType('ATARI');

      //   setDeathEffect((deathEffect) => {
      //     return { ...deathEffect, [rIdx + '_' + cIdx]: 'start' };
      //   });
      //   setPlayRAtariCnt(countAtariPoint('R'));
      //   setPlayLAtariCnt(countAtariPoint('L'));

      //   setPlayerGetPointEffect(playerGetPointEffect);
      // }

      const updateBoard = clearAtariPos([...board]);
      setBoard(() => {
        return updateBoard;
      });
      //console.log('updateBoard:', [updateBoard]);
      //console.log('prevBoardHistory:', [...boardHistory]);
      //console.log('setBoardHistory:', [
      //  [...updateBoard],
      //  ...boardHistory.slice(0, 1),
      //]);
      let updateBoardHistory = [];
      if (actionType === 'MOVE') {
        updateBoardHistory = [[...updateBoard], ...boardHistory.slice(0, 1)];
      } else if (actionType === 'COUNT') {
        updateBoardHistory = [[...updateBoard], ...boardHistory];
      }
      //console.log('updateBoardHistory:::', [...updateBoardHistory]);
      setBoardHistory(updateBoardHistory);
      setActionType(actionType);
      setCountStep((countStep) => countStep + 1);
    } else if (
      ((curPosValue === 'L' && isMinStarR) ||
        (curPosValue === 'R' && isMinStarL)) &&
      !isFinished
    ) {
      //console.log('boardOnClickHandler::');
      boardOnDblClickHandler(rIdx, cIdx);
      setTimeout(() => {
        boardOnClickHandler(rIdx, cIdx);
      }, 500);
    } else {
      setActionType('NONE');
    }
  };

  const clearAtariCnt = (board) => {
    board.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        if (cIt === 'RA' || cIt === 'LA') {
          board[rIdx][cIdx] = null;
        }
      });
    });
    return [...board];
  };

  const boardOnDblClickHandler = (rIdx, cIdx) => {
    // this respresent capture stone
    const curPosValue = board[rIdx][cIdx];
    if (curPosValue !== null) {
      let actionType;
      let updatePos = null;
      if (
        !isShowFinishBtn ||
        ((isMinStarL || isMinStarR) &&
          (curPosValue === 'L' || curPosValue === 'R'))
      ) {
        actionType = 'ATARI';
        let tmpDeathEffect = { ...deathEffect };
        if (curPosValue === 'R') {
          updatePos = 'LA';
          tmpDeathEffect = { ...deathEffect, [rIdx + '_' + cIdx]: 'start' };
          //setPlayerGetPointEffect('L');
        } else if (curPosValue === 'L') {
          updatePos = 'RA';
          tmpDeathEffect = { ...deathEffect, [rIdx + '_' + cIdx]: 'start' };
          //setPlayerGetPointEffect('R');
        } else {
          setActionType('NONE');
          return;
        }

        setTimeout(() => {
          //setActionType(actionType);

          setDeathEffect(tmpDeathEffect);
          setPlayRAtariCnt(countAtariPoint('R'));
          setPlayLAtariCnt(countAtariPoint('L'));
          setPlayerGetPointEffect(switchTurn(curPosValue));
        });
      } else if (
        (isMinStarL || isMinStarR) &&
        curPosValue !== 'L' &&
        curPosValue !== 'R' &&
        !isFinished
      ) {
        actionType = 'DEL_COUNT';
      } else {
        setActionType('NONE');
        return;
      }

      board[rIdx] = [...board[rIdx]]; // this line is changing reference point of row board for update.
      board[rIdx][cIdx] = updatePos;
      const updateBoard = [...board];
      setBoard(() => {
        return updateBoard;
      });
      // console.log('updateBoard:', [updateBoard]);
      // console.log('prevBoardHistory:', [...boardHistory]);
      // console.log('setBoardHistory:', [[...updateBoard], ...boardHistory]);
      const updateBoardHistory = [[...updateBoard], ...boardHistory];
      // if (updateBoardHistory.length === 3) {
      //   updateBoardHistory.push([...initBoard]);
      // }
      setBoardHistory(updateBoardHistory);
      setActionType(actionType);
      setCountStep((countStep) => countStep + 1);
    } else {
      setActionType('NONE');
    }
  };

  const atariHandler = (captures) => {
    //console.log('atariHandler:', captures);
    const captureList = captures || [];
    if (captureList.length === 0 || isShowFinishBtn) {
      return;
    }

    let actionType = '';
    let updateBoard = [];
    const playerGetPointEffect = switchTurn(
      board[captures[0].x][captures[0].y]
    );
    let tmpDeathEffect = { ...deathEffect };
    captureList.every((pos) => {
      const [rIdx, cIdx] = [pos.x, pos.y];
      //setActionPos([+rIdx, +cIdx]);
      const curPosValue = board[rIdx][cIdx];
      //console.log('curPosValue:', curPosValue);
      let updatePos = null;
      if (curPosValue !== null) {
        actionType = 'ATARI';
        if (curPosValue === 'R') {
          updatePos = 'LA';

          // setDeathEffect((deathEffect) => {
          //   return { ...deathEffect, [rIdx + '_' + cIdx]: 'start' };
          // });
          //setPlayerGetPointEffect('L');
        } else if (curPosValue === 'L') {
          updatePos = 'RA';
          // setDeathEffect((deathEffect) => {
          //   return { ...deathEffect, [rIdx + '_' + cIdx]: 'start' };
          // });
          //setPlayerGetPointEffect('R');
        } else {
          actionType = 'NONE';
        }
        tmpDeathEffect = { ...tmpDeathEffect, [rIdx + '_' + cIdx]: 'start' };
      } else {
        actionType = 'NONE';
      }
      if (actionType === 'ATARI' && updatePos) {
        board[rIdx] = [...board[rIdx]]; // this line is changing reference point of row board for update.
        board[rIdx][cIdx] = updatePos;
        // updateboard = [...board];
        // seboard(() => {
        //   return updateBoard;
        // });
        // setActionType(actionType);
        return true;
      } else {
        //setActionType(actionType);
        return false;
      }
    });
    if (actionType === 'ATARI') {
      //const updateBoard = [...board];
      //console.log('setsdddd:', updateBoard);
      // setBoard(() => {
      //   return updateBoard;
      // });
      // const updateBoardHistory = [[...updateBoard], ...boardHistory];
      // setBoardHistory(updateBoardHistory);

      setTimeout(() => {
        //console.log('Board::::', board);
        setActionType(actionType);
        setDeathEffect(tmpDeathEffect);

        setPlayRAtariCnt(countAtariPoint('R'));
        setPlayLAtariCnt(countAtariPoint('L'));
        setPlayerGetPointEffect(playerGetPointEffect);
      });

      // setTimeout(() => {
      //   // console.log('updateBoard::::', board);
      //   // console.log('deathEffect::', deathEffect);
      //   // console.log('atariPoint::', playLAtariPoint + playLAtariCnt);
      //   //console.log('BoardHistory::::', boardHistory);
      // }, 1500);
    }
  };

  const undoOnClickHandler = () => {
    if (boardHistory.length > 1) {
      Game.current.popPosition();
      //console.log('position::', Game.current.getPosition());
      //console.log('boardHistory:', [...boardHistory]);
      //console.log('actionType:', actionType);
      if (actionType === 'MOVE') {
        boardHistory.shift();
        setBoard([...boardHistory[0]]);
        //console.log('setBoardHistory:', [...boardHistory.slice(0, 1)]);
        setBoardHistory([...boardHistory.slice(0, 1)]);
        setTurn((turn) => switchTurn(turn));
      } else if (
        actionType === 'ATARI' ||
        actionType === 'DEL_COUNT' ||
        actionType === 'COUNT'
      ) {
        if (actionType === 'ATARI') {
          setPlayRAtariCnt(countAtariPoint('R'));
          setPlayLAtariCnt(countAtariPoint('L'));
        }
        if (!checkHaveAtari([...boardHistory[0]])) {
          setTurn((turn) => switchTurn(turn));
        }
        boardHistory.shift();

        setBoard([...boardHistory[0]]);
        setBoardHistory([...boardHistory]); // for every previous actions
      }
      setCountStep((countStep) => countStep - 1);
    }
  };

  const checkHaveAtari = (checkBoard) => {
    //console.log('checkBoard:', [...checkBoard]);
    let isHaveAtari = false;
    checkBoard.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        if (!isHaveAtari) {
          isHaveAtari = cIt === 'LA' || cIt === 'RA';
        }
      });
    });
    return isHaveAtari;
  };

  const countAtariPoint = (player) => {
    let atariCount = 0;
    board.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        if (
          (cIt === 'LA' && player === 'L') ||
          (cIt === 'RA' && player === 'R')
        ) {
          atariCount++;
        }
      });
    });
    //console.log('atariCount:::s:', atariCount, board);
    return atariCount;
  };

  const clearAtariPos = (board) => {
    const updateBoard = [...board].map((row) => [...row]);
    updateBoard.forEach((rIt, rIdx) => {
      rIt.forEach((cIt, cIdx) => {
        if (cIt === 'LA' || cIt === 'RA') {
          rIt[cIdx] = null;
        }
      });
    });
    //console.log('clearAtariPos::', [...updateBoard]);
    return updateBoard;
  };

  const passOnClickHandler = (fromPlayer) => {
    if (fromPlayer === turn && !isShowFinishBtn) {
      if (fromPlayer === 'L') {
        setIsPassL(true);
      }
      if (fromPlayer === 'R') {
        setIsPassR(true);
      }
      setBoardHistory([...boardHistory.splice(0, 1)]);
      setTurn((turn) => switchTurn(turn));
    }
  };

  const finishOnClickHandler = () => {
    if (!isFinished) {
      setIsFinished(true);
      //console.log('playRAtariPoint:', playRAtariPoint);
      //console.log('playLAtariPoint:', playLAtariPoint);
      //console.log('isSurrender:::', isSurrender);
      if (playRAtariPoint > 0 && !isSurrender.current) {
        setPlayerRFireShootEffect(true);
      }

      setTimeout(
        () => {
          console.log('setIsStartCalPointLPlayer');
          setIsStartCalPointLPlayer(true);
        },
        playRAtariPoint > 0 && !isSurrender.current ? 2000 : 0
      );

      setTurn(0);
    }
  };

  const minStarROnClickHandler = () => {
    if (!isFinished) {
      setIsMinStarR(true);
      setIsMinStarL(false);
    }
  };

  const minStarLOnClickHandler = () => {
    if (!isFinished) {
      setIsMinStarL(true);
      setIsMinStarR(false);
    }
  };

  const getDigit = (number, digitPos) => {
    let ndigitPos = digitPos;
    if (number < 0) {
      ++ndigitPos;
    }
    if (ndigitPos < 1 || ndigitPos > String(number).length) {
      return 0;
    }
    return +String(number)[ndigitPos - 1];
  };

  const deathEffectHandler = (rIdx, cIdx) => {
    //console.log('deathEffectHandler:', [rIdx, cIdx]);
    if (deathEffect[rIdx + '_' + cIdx] === 'start') {
      if (!isPlayedDeathSound.current) {
        soundDeath.play(!props.config.isMutedSound && 0.3);
        isPlayedDeathSound.current = true;
      }

      setDeathEffect((deathEffect) => {
        return { ...deathEffect, [rIdx + '_' + cIdx]: 'process' };
      });
      setTimeout(() => {
        setDeathEffect((deathEffect) => {
          return { ...deathEffect, [rIdx + '_' + cIdx]: 'end' };
        });
        isPlayedDeathSound.current = false;
      }, 1000);
    }
  };

  const getPointEffectHandler = () => {
    if (playerGetPointEffect === 'L' || playerGetPointEffect === 'R') {
      setTimeout(() => {
        setEndPointEffectPos(actionPos);
      }, 800);
    }
  };

  const playerLFireShootEffectHandler = () => {
    if (playerLFireShootEffect) {
      //soundFireShoot.play();
      setTimeout(() => {
        setPlayerLFireShootEffect(false);
      }, 2700);
    }
  };

  const playerRFireShootEffectHandler = () => {
    if (playerRFireShootEffect) {
      //soundFireShoot.play();
      setTimeout(() => {
        setPlayerRFireShootEffect(false);
      }, 2700);
    }
  };

  const backHandler = () => {
    //setDispScoreSummary(false);
    // setPlayLAtariPoint(playLAtariScore);
    // setPlayRAtariPoint(playRAtariScore);
    //window.location.reload();
    backOnClickHandler();
  };

  const checkWinner = () => {
    if (playLAreaPoint > playRAreaPoint) {
      return 'L';
    } else if (playLAreaPoint < playRAreaPoint) {
      return 'R';
    } else {
      return 'Equal';
    }
  };

  const getDigitLen = (number) => {
    return String(Math.abs(number)).length;
  };

  //console.log('board::', board);
  //console.log('actionPos:', actionPos);
  //console.log('actionPos[0]:', actionPos[0]);
  //console.log('actionPos[1]:', actionPos[1]);

  const adjustAudioMain = () => {
    document.getElementById('audio_gameplay_main').volume = 0.12;
    setTimeout(() => {
      if (!props.config.isMutedSound) {
        document.getElementById('audio_gameplay_main').play();
      }
    }, 1200);
  };
  const adjustAudioFireShootL = () => {
    document.getElementById('audio_fireshoot_l').volume = 0.6;
    document.getElementById('audio_fireshoot_l').playbackRate = 1.2;
  };
  const adjustAudioFireShootR = () => {
    document.getElementById('audio_fireshoot_r').volume = 0.6;
    document.getElementById('audio_fireshoot_r').playbackRate = 1.2;
  };

  // const adjustMouse = React.useCallback((node) => {
  //   if (node !== null) {
  //     node.style.cursor = 'pointer !important';
  //   }
  // }, []);

  const playagainOnClickHandler = () => {
    Game.current = initialWGO();
    setBoard([...initBoard]);
    setBoardHistory([[...board]]);
    setTurn('L');
    setIsFinished(false);
    setActionType('MOVE');
    setIsPassL(false);
    setIsPassR(false);
    setIsMinStarL(false);
    setIsMinStarR(false);
    setIsShowFinishBtn(0);
    setPlayLAreaPoint(0);
    setPlayLAtariPoint(0);
    setPlayRAreaPoint(0);
    setPlayRAtariPoint(0);
    setPlayRAtariCnt(0);
    setPlayLAtariCnt(0);
    setPlayLAtariScore(0);
    setPlayRAtariScore(0);
    setPlayLAreaPointSurrenderCase(0);
    setPlayRAreaPointSurrenderCase(0);
    // setPlayLAtariPointSurrenderCase(0);
    // setPlayRAtariPointSurrenderCase(0);
    setPlayLAtariScoreSurrenderCase(0);
    setCountStep(0);
    setIsFinishedCalPoint(false);
    setTrickInterval(false);
    setSubscription(null);
    setIsStartCalPointLPlayer(false);
    setIsStartCalPointRPlayer(false);
    setActionPos([null, null]);
    setDeathEffect({});
    setPlayerGetPointEffect('N');
    setEndPointEffectPos(null);
    setPlayerLFireShootEffect(false);
    setPlayerRFireShootEffect(false);
    setDispScoreSummary(false);
    setCurPos([null, null]);
  };

  const backOnClickHandler = () => {
    //history.goBack();
    props.backHandler && props.backHandler();
  };

  const startEffectHandler = () => {
    setIsDispBoard(true);
    soundStart.play(!props.config.isMutedSound && 0.2);
    setTimeout(() => {
      setIsDispStartEffect(false);
    }, 900);
  };

  const tripleEffectHandler = () => {
    soundTriple.play(!props.config.isMutedSound && 0.4);
    setTimeout(() => {
      setIsDispTripleEffect(false);
    }, 850);
  };

  const fantasticEffectHandler = () => {
    soundFantastic.play(!props.config.isMutedSound && 0.4);
    setTimeout(() => {
      setIsDispFantasticEffect(false);
    }, 850);
  };

  const excellentEffectHandler = () => {
    soundExcellent.play(!props.config.isMutedSound && 0.4);
    setTimeout(() => {
      setIsDispExcellentEffect(false);
    }, 850);
  };

  const Board = (
    <>
      {!dispScoreSummary && !props.config.isMutedSound ? (
        <audio
          //ref={adjustAudioMain}
          onLoadedData={adjustAudioMain}
          id="audio_gameplay_main"
          key={'audio_gameplay_main' + dispScoreSummary}
          hidden
          src={soundMain}
          //autoPlay
          controls
          loop
        ></audio>
      ) : null}

      <div
        //ref={adjustMouse}
        id="board"
        style={{
          backgroundImage: `url(${images['frame.png']})`,
        }}
        className={classes.container}
      >
        {isDispStartEffect ? (
          <img
            key={'isDispStartEffect' + retrigNumber}
            style={{
              position: 'absolute',
              zIndex: 9999,
            }}
            onLoad={startEffectHandler}
            src={images['effect_start.png'] + '?a=' + retrigNumber}
          ></img>
        ) : null}
        <Grid container justify="center" alignItems="stretch">
          <Grid item xs container direction="column" justify="space-between">
            <div className={classes.leftTool}>
              <img
                passl={
                  isPassL ? 'clicked' : !isShowFinishBtn ? 'enable' : 'disable'
                }
                src={images['pass.png']}
                onClick={() => passOnClickHandler('L')}
              />
              {boardHistory.length > 1 && !isFinished ? (
                <img
                  undo={'enable'}
                  onClick={undoOnClickHandler}
                  src={images['undo.png']}
                />
              ) : null}
              <img
                back=""
                onClick={backOnClickHandler}
                src={images['backbtn.png']}
                style={{ height: '106px' }}
              />
            </div>
            <div className={classes.leftPlayer}>
              {isShowFinishBtn && !isSurrender.current ? (
                <div summarypoint="L">
                  {playLAreaPoint < 0 ? (
                    <img minus="" src={images[`minus.png`]}></img>
                  ) : null}
                  <img
                    summarypoindigit1=""
                    src={images[`${getDigit(playLAreaPoint, 1)}B.png`]}
                  ></img>
                  {getDigitLen(playLAreaPoint) > 1 ? (
                    <img
                      summarypoindigit2=""
                      src={images[`${getDigit(playLAreaPoint, 2)}B.png`]}
                    ></img>
                  ) : null}
                </div>
              ) : null}
              {playerLFireShootEffect ? (
                <>
                  {!props.config.isMutedSound ? <audio
                    ref={adjustAudioFireShootL}
                    id="audio_fireshoot_l"
                    key="audio_fireshoot_l"
                    hidden
                    src={soundFireShoot}
                    autoPlay
                    controls
                    loop
                  ></audio> : null}

                  <img
                    fireshootl=""
                    ref={playerLFireShootEffectHandler}
                    src={images['firebird.png'] + '?a=L'}
                  ></img>
                </>
              ) : null}
              {playLAtariPoint + playLAtariCnt > 0 ||
                (isShowFinishBtn && !isSurrender.current) ? (
                  <>
                    <div atarinum="L">
                      <img
                        atarinumdigit1=""
                        src={
                          images[
                          `${getDigit(playLAtariPoint + playLAtariCnt, 1)}.png`
                          ]
                        }
                      ></img>
                      {getDigitLen(playLAtariPoint + playLAtariCnt) > 1 ? (
                        <img
                          atarinumdigit2=""
                          src={
                            images[
                            `${getDigit(
                              playLAtariPoint + playLAtariCnt,
                              2
                            )}.png`
                            ]
                          }
                        ></img>
                      ) : null}
                    </div>
                    {
                      //!isShowFinishBtn &&
                      actionType === 'ATARI' &&
                        playerGetPointEffect === 'L' &&
                        endPointEffectPos !== actionPos ? (
                          <img
                            ref={getPointEffectHandler}
                            getpointeffectl=""
                            src={
                              images[`getPointEffect.png`] +
                              '?a=' +
                              actionPos[0] +
                              '' +
                              actionPos[1]
                            }
                          ></img>
                        ) : null
                    }
                  </>
                ) : null}

              <img playerstarimg="" src={images['starbigy.png']} />
              <img
                playerimg=""
                className={[
                  turn === 'L' && !isShowFinishBtn ? classes.turn : '',
                ].join(' ')}
                src={images[playerInfo.selectedBigP1]}
              ></img>
              <img playerframeimg="" src={images['playb.png']} />
              {isShowFinishBtn ? (
                <img
                  onClick={minStarLOnClickHandler}
                  playerminstarimg={
                    isFinished ? 'disable' : isMinStarL ? 'clicked' : 'enable'
                  }
                  src={images['buttony.png']}
                ></img>
              ) : null}
            </div>
          </Grid>
          <Grid container justify="center" alignItems="stretch" item xs>
            <div
              style={{
                backgroundImage: `url(${images[playerInfo.selectedBgBoard]})`,
              }}
              className={classes.boardContainer}
            >
              <div className={classes.fireBoardTop}>
                <img src={images['fire_board.png']} />
              </div>
              <Grid
                container
                direction="column"
                className={classes.board}
                alignItems="center"
              >
                {board.map((row, rIdx) => (
                  <Grid
                    key={rIdx}
                    item
                    xs
                    container
                    style={{ width: '100%', height: 'fit-content' }}
                    justify="center"
                    alignItems="center"
                  >
                    {row.map((col, cIdx) => (
                      <Grid
                        key={rIdx + '' + cIdx}
                        item
                        // className={[
                        //   isTouchDevice() ? '' : classes.boardPointWrapper,
                        // ].join()}
                        ref={(ref) => boardPointWrapRefCall(ref, rIdx, cIdx)}
                      //onClick={() => boardPointWrapOnClick(rIdx, cIdx)}
                      //onLoad={initialBoardPointWrap}
                      >
                        <div
                          className={classes.boardPoint}
                          point={[rIdx, cIdx]}
                          ref={pointRef.current[rIdx + '_' + cIdx]}
                        //onClick={() => boardPointOnClick(rIdx, cIdx)}
                        >
                          {col === null || col === 'LA' || col === 'RA' ? (
                            turn === 'L' && !isShowFinishBtn ? (
                              <div
                                style={{
                                  width: '90px',
                                  height: '90px',
                                }}
                                className={classes.boardHover}
                              >
                                <img
                                  point={[rIdx, cIdx]}
                                  style={{
                                    width: '90px',
                                    height: '90px',
                                  }}
                                  src={images[playerInfo.selectedP1]}
                                />
                              </div>
                            ) : turn === 'R' && !isShowFinishBtn ? (
                              <div
                                style={{
                                  width: '90px',
                                  height: '90px',
                                }}
                                className={classes.boardHover}
                              >
                                <img
                                  point={[rIdx, cIdx]}
                                  style={{
                                    width: '90px',
                                    height: '90px',
                                  }}
                                  src={images[playerInfo.selectedP2]}
                                />
                              </div>
                            ) : isMinStarL && !isFinished ? (
                              <div
                                style={{
                                  width: '70px',
                                  height: '70px',
                                }}
                                className={classes.boardHover}
                              >
                                <img
                                  point={[rIdx, cIdx]}
                                  src={images['pointb.png']}
                                />
                              </div>
                            ) : isMinStarR && !isFinished ? (
                              <div
                                style={{
                                  width: '70px',
                                  height: '70px',
                                }}
                                className={classes.boardHover}
                              >
                                <img
                                  point={[rIdx, cIdx]}
                                  src={images['pointw.png']}
                                />
                              </div>
                            ) : null
                          ) : null}

                          {col === 'L' ? (
                            curPos[0] === rIdx && curPos[1] === cIdx ? (
                              <img
                                point={[rIdx, cIdx]}
                                style={{
                                  width: '120px !important',
                                  height: '120px',
                                }}
                                src={images[playerInfo.selectedCurP1]}
                              />
                            ) : (
                                <img
                                  point={[rIdx, cIdx]}
                                  style={{
                                    width: '90px',
                                    height: '90px',
                                  }}
                                  src={images[playerInfo.selectedP1]}
                                />
                              )
                          ) : null}
                          {col === 'R' ? (
                            curPos[0] === rIdx && curPos[1] === cIdx ? (
                              <img
                                point={[rIdx, cIdx]}
                                style={{
                                  width: '120px !important',
                                  height: '120px',
                                }}
                                src={images[playerInfo.selectedCurP2]}
                              />
                            ) : (
                                <img
                                  point={[rIdx, cIdx]}
                                  style={{
                                    width: '90px',
                                    height: '90px',
                                  }}
                                  src={images[playerInfo.selectedP2]}
                                />
                              )
                          ) : col === 'LP' ? (
                            <img
                              point={[rIdx, cIdx]}
                              style={{
                                width: '70px',
                                height: '70px',
                              }}
                              src={images['pointb.png']}
                            />
                          ) : col === 'RP' ? (
                            <img
                              point={[rIdx, cIdx]}
                              style={{
                                width: '70px',
                                height: '70px',
                              }}
                              src={images['pointw.png']}
                            />
                          ) : null}
                          {actionType === 'ATARI' &&
                            (deathEffect[rIdx + '_' + cIdx] === 'start' ||
                              deathEffect[rIdx + '_' + cIdx] === 'process') ? (
                              //actionPos[0] === rIdx &&
                              //actionPos[1] === cIdx
                              <img
                                //key={[rIdx, cIdx]}
                                ref={() => deathEffectHandler(rIdx, cIdx)}
                                style={{
                                  position: 'absolute',
                                  top: '-900px',
                                  left: '-50px',
                                }}
                                point={[rIdx, cIdx]}
                                src={
                                  images['Death.png'] + '?a=' + rIdx + '' + cIdx
                                }
                              />
                            ) : null}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <div className={classes.fireBoardBottom}>
                <img src={images['fire_board.png']} />
              </div>
            </div>
          </Grid>
          <Grid item xs container direction="column" justify="space-between">
            <div className={classes.rightTool}>
              <img
                playagain=""
                src={images['playagain.png']}
                onClick={playagainOnClickHandler}
              />
              {isShowFinishBtn ? (
                <img
                  finish={!isFinished ? 'enable' : 'disable'}
                  src={images['finish.png']}
                  onClick={finishOnClickHandler}
                />
              ) : null}
              <img
                passr={
                  isPassR ? 'clicked' : !isShowFinishBtn ? 'enable' : 'disable'
                }
                src={images['pass2.png']}
                onClick={() => passOnClickHandler('R')}
              />
            </div>
            <div className={classes.rightPlayer}>
              {isShowFinishBtn && !isSurrender.current ? (
                <div summarypoint="R">
                  {playRAreaPoint < 0 ? (
                    <img minus="" src={images[`minus.png`]}></img>
                  ) : null}
                  <img
                    summarypoindigit1=""
                    src={images[`${getDigit(playRAreaPoint, 1)}B.png`]}
                  ></img>
                  {getDigitLen(playRAreaPoint) > 1 ? (
                    <img
                      summarypoindigit2=""
                      src={images[`${getDigit(playRAreaPoint, 2)}B.png`]}
                    ></img>
                  ) : null}
                </div>
              ) : null}
              {playerRFireShootEffect ? (
                <>
                  {!props.config.isMutedSound ? <audio
                    ref={adjustAudioFireShootR}
                    id="audio_fireshoot_r"
                    key="audio_fireshoot_r"
                    hidden
                    src={soundFireShoot}
                    autoPlay
                    controls
                    loop
                  ></audio> : null}

                  <img
                    fireshootr=""
                    ref={playerRFireShootEffectHandler}
                    src={images['firecro.png'] + '?a=R'}
                  ></img>
                </>
              ) : null}
              {playRAtariPoint + playRAtariCnt > 0 ||
                (isShowFinishBtn && !isSurrender.current) ? (
                  <>
                    <div atarinum="R">
                      <img
                        atarinumdigit1=""
                        src={
                          images[
                          `${getDigit(playRAtariPoint + playRAtariCnt, 1)}.png`
                          ]
                        }
                      ></img>
                      {getDigitLen(playRAtariPoint + playRAtariCnt) > 1 ? (
                        <img
                          atarinumdigit2=""
                          src={
                            images[
                            `${getDigit(
                              playRAtariPoint + playRAtariCnt,
                              2
                            )}.png`
                            ]
                          }
                        ></img>
                      ) : null}
                    </div>
                    {
                      //!isShowFinishBtn &&
                      actionType === 'ATARI' &&
                        playerGetPointEffect === 'R' &&
                        endPointEffectPos !== actionPos ? (
                          <img
                            ref={getPointEffectHandler}
                            getpointeffectr=""
                            src={
                              images[`getPointEffect.png`] +
                              '?a=' +
                              actionPos[0] +
                              '' +
                              actionPos[1]
                            }
                          ></img>
                        ) : null
                    }
                  </>
                ) : null}

              <img playerstarimg="" src={images['starbigr.png']} />
              <img
                playerimg=""
                className={[
                  turn === 'R' && !isShowFinishBtn ? classes.turn : '',
                ].join(' ')}
                src={images[playerInfo.selectedBigP2]}
              />
              <img playerframeimg="" src={images['playw.png']} />
              {isShowFinishBtn ? (
                <img
                  onClick={minStarROnClickHandler}
                  playerminstarimg={
                    isFinished ? 'disable' : isMinStarR ? 'clicked' : 'enable'
                  }
                  src={images['buttonr.png']}
                ></img>
              ) : null}
              <img
                surrender=""
                hidden={isDispStartEffect}
                onClick={() => {
                  if (!isPassL || !isPassR) {
                    // passOnClickHandler(turn);
                    // passOnClickHandler(switchTurn(turn));
                    // setTimeout(() => {
                    //   setIsPassL(true);
                    //   setIsPassR(true);
                    // }, 10);
                    // setTimeout(() => {
                    //   setIsSurrender(true);
                    //   finishOnClickHandler();
                    // }, 50);

                    isSurrender.current = true;
                    passOnClickHandler(turn);
                    passOnClickHandler(switchTurn(turn));
                    setTimeout(() => {
                      setIsPassL(true);
                      setIsPassR(true);
                    }, 10);

                    setTimeout(() => {
                      //setIsSurrender(true);

                      //setIsFinished(true);
                      // setTimeout(() => {
                      //   setIsStartCalPointLPlayer(true);
                      //   setIsStartCalPointRPlayer(true);
                      // }, 50);
                      // setTimeout(() => {
                      //   finishOnClickHandler();
                      // }, 10);
                      finishOnClickHandler();
                    }, 20);
                  }
                }}
                className={!isPassL || !isPassR ? classes.imgHover : ''}
                style={{ position: 'absolute', right: '65px', bottom: 0 }}
                src={images['blose.png']}
              />
            </div>
          </Grid>
        </Grid>

        {isDispTripleEffect ? (
          <img
            style={{
              position: 'absolute',
              zIndex: 9999,
            }}
            onLoad={tripleEffectHandler}
            src={images['effect_triple.png'] + '?a=R'}
          ></img>
        ) : null}
        {isDispFantasticEffect ? (
          <img
            style={{
              position: 'absolute',
              zIndex: 9999,
            }}
            onLoad={fantasticEffectHandler}
            src={images['effect_fantastic.png'] + '?a=R'}
          ></img>
        ) : null}
        {isDispExcellentEffect ? (
          <img
            style={{
              position: 'absolute',
              zIndex: 9999,
            }}
            onLoad={excellentEffectHandler}
            src={images['effect_excellent.png'] + '?a=R'}
          ></img>
        ) : null}
      </div>
      {/* <footer id="footer" className={classes.footer}></footer> */}
    </>
  );

  //console.log('playerInfo::', playerInfo.selectedBg);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          backgroundImage: `url(${images[playerInfo.selectedBg]})`,
          visibility: isDispBoard ? 'visible' : 'hidden',
        }}
        className={classes.root}
      >
        {Board}
        {dispScoreSummary ? (
          <Score
            backHandler={backHandler}
            score={
              isSurrender.current
                ? null
                : Math.abs(playLAreaPoint - playRAreaPoint)
            }
            capture={
              isSurrender.current
                ? playLAtariScoreSurrenderCase
                : playLAtariScore
            }
            status={
              isSurrender.current
                ? 'win'
                : checkWinner() === 'L'
                  ? 'win'
                  : checkWinner() === 'R'
                    ? 'lose'
                    : 'equal'
            }
            playerInfo={playerInfo}
          />
        ) : null}
      </div>
      {!isDispBoard ? (
        <img
          className={classes.loading}
          //src={imgLoading}
          src={images['loadstart.png']}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

export default connect(mapStateToProps, null)(GamePlay);
