// import React, { useState, Fragment } from 'react';
import React, { useState } from 'react';
// import Img from 'react-image';
// import React, { useState, useEffect, useRef } from 'react';
// import { withRouter } from 'react-router-dom';
// import Camera from 'react-html5-camera-photo';
import { connect } from 'react-redux';
import 'react-html5-camera-photo/build/css/index.css';
import ReactCrop from 'react-image-crop';
// import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useImagesContext } from '../../contexts/ImagesContext';
import {
  Popover,
  Typography,
  Divider,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { dataURItoBlob, getCroppedImg } from '../../utils/image';

import TakeCamera from '../camera/TakeCamera';
import './register.css';

import {
  addStudent as addStudentService,
  getPreLevel as getPreLevelService,
  getCode as getCodeService,
} from '../../services/student';
import { joinClasses } from '../../utils/utility';
import { handleSuccess, handleError } from '../../services/handleResponse';
// import branch from 'reducers/branch';

// import imgBackground from '../../images/background-register.jpg';
// import imgCamera from '../../images/register-camera.png';
// import imgPicture from '../../images/register-picture.png';
// import imgOk from '../../images/ok.png';
// import imgReset from '../../images/register-reset.png';
// import imgBack from '../../images/back.png';

const useStyles = makeStyles((theme) => ({
  selectPreLevelOption: {
    '& [class~=MuiPaper-root]': {
      border: '4px solid #40a9ff',
      borderRadius: '8px',
      maxHeight: 350,
      '&::-webkit-scrollbar': {
        width: '14px',
      },
      '&::-webkit-scrollbar-thumb': {
        '-webkit-box-shadow': `inset 10px 0px 0px #84bfefad`,
        boxShadow: `inset 10px 0px 0px #84bfefad`,
        border: 'solid 3px transparent',

        // borderBottom: 'none',
        borderRadius: '25px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': `inset 10px 0px 0px rgba(0, 0, 0, 0.05)`,
      },
    },
    '& [container]': {
      display: 'block',
      padding: 16,
      minWidth: 160,
      '& p:hover': {
        backgroundColor: '#096dd940',
      },
    },

    '& p': {
      fontSize: '2rem',
      textAlign: 'center',
    },
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: '25px solid transparent',
    borderRight: '25px solid transparent',
    borderTop: '25px solid #40a9ff',
    position: 'absolute',
    left: 910,
    top: 470,
    borderRadius: 8,
    filter: 'drop-shadow(0px 2px 2px #000)',
    zIndex: 7,
    '&:hover': {
      borderTop: '25px solid #5397cf',
    },
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 170px)',
    left: 'calc(50% + 40px)',
  },
  studentCode: {
    position: 'absolute',
    top: 230,
    left: 400,
    zIndex: 1000,
    width: '150px',
    height: '45px',
    fontSize: '1.8rem',
    borderRadius: '23px',
    color: '#fff',
    backgroundColor: '#5ac9e4',
  },
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));

const Register = ({ history, branch }) => {
  const classes = useStyles();
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();
  const [image, setImage] = useState(null);
  const [imgInput, setImgInput] = useState(null);
  const [takePhotoFlag, setTakePhotoFlag] = useState(false);
  const [resizePhotoFlag, setResizePhotoFlag] = useState(false);
  // let fileInput = null;
  const [formData, setFormDate] = useState({
    name: '',
    level: '15',
    image: null,
  });
  // console.debug(formData);
  // const { goBack } = history;

  const cropState = {
    src: null,
    crop: {
      aspect: 1 / 1,
      x: 10,
      y: 10,
      width: 200,
      height: 200,
    },
    // img: null
  };

  const [cropData, setCrop] = useState(cropState);

  const [preLevelDdlList, setPreLevelDdlList] = React.useState([]);
  const [selectedPreLevel, setSelectedPreLevel] = React.useState(undefined);
  const [studentCode, setStudentCode] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [getPreLevelErrorFlag, setGetPreLevelErrorFlag] = useState(false);
  const [getPreLevelLoadingFlag, setGetPreLevelLoadingFlag] = useState(false);
  // const [getCodeErrorFlag, setGetCodeErrorFlag] = useState(false);
  // const [getCodeLoadingFlag, setGetCodeLoadingFlag] = useState(false);

  const refInput = React.useRef(null);

  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
    setAnchorEl(refInput.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const inputEl = useRef(null);

  React.useEffect(() => {
    getPreLevel();
    if (branch.selectedBranch?.id) {
      getCode();
    } else {
      history.push('/branch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    history.push('/home');
  };

  const handleNameChange = (e) => {
    setFormDate({ ...formData, name: e.target.value.toUpperCase() });
  };

  // const handleChange = (e) => {
  //   setFormDate({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleImageChange = (e) => {
  //   setFormDate({ ...formData, image: e.target.files[0] });
  // };

  const clearData = () => {
    setFormDate({
      name: '',
      level: '15',
      image: null,
    });
    setImage(null);
  };

  const addStudent = () => {
    console.debug(formData);
    if (formData.name === '') return;
    // e.preventDefault();
    const { name, level, image } = formData;
    addStudentService(
      studentCode,
      name,
      level,
      image,
      branch?.selectedBranch?.id
    )
      .then((res) => {
        console.debug(res);
        // console.debug(fileInput);
        handleSuccess();
        setImage(null);
        setFormDate({ name: '', level: '15', image: null });
        getCode();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleTakePhoto = (dataUri) => {
    // setFormDate({ ...formData, image: dataURItoBlob(dataUri) });
    setImage(dataUri);
    setTakePhotoFlag(false);
    setCrop({ ...cropData, src: dataUri });
    setResizePhotoFlag(true);
  };

  const onImageLoaded = (image) => {
    console.debug('onImageLoaded', image);
    setImgInput(image);
    // const crop = { ...cropData.crop, width: image.width, height: image.height };
    // console.debug(crop);
    // setCrop({
    //   ...cropData,
    //   crop: crop
    // });
    // return false;
  };

  const onCropComplete = (crop) => {
    console.debug('onCropComplete', crop);
  };

  const onComplete = (crop) => {
    console.debug('onComplete', crop);
  };

  const onCropChange = (crop) => {
    setCrop({ ...cropData, crop: crop });
  };

  const cropOk = async () => {
    console.debug(cropData);
    const croppedImg = await getCroppedImg(imgInput, cropData.crop, 'file.jpg');
    setImage(croppedImg);
    setFormDate({ ...formData, image: dataURItoBlob(croppedImg) });
    setResizePhotoFlag(false);
  };

  const handleSelectPreLevel = (item) => {
    setSelectedPreLevel(item);
    setFormDate({ ...formData, level: item.value });
    handleClose();
  };

  const getPreLevel = () => {
    setGetPreLevelLoadingFlag(true);
    // setGetPreLevelErrorFlag(false);
    getPreLevelService(
      (resp) => {
        setPreLevelDdlList(
          (resp || []).map((it) => {
            return { ...it };
          })
        );
        setGetPreLevelLoadingFlag(false);
        const foundDefaultVal = resp.find((it) => +it.value === 15);
        setSelectedPreLevel(foundDefaultVal);
      },
      (error) => {
        setPreLevelDdlList([]);
        // setGetPreLevelErrorFlag(true);
        setGetPreLevelLoadingFlag(false);
        handleError(error);
      }
    );
  };

  const getCode = () => {
    // setGetCodeLoadingFlag(true);
    // setGetCodeErrorFlag(false);
    getCodeService(
      branch.selectedBranch?.id,
      (resp) => {
        setStudentCode(resp);
        // setGetCodeLoadingFlag(false);
      },
      (error) => {
        // setGetCodeErrorFlag(true);
        // setGetCodeLoadingFlag(false);
        handleError(error);
      }
    );
  };

  return (
    // <div style={{ display: 'none' }}>
    <div style={{ position: 'relative' }}>
      {studentCode ? (
        <Chip eval label={studentCode} className={classes.studentCode} />
      ) : null}
      <div
        id='wb_Image1'
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          width: 1920,
          height: 1080,
          zIndex: 0,
        }}
      >
        <img
          src={images['background-register.jpg']}
          id='Image1'
          alt='true'
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div
        id='wb_Image2'
        style={{
          position: 'absolute',
          left: 1240,
          top: 280,
          width: 241,
          height: 245,
          zIndex: 1,
        }}
        className={c('point')}
      >
        <img
          src={images['register-camera.png']}
          id='Image2'
          alt=''
          className='my-btn'
          onClick={() => {
            setTakePhotoFlag(true);
          }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <input
        type='text'
        id='name'
        style={{
          position: 'absolute',
          left: 670,
          top: 280,
          width: 440,
          height: 100,
          zIndex: 2,
        }}
        name='name'
        value={formData.name}
        onChange={handleNameChange}
        autoComplete='off'
        spellCheck='false'
      />
      <div
        id='wb_Image3'
        style={{
          position: 'absolute',
          left: 796,
          top: 560,
          width: 329,
          height: 331,
          zIndex: 3,
        }}
      >
        {image == null ? (
          <img
            src={images['register-picture.png']}
            id='Image3'
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : (
          <img
            src={image}
            id='Image_Student'
            alt=''
            onClick={() => {
              setResizePhotoFlag(true);
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        )}
      </div>
      <div
        id='wb_Image4'
        style={{
          position: 'absolute',
          left: 750,
          top: 900,
          width: 200,
          height: 97,
          zIndex: 4,
        }}
        className={c('point')}
      >
        <img
          className='my-btn'
          src={images['ok.png']}
          id='Image4'
          alt=''
          onClick={addStudent}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div
        id='wb_Image5'
        style={{
          position: 'absolute',
          left: 970,
          top: 900,
          width: 200,
          height: 97,
          zIndex: 5,
        }}
        className={c('point')}
      >
        <img
          className='my-btn'
          src={images['register-reset.png']}
          id='Image5'
          alt=''
          onClick={clearData}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div
        id='wb_Image7'
        style={{
          position: 'absolute',
          left: 60,
          top: 890,
          width: 186,
          height: 130,
          zIndex: 6,
        }}
        className={c('point')}
      >
        {/* <a href="./home.html"> */}
        <img
          className='my-btn'
          src={images['back.png']}
          id='Image7'
          onClick={goBack}
          alt=''
          onContextMenu={(e) => e.preventDefault()}
        />
        {/* </a> */}
      </div>
      <input
        type='text'
        ref={refInput}
        id='register_level'
        style={{
          position: 'absolute',
          left: 710,
          top: 430,
          width: 200,
          height: 100,
          zIndex: 7,
        }}
        name='level'
        value={selectedPreLevel?.name || ''}
        //onChange={handleChange}
        autoComplete='off'
        //spellCheck="false"
        onClick={handleClick}
        readOnly
      />
      <div onClick={handleClick} className={c('point arrowDown')}></div>
      {getPreLevelLoadingFlag ? (
        <CircularProgress
          style={{
            position: 'absolute',
            left: 780,
            top: 447,
            width: 65,
            height: 'auto',
            zIndex: 7,
          }}
        />
      ) : null}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.selectPreLevelOption}
        ref={(ref) => {
          if (ref) {
            ref.parentNode.style.cursor = 'url(/images/mouse.png),auto';
          }
        }}
      >
        <div container=''>
          {preLevelDdlList.map((item, idx) => {
            return (
              <div key={item.value} onClick={() => handleSelectPreLevel(item)}>
                <Typography>{item.name}</Typography>
                {idx < preLevelDdlList.length - 1 ? <Divider /> : null}
              </div>
            );
          })}
        </div>
      </Popover>

      {takePhotoFlag && (
        <div
          className='CameraFrame'
          style={{
            zIndex: 8,
          }}
        >
          <TakeCamera takePhoto={handleTakePhoto} />
        </div>
      )}

      {resizePhotoFlag && (
        <div
          onDoubleClick={cropOk}
          className='CameraFrame'
          style={{
            zIndex: 8,
          }}
        >
          {cropData.src && (
            <ReactCrop
              src={cropData.src}
              crop={cropData.crop}
              onImageLoaded={onImageLoaded}
              onComplete={onComplete}
              onChange={onCropChange}
              onCropComplete={onCropComplete}
            />
          )}
        </div>
      )}
    </div>
  );
};

// export default withRouter(Register);

const mapStateToProps = ({ branch }) => ({
  branch,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
