import { SET_MUTE_SOUND } from '../actions/types';

const initialState = {
  isMutedSound: window.localStorage.getItem('isMutedSound'),
};
//window.localStorage.setItem('isMutedSound', false);

//console.log('ddsd:', window.localStorage.getItem('isMutedSound'))
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MUTE_SOUND:
      return { ...state, isMutedSound: payload.value };
    default:
      return state;
  }
}
