import React, { useState, useEffect } from 'react';
import Sound from 'react-sound';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useImagesContext } from '../../contexts/ImagesContext';

import { getState as getStateService } from '../../services/student';
import { saveStateData } from '../../actions/state';
import { joinClasses } from '../../utils/utility';
import { makeStyles } from '@material-ui/core/styles';
// import UIfx from 'uifx';

import StateItem from './StateItem';
import soundStateBackground from '../../sounds/06.mp3';
import './State.css';
import soundToLessonFile from '../../sounds/05.mp3';

// import imgState from '../../images/state-state.png';
// import imgState16 from '../../images/state-state6.png';
// import imgLock from '../../images/state-lock.png';
// import imgBack from '../../images/back.png';
// import imgEffect03 from '../../images/effect-03.png';

// import soundLessonFile from '../../sounds/05.mp3';
// const soundLesson = new UIfx(soundLessonFile);
const useStyles = makeStyles((theme) => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
}));
const State = ({ history, match, stateData, saveStateData, config, student }) => {
  // const studentId = match.params.id;
  // const level = match.params.level;
  const c = joinClasses(useStyles());
  const { images } = useImagesContext();

  const imgBackgroundlevel15 = images['state-back-15.jpg'];
  const imgBackgroundlevel14 = images['state-back-14.jpg'];
  const imgBackgroundlevel13 = images['state-back-13.jpg'];
  const imgBackgroundlevel12 = images['state-back-12.jpg'];
  const imgBackgroundlevel11 = images['state-back-11.jpg'];

  const imgPlenetlevel15 = images['state-planet-15.png'];
  const imgPlenetlevel14 = images['state-planet-14.png'];
  const imgPlenetlevel13 = images['state-planet-13.png'];
  const imgPlenetlevel12 = images['state-planet-12.png'];
  const imgPlenetlevel11 = images['state-planet-11.png'];

  const imgBackgroundLevel = {
    15: imgBackgroundlevel15,
    14: imgBackgroundlevel14,
    13: imgBackgroundlevel13,
    12: imgBackgroundlevel12,
    11: imgBackgroundlevel11,
  };

  const imgPlenetLevel = {
    15: imgPlenetlevel15,
    14: imgPlenetlevel14,
    13: imgPlenetlevel13,
    12: imgPlenetlevel12,
    11: imgPlenetlevel11,
  };

  const { level } = match.params;
  const id = student.id;
  // let query = new URLSearchParams(useLocation().search);

  // const unlockState = query.get('unlock');

  // console.debug('stateData', stateData);

  // const [stateData, setStateData] = useState([]);

  const [soundToLesson, setSoundToLesson] = useState(null);

  useEffect(() => {
    const handleError = (error) => {
      if (error.response) {
        console.error(error.response.data);
        if (error.response.status == 404) {
          history.push('/home');
        }
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error('Error', error.message);
      }
    };

    console.debug('useEffect', id);
    getStateService(id, level)
      .then((res) => {
        console.debug('getStateService', res.data);
        console.debug('stateData', stateData.state);
        const newStateData = res.data;
        if (stateData.state && stateData.level == level) {
          console.debug('compare state data');
          const newTemp = newStateData.map((v) => ({
            ...v,
            unlocked:
              v.access &&
              stateData.state.findIndex(
                (s) => s.number == v.number && !s.access
              ) !== -1,
          }));
          console.debug('newTemp:', newTemp);
          saveStateData({ level, state: newTemp });
        } else {
          saveStateData({ level, state: newStateData });
        }
      })
      .catch((error) => {
        handleError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    // history.push(`/planet`);
    history.goBack();
  };

  const toLesson = (number) => {
    let stateId = null;
    for (let i = 0; i < stateData.state.length; i++) {
      if (stateData.state[i].number == number) {
        stateId = stateData.state[i].stateId;
        break;
      }
    }

    if (stateId !== null) {
      // soundLesson.play();
      setSoundToLesson({ stateId, number });
      // history.push(`/lesson/${level}/${stateId}`);
    }
  };

  const doneCount = (number) => {
    if (stateData.state) {
      for (let i = 0; i < stateData.state.length; i++) {
        if (stateData.state[i].number == number) {
          return stateData.state[i].done == 0 ? null : stateData.state[i].done;
        }
      }
    }
    return null;
  };

  const checkAccess = (number) => {
    if (stateData.state == null) {
      return null;
    }
    for (let i = 0; i < stateData.state.length; i++) {
      if (stateData.state[i].number == number) {
        return stateData.state[i].access;
      }
    }
    return false;
  };

  const checkUnlocked = (number) => {
    if (stateData.state) {
      for (let i = 0; i < stateData.state.length; i++) {
        if (stateData.state[i].number == number) {
          return stateData.state[i].unlocked ? true : false;
        }
      }
    }
    return false;
  };

  return (
    <div style={{ position: 'relative' }}>
      <Sound
        url={soundStateBackground}
        playStatus='PLAYING'
        loop={true}
        volume={config.isMutedSound ? 0 : 50}
      ></Sound>

      {soundToLesson && (
        <Sound
          url={soundToLessonFile}
          playStatus='PLAYING'
          onFinishedPlaying={() => {
            history.push(
              `/lesson/${level}/${soundToLesson.stateId}?number=${soundToLesson.number}`
            );
          }}
          volume={config.isMutedSound ? 0 : undefined}
        ></Sound>
      )}
      <div>
        <div
          id='wb_Image_backgroup'
          style={{
            position: 'relative',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 0,
          }}
        >
          <img
            src={imgBackgroundLevel[level]}
            id='Image_backgroup'
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div
          id='wb_'
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 1,
          }}
        >
          <img
            src={imgPlenetLevel[level]}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

        <div
          id='wb_state-1'
          style={{
            position: 'absolute',
            left: 700,
            top: 570,

            zIndex: 2,
          }}
        >
          <StateItem
            onClick={() => toLesson(1)}
            score={doneCount(1)}
            access={checkAccess(1)}
            unlocked={checkUnlocked(1)}
          />
        </div>
        <div
          id='wb_state-2'
          style={{
            position: 'absolute',
            left: 500,
            top: 400,

            zIndex: 3,
          }}
        >
          <StateItem
            onClick={() => toLesson(2)}
            score={doneCount(2)}
            access={checkAccess(2)}
            unlocked={checkUnlocked(2)}
            // unlocked={true}
          />
        </div>
        <div
          id='wb_state-3'
          style={{
            position: 'absolute',
            left: 860,
            top: 400,

            zIndex: 4,
          }}
        >
          <StateItem
            onClick={() => toLesson(3)}
            score={doneCount(3)}
            access={checkAccess(3)}
            unlocked={checkUnlocked(3)}
          />
        </div>
        <div
          id='wb_state-4'
          style={{
            position: 'absolute',
            left: 1140,
            top: 460,

            zIndex: 5,
          }}
        >
          <StateItem
            onClick={() => toLesson(4)}
            score={doneCount(4)}
            access={checkAccess(4)}
            unlocked={checkUnlocked(4)}
          />
        </div>
        <div
          id='wb_state-5'
          style={{
            position: 'absolute',
            left: 1030,
            top: 210,

            zIndex: 6,
          }}
        >
          <StateItem
            onClick={() => toLesson(5)}
            score={doneCount(5)}
            access={checkAccess(5)}
            unlocked={checkUnlocked(5)}
          />
        </div>
        <div
          id='wb_state-6'
          style={{
            position: 'absolute',
            left: 994,
            top: 575,

            zIndex: 6,
          }}
        >
          <StateItem
            onClick={() => toLesson(6)}
            score={doneCount(6)}
            access={checkAccess(6)}
            unlocked={checkUnlocked(6)}
          />
        </div>
        <div
          id='wb_state-7'
          style={{
            position: 'absolute',
            left: 856,
            top: 707,

            zIndex: 6,
          }}
        >
          <StateItem
            onClick={() => toLesson(7)}
            score={doneCount(7)}
            access={checkAccess(7)}
            unlocked={checkUnlocked(7)}
          />
        </div>
        <div
          id='wb_state-8'
          style={{
            position: 'absolute',
            left: 672,
            top: 282,

            zIndex: 6,
          }}
        >
          <StateItem
            onClick={() => toLesson(8)}
            score={doneCount(8)}
            access={checkAccess(8)}
            unlocked={checkUnlocked(8)}
          />
        </div>
        <div
          id='wb_state-9'
          style={{
            position: 'absolute',
            left: 672,
            top: 116,

            zIndex: 6,
          }}
        >
          <StateItem
            onClick={() => toLesson(9)}
            score={doneCount(9)}
            access={checkAccess(9)}
            unlocked={checkUnlocked(9)}
            redBackGround
          />
        </div>
        {level == 15 && (
          <div
            id='wb_Image1'
            style={{
              position: 'absolute',
              left: 900,
              top: 130,
              width: 114,
              height: 115,
              zIndex: 7,
            }}
          >
            {!checkAccess(0) ? (
              <img
                src={images['state-state6.png']}
                id='state-6'
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <img
                src={images['state-state6.png']}
                className='my-btn'
                onClick={() => toLesson(0)}
                id='state-6'
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            )}
          </div>
        )}
        {checkAccess(0) && (
          <div
            id='wb_state-pass-6'
            className='my-btn'
            onClick={() => toLesson(0)}
            style={{
              position: 'absolute',
              left: 937,
              top: 171,
              width: 40,
              height: 32,
              textAlign: 'center',
              zIndex: 13,
            }}
          >
            <span className='state_number'>
              <strong>{doneCount(0)}</strong>
            </span>
          </div>
        )}
        {level == 15 && !checkAccess(0) && (
          <div
            id='wb_Image2'
            style={{
              position: 'absolute',
              left: 889,
              top: 107,
              width: 137,
              height: 160,
              zIndex: 19,
            }}
          >
            <img
              src={images['state-lock.png']}
              id='Image2'
              alt=''
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        )}
      </div>

      <div
        id='wb_Image_back'
        style={{
          position: 'absolute',
          left: 60,
          top: 920,
          // width: 186,
          // height: 130,
          zIndex: 10,
        }}
        className={c('point')}
      >
        <img
          className='my-btn'
          src={images['back.png']}
          id='Image_back'
          onClick={goBack}
          alt=''
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    </div>
  );
};

State.propTypes = {
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  saveStateData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ state, config, student }) => ({
  stateData: state,
  config,
  student,
});

export default connect(mapStateToProps, { saveStateData })(State);
