import React, { useEffect, useState } from 'react';
import Sound from 'react-sound';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import {
  getPlayBoard as getPlayBoardService,
  addPlayBoard,
} from '../../services/student';
import { useImagesContext } from '../../contexts/ImagesContext';
import { NumberImageYellow } from '../utils/NumberImage';
import soundPlayBoardBackground from '../../sounds/01.mp3';

const useStyles = makeStyles(() => ({
  point: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  background: {
    position: 'relative',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },
  winScoreBackground: {
    position: 'absolute',
    left: 380,
    top: 50,
  },
  winScore: {
    position: 'absolute',
    left: 500,
    top: 56,
    width: 160,
    height: 80,
    display: 'block',
    textAlign: 'center',
  },
  loseScoreBackground: {
    position: 'absolute',
    right: 360,
    top: 50,
  },
  loseScore: {
    position: 'absolute',
    right: 430,
    top: 56,
    width: 160,
    height: 80,
    display: 'block',
    textAlign: 'center',
  },
  home: {
    position: 'absolute',
    left: 20,
    bottom: 20,
  },
  elements: {
    position: 'relative',
    margin: 'auto',
    top: 60,
    width: 1100,
    height: 750,
    display: 'flex',
    flexWrap: 'wrap',
  },
  board: {
    position: 'relative',
    top: 150,
    height: 900,
    margin: 'auto',
  },
  element: {
    width : 89,
    height: 89,
    padding: 10,
  }
}));

const PlayBoard = ({ history, match, config, student }) => {
  const { images } = useImagesContext();
  const imgResult = {
    'result-win': images['winstar.png'],
    'result-lose': images['losestar.png'],
    empty: images['unuse.png'],
  };
  // const studentId = match.params.id;
  const studentId = student.id;
  const [data, setData] = useState([]);
  // const [name, setName] = useState(null);
  const [current, setCurrent] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getPlayBoardService(studentId)
      .then((res) => {
        console.debug(res.data);
        setData(res.data.data);
        // setName(res.data.name);
      })
      .catch((error) => {
        console.error(error.response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHomeClick = (e) => {
    async function _saveData() {
      try {
        await addPlayBoard(studentId, current);
      } catch (err) {
        console.error(err);
      }
    }
    if (current.length > 0) {
      _saveData();
    }
    history.goBack();
  };

  const playTiggle = (itemIndex) => {
    if (itemIndex < data.length) return;
    const playIndex = data.length + current.length;
    if (playIndex === itemIndex) {
      setCurrent([...current, { id: itemIndex + 1, result: 'W' }]);
    } else if (playIndex - 1 === itemIndex) {
      if (current[itemIndex - data.length].result === 'W') {
        setCurrent([
          ...current.slice(0, itemIndex - data.length),
          { id: itemIndex + 1, result: 'L' },
        ]);
      } else {
        setCurrent([...current.slice(0, itemIndex - data.length)]);
      }
    }
  };

  console.debug('current', current);
  console.debug('data', data);
  let winCount = 0;
  let loseCount = 0;
  data.forEach((v) => {
    if (v.result === 'W') winCount += 1;
    else loseCount += 1;
  });
  current.forEach((v) => {
    if (v.result === 'W') winCount += 1;
    else loseCount += 1;
  });

  const element = [];
  const playedCount = data.length;
  let playResult;
  for (let i = 0; i < 5; i++) {
    const elementI = [];
    for (let j = 0; j < 12; j++) {
      const itemIndex = i * 12 + j;
      if (itemIndex < playedCount) {
        playResult =
          data[itemIndex].result === 'W' ? 'result-win' : 'result-lose';
      } else if (itemIndex < playedCount + current.length) {
        playResult =
          current[itemIndex - playedCount].result === 'W'
            ? 'result-win'
            : 'result-lose';
      } else {
        playResult = 'empty';
      }
      element.push(
        // elementI.push(
        // <td style={{ padding: 10 }}>
        <img
          onClick={() => playTiggle(itemIndex)}
          src={imgResult[playResult]}
          className={classes.element}
          alt=''
          onContextMenu={(e) => e.preventDefault()}
        />
        // </td>
      );
    }
    // element.push(<tr>{elementI}</tr>);
  }

  const goBack = () => {
    // history.push(`/dashboard`);
    history.goBack();
  };

  return (
    <div
      className={classes.background}
      style={{
        backgroundImage: `url(${images['background.jpg']})`,
      }}
    >
      <Sound
        url={soundPlayBoardBackground}
        playStatus='PLAYING'
        loop={true}
        volume={config.isMutedSound ? 0 : 15}
      ></Sound>
      <div
        className={classes.board}
        style={{
          background: `url(${images['board.png']}) no-repeat center center`,
        }}
      >
        <div className={classes.elements}>
          {element}
        </div>
      </div>
      {/* <div className={classes.elements}></div> */}
      <img
        className={classes.winScoreBackground}
        src={images['win.png']}
        alt=''
      />
      <div className={classes.winScore}>
        <NumberImageYellow height={80} number={winCount ? winCount : ''} />
      </div>
      <img
        className={classes.loseScoreBackground}
        src={images['lose.png']}
        alt=''
      />
      <div className={classes.loseScore}>
        <NumberImageYellow height={80} number={loseCount ? loseCount : ''} />
      </div>
      <img
        onClick={handleHomeClick}
        className={`${classes.home} ${classes.point}`}
        src={images['home.png']}
        alt=''
      />
    </div>
  );
};

const mapStateToProps = ({ config,student }) => ({
  student,
  config,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlayBoard);
