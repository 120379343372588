import {
  BOOK_OPEN_FORWARD,
  BOOK_OPEN_BACKWARD,
  BOOK_OPEN_DISAPPEAR,
} from '../actions/types';

const initialState = { bookForward: false, bookBackward: false };
export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case BOOK_OPEN_FORWARD:
      return { ...state, bookForward: true, bookBackward: false };
    case BOOK_OPEN_BACKWARD:
      return { ...state, bookForward: false, bookBackward: true };
    case BOOK_OPEN_DISAPPEAR:
      return { ...state, bookForward: false, bookBackward: false };
    default:
      return state;
  }
}
