import React from "react";

import { connect } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';

import FightMap from './FightMap';
import FightStage from './FightStage';


function Fight() {
  let { path } = useRouteMatch();
  console.debug('path', path);
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={FightMap} />
        <Route exact path={`${path}/:stageId`} component={FightStage} />
      </Switch>
    </div>
  );
}

export default Fight;
