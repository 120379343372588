import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import Lesson from './Lesson/Lesson';
import {
  getBookLevelList as getBookLevelListService,
  errorHandler,
} from '../../../services/fuseki';
import imgLoading from '../../../images/circle-loading-animation.gif';
import { connect } from 'react-redux';
// import Swal from 'sweetalert2';
// import { useRouteMatch } from 'react-router-dom';
import UIfx from 'uifx';
import soundUnLockedFile from '../../../sounds/13.mp3';
import soundClearFile from '../../../sounds/14.wav';
import soundEnterLevelFile from '../../../sounds/15.wav';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '1790px',
    height: '1120px',
  },
  grid: {
    width: '1700px',
    height: '1080px',
  },
  imageBook: {
    width: 232,
    height: 233,
  },
  imageLockEffect: {
    position: 'absolute',
    top: '-150px',
    left: '-90px',
  },
  imageClearEffect: {
    position: 'absolute',
    zIndex: '9999',
    width: '400px',
    height: '240px',
    top: '8px',
    left: '-50px',
  },
  imageLock: {
    position: 'absolute',
    top: '2px',
    left: '50px',
  },
  imageClear: {
    position: 'absolute',
    top: '75px',
    left: '-8px',
  },
  bookTitle: {
    position: 'absolute',
    bottom: '84px',
    transform: 'translateX(8px) scale(1.06)',
    margin: 'auto',
    fontSize: '2.5rem',
    textShadow:
      '2px 0px 0px #fff, 0px 2px 0px #fff, 0px -2px 0px #fff, -2px 0px 0px #fff',
  },
  imgContainer: {
    display: 'flex',
    width: 296,
    height: 297,
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    '&:hover': {
      '& [lock="false"]': {
        transform: 'scale(1.05)',
        filter: 'drop-shadow(-4px 12px 6px black)',
        '& + [imageclear]': {
          transform: 'scale(1.05)',
          filter: 'drop-shadow(-4px 8px 2px black)',
        },
      },
      '& [booktitle]': {
        transform: 'translate(8px,3px) scale(1.08)',
        zIndex: '7000',
      },
    },
  },
  imageBack: {
    marginLeft: '20px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },
  imageFusekiLabel: {
    position: 'absolute',
    right: '20px',
    filter: 'drop-shadow(0px 4px 2px black)',
  },
  footer: {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 250px)',
    left: 'calc(50% - 250px)',
  },
}));

const soundUnLocked = new UIfx(soundUnLockedFile);
const soundClear = new UIfx(soundClearFile);
const soundEnterLevel = new UIfx(soundEnterLevelFile);

const Fuseki = (props) => {
  const classes = useStyles();
  const { config } = props;
  const [itemes, setItemes] = useState([]);
  const [colItem, setColItem] = useState([]);
  const [isLessonDisp, setIsLessonDisp] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [backToLevel, setBackToLevel] = useState(false);
  const [startUnlockedEffect, setStartUnlockedEffect] = useState(false);
  const [startClearEffect, setStartClearEffect] = useState(false);
  const isInited = React.useRef(false);
  const refClearEffect = useRef();
  const [isRenderTitle, setIsRenderTitle] = useState(false);
  const { images } = useImagesContext();
  // const refBookImg = useRef({});

  // React.useEffect(() => {
  //   // setIsLoading(true);
  //   // getBookLevel();
  // }, []);

  // const { url } = useRouteMatch();
  React.useEffect(() => {
    if (!props.student || !props.student.id) {
      props.history.push(`/home`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.student]);

  React.useEffect(() => {
    if (backToLevel) {
      setIsLoading(true);
      getBookLevel();
      setBackToLevel(false);
      setIsRenderTitle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToLevel]);

  React.useEffect(() => {
    if (itemes.length > 0) {
      setColItem([
        [[itemes[0], itemes[1]], [itemes[2], itemes[3]], [itemes[4]]],
        [[itemes[8], itemes[9]], [itemes[6], itemes[7]], [itemes[5]]],
      ]);
    }
  }, [itemes]);

  const initial = () => {
    if (!isInited.current) {
      setIsLoading(true);
      getBookLevel();
      isInited.current = true;
    }
  };

  const unlockedEffectHandler = (colIdx, rowIdx) => {
    if (colItem[colIdx][rowIdx].unlockedEffect) {
      !config.isMutedSound && soundUnLocked.play(0.4);
      setTimeout(() => {
        setColItem((colItem) => {
          const tcolItem = colItem;
          tcolItem[colIdx][rowIdx].unlockedEffect = false;
          return [...tcolItem];
        });
        setStartUnlockedEffect(false);
      }, 500);
    }
  };

  const clearEffectHandler = (id) => {
    const idx = itemes.findIndex((it) => it.id === id);
    if (itemes[idx].clearEffect && !refClearEffect.current) {
      refClearEffect.current = true;
      !config.isMutedSound && soundClear.play(0.4);
      setTimeout(() => {
        // setColItem((colItem) => {
        //   const tcolItem = colItem;
        //   tcolItem[colIdx][rowIdx].clearEffect = false;
        //   return [...tcolItem];
        // });
        setItemes((itemes) => {
          const tItemes = itemes;
          tItemes[idx].clearEffect = false;
          return [...itemes];
        });
        refClearEffect.current = false;
        setStartClearEffect(false);
      }, 600);
    }
  };

  const getBookLevel = () => {
    getBookLevelListService(props.student.id)
      .then((resp) => {
        setIsLoading(false);
        const updateItem = resp.data || [];

        //hardcode for test
        //updateItem[1].clear = false;
        //updateItem[9].locked = true;
        if (backToLevel) {
          //updateItem[3].clear = true;
          //updateItem[1].clear = true;
          //updateItem[9].locked = false;
          updateItem.forEach((updateIt) => {
            itemes.forEach((prevIt) => {
              if (updateIt.number === prevIt.number) {
                if (prevIt.locked && !updateIt.locked) {
                  updateIt.unlockedEffect = true;
                }
                if (!prevIt.clear && updateIt.clear) {
                  updateIt.clearEffect = true;
                }
              }
            });
          });
          setTimeout(() => {
            setStartClearEffect(true);
            setStartUnlockedEffect(true);
          }, 300);
        }

        console.log('updateItem:', updateItem);

        setItemes(updateItem);
      })
      .catch((err) => {
        setIsLoading(false);
        // setErrorMsg(err);
        // setIsErrorShown(true);
        errorHandler(err);
      });
  };

  const chapterBackHandler = () => {
    setIsLessonDisp(false);
    setBackToLevel(true);
  };

  return (
    <React.Fragment>
      {isLessonDisp ? (
        <Lesson number={selectItem.number} backHandler={chapterBackHandler} />
      ) : (
          <div
            style={{
              backgroundImage: `url(${images['fsk_background.jpg']})`,
            }}
            className={classes.root}
            onLoad={initial}
          >
            <div
              style={{
                backgroundImage: `url(${images['fsk_bookbig.png']})`,
              }}
              className={classes.container}
            >
              <img
                className={classes.imageFusekiLabel}
                src={images['fuseki.png']}
                id="fuseki"
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
              <Grid container className={classes.grid} spacing={6}>
                <Grid item xs={1}></Grid>
                {colItem.map((rowItem, colIdx) => (
                  <Grid
                    key={'col' + colIdx}
                    item
                    xs
                    container
                    direction={colIdx === 0 ? 'column' : 'column-reverse'}
                    justify="center"
                    alignItems="center"
                    spacing={4}
                  >
                    {(rowItem || []).map((rit, rowIdx) => (
                      <Grid
                        key={'row' + rowIdx}
                        item
                        container
                        justify="center"
                        spacing={8}
                      >
                        {rit.map((it, colIdx) => (
                          <Grid
                            key={'it' + it.id}
                            className={classes.imgContainer}
                            item
                            onClick={() => {
                              if (!it.locked) {
                                !config.isMutedSound && soundEnterLevel.play(0.4);
                                setIsLessonDisp(true);
                                setSelectItem(it);
                                setStartClearEffect(false);
                                setStartUnlockedEffect(false);
                              }
                            }}
                          >
                            <img
                              className={classes.imageBook}
                              src={images['fsk_book.png']}
                              id={it.id}
                              lock={it.locked ? 'true' : 'false'}
                              onContextMenu={(e) => e.preventDefault()}
                              // onLoad={() => {
                              //   isRenderTitle[colIdx + '_' + rowIdx] = true;
                              //   setIsRenderTitle({ ...isRenderTitle });
                              // }}
                              ref={(ref) => {
                                if (ref) {
                                  //console.log('refff:::', ref.complete);
                                  if (!ref.complete && it.number === 6) {
                                    ref.onload = function () {
                                      setTimeout(() => {
                                        setIsRenderTitle(true);
                                      }, 50);

                                      // setTimeout(() => {
                                      //   isRenderTitle[colIdx + '_' + rowIdx] = true;
                                      //   setIsRenderTitle({ ...isRenderTitle });
                                      // }, 100);
                                    };
                                  } else if (ref.complete && it.number === 6) {
                                    setIsRenderTitle(true);
                                  }
                                }
                              }}
                              alt=''
                            />
                            {isRenderTitle ? (
                              <span booktitle="" className={classes.bookTitle}>
                                {it.title}
                              </span>
                            ) : null}
                            {it.unlockedEffect && startUnlockedEffect ? (
                              <img
                                ref={() => unlockedEffectHandler(colIdx, rowIdx)}
                                className={classes.imageLockEffect}
                                src={images['effect04.png']}
                                id={'lockeffect_' + it.id}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                            {it.clearEffect && startClearEffect ? (
                              <img
                                ref={() => clearEffectHandler(it.id)}
                                className={classes.imageClearEffect}
                                src={
                                  images['f_effect01.png'] + '?a=' + Math.random()
                                }
                                id={'cleareffect_' + it.id}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                            {it.locked ? (
                              <img
                                className={classes.imageLock}
                                src={images['lock.png']}
                                id={'lock_' + it.id}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                            {!it.locked && it.clear ? (
                              <img
                                imageclear=""
                                className={classes.imageClear}
                                src={images['fsk_clear.png']}
                                id={'clear_' + it.id}
                                onContextMenu={(e) => e.preventDefault()}
                                alt=''
                              />
                            ) : null}
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                ))}
                <Grid item xs={1}></Grid>
              </Grid>
            </div>
            <footer id="footer" className={classes.footer}>
              <Grid container justify="space-between">
                <Grid item xs container>
                  <img
                    className={classes.imageBack}
                    src={images['back.png']}
                    id="back"
                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => {
                      props.history.goBack();
                      //props.backHandler()
                    }}
                    alt=''
                  />
                </Grid>
              </Grid>
            </footer>
            {isLoading ? (
              <img
                className={classes.loading}
                src={imgLoading}
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />
            ) : null}
          </div>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ student, config }) => ({
  student,
  config
});

export default connect(mapStateToProps, null)(Fuseki);
