import React, { useState, useEffect, Fragment } from 'react';
import Sound from 'react-sound';
import UIfx from 'uifx';
import { connect } from 'react-redux';
import { Fade } from '@material-ui/core';
import { useImagesContext } from '../../contexts/ImagesContext';

import {
  getLesson,
  passLesson as passLessonService,
} from '../../services/student';

import './Lesson.css';
import { useLocation } from 'react-router-dom';

import ToolBar from '../toolbar/ToolBar';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from '../../utils/utility';
import { saveJumpUri } from '../../actions/jump';
// import { clickAnime } from 'utils/element';

import soundPassOrNoFile from '../../sounds/07.mp3';
// import soundScoreDisplayFile from '../../sounds/08.mp3';
import soundFinish from '../../sounds/08.mp3';

const FADE_IN_DURATION = 2000;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const soundPassOrNo = new UIfx(soundPassOrNoFile);
// const soundScoreDisplay = new UIfx(soundScoreDisplayFile);

const useStyles = makeStyles((theme) => ({
  focus: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  active: {
    filter:
      'drop-shadow(0px 4px 4px #4aaccb) drop-shadow(0px -3px 4px #4aaccb) !important',
    transform: 'scale(1.1) !important',
    willChange: 'transform !important',
    transition: 'transform 0.1s ease !important',
  },
  jump: {
    position: 'absolute',
    zIndex: 7001,
    // margin: 'auto',
    top: '300px',
    left: '480px',
  },
}));

const Lesson = ({ history, match, config, saveJumpUri, student }) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles());

  const imgBackToLevel = {
    15: images['lesson-backto15.png'],
    14: images['lesson-backto14.png'],
    13: images['lesson-backto13.png'],
    12: images['lesson-backto12.png'],
    11: images['lesson-backto11.png'],
  };

  // const { path } = useRouteMatch();
  let query = useQuery();
  // console.debug('path', path);
  const { level, state } = match.params;
  const id = student.id;
  console.debug('match.params', match.params);
  const [lesson, setLesson] = useState([]);
  const num = query.get('num');
  const qCurrentPass = query.get('pass');
  const number = query.get('number');
  console.debug('qCurrentPass', qCurrentPass);
  const [currentPass, setCurrentPass] = useState(
    qCurrentPass ? qCurrentPass.split(',').map((v) => parseInt(v)) : []
  );
  const [lessonIndex, setLessonIndex] = useState(num ? parseInt(num) : 0);
  // const [finishScore, setFinishScore] = useState(null);
  const [finish, setFinish] = useState({
    score: null,
    level: null,
  });
  const [scoreNumber, setScoreNumber] = useState(false);

  const [effectPass, setEffectPass] = useState(false);

  const [toolState, setToolState] = useState('init');

  const [playSound, setPlaySound] = useState(false);
  const [showImage, setShowImage] = useState(false);

  console.debug('currentPass', currentPass);

  const handleError = (error) => {
    if (error.response) {
      console.error(error.response.data);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error('Error', error.message);
    }
  };

  useEffect(() => {
    getLesson(id, state)
      .then((res) => {
        console.debug('getLesson', res.data);
        setLesson(res.data);
      })
      .catch((error) => {
        handleError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finishLesson = () => {
    passLessonService(id, state, currentPass)
      .then((res) => {
        console.debug('passLessonSerive', res.data);
        setFinish({
          score: res.data.score,
          level: res.data.level,
        });
      })
      .catch((err) => console.error(err));
  };

  const moveToState = () => {
    if (finish.level != null) {
      history.replace(`/planet?levelup=${finish.level}`);
    } else {
      history.replace(`/state/${level}`);
    }
  };

  const goBack = () => {
    resetToolBar();
    history.goBack();
  };

  const _moveForward = () => {
    if (lessonIndex === lesson.length - 1) {
      finishLesson();
      // setFinishScore(
      //   lesson.reduce((prev, curr) => prev + (curr.pass ? 1 : 0), 0) +
      //     currentPass.length
      // );
      return;
    }
    resetToolBar();
    setLessonIndex(lessonIndex + 1);
  };

  const moveForward = () => {
    if (playSound) {
      setPlaySound(false);
      setShowImage(false);
      // setTimeout(() => {
      //   _moveForward();
      // }, FADE_IN_DURATION);
      // } else {
    }
    _moveForward();
  };

  const _moveฺBackward = () => {
    if (lessonIndex === 0) return;
    resetToolBar();
    setLessonIndex(lessonIndex - 1);
  };

  const moveฺBackward = () => {
    if (playSound) {
      setPlaySound(false);
      setShowImage(false);
      // setTimeout(() => {
      //   _moveฺBackward();
      // }, FADE_IN_DURATION);
      // } else {
    }
    _moveฺBackward();
  };

  const addPass = (lessonId) => {
    !config.isMutedSound && soundPassOrNo.play();
    setEffectPass(true);
    setTimeout(() => {
      setEffectPass(false);
      setCurrentPass([...currentPass, lessonId]);
    }, 500);
  };

  const removePass = (lessonId) => {
    !config.isMutedSound && soundPassOrNo.play();
    const i = currentPass.findIndex((v) => v === lessonId);
    const newCurrentPass = currentPass
      .slice(0, i)
      .concat(currentPass.slice(i + 1));

    setEffectPass(true);
    setTimeout(() => {
      setEffectPass(false);
      setCurrentPass(newCurrentPass);
    }, 500);
  };

  const currLesson =
    lessonIndex !== 0 && lesson.length > 0 ? lesson[lessonIndex] : null;
  const oldPass = currLesson ? (currLesson.pass ? true : false) : false;
  const currPass =
    currLesson && currentPass.findIndex((v) => v === currLesson.id) !== -1
      ? true
      : false;

  if (finish.score != null) {
    setTimeout(() => {
      // soundScoreDisplay.play();
      setScoreNumber(true);
    }, 100);
  }

  const resetToolBar = () => {
    setToolState('');
    setTimeout(() => {
      setToolState('init');
    }, 10);
  };

  const handleClickPlaySound = () => {
    setPlaySound((prev) => !prev);
    setShowImage(true);
  };

  console.debug(
    'lesson[lessonIndex]?.imageEffect',
    lesson[lessonIndex]?.imageEffect
  );
  const lastImage = lessonIndex === lesson.length - 1;

  function jump() {
    const jumpUri = `/lesson/${level}/${state}`;
    if (currentPass.length > 0) {
      saveJumpUri(jumpUri + '?num=12&pass=' + currentPass.join(','));
    } else {
      saveJumpUri(jumpUri + '?num=12');
    }
    // history.push(`/dashboard`);
    history.push(`/hall`);
  }

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {lesson[lessonIndex]?.sound ? (
        <Sound
          url={lesson[lessonIndex]?.sound || ''}
          playStatus={playSound ? 'PLAYING' : 'STOPPED'}
          //loop={true}
          volume={config.isMutedSound ? 0 : 50}
          onFinishedPlaying={() => {
            console.debug('onFinishedPlaying');
            setPlaySound(false);
          }}
        ></Sound>
      ) : null}
      {showImage && (
        <Fade
          in={playSound}
          timeout={FADE_IN_DURATION}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 99,
          }}
        >
          <img
            src={lesson[lessonIndex]?.imageEffect + '?a=' + Math.random()}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </Fade>
      )}
      <div>
        <div
          id='wb_lesson_lesson'
          style={{
            position: 'relative',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 0,
          }}
        >
          <img
            src={lesson.length > 0 ? lesson[lessonIndex].image : ''}
            id='lesson_lesson'
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div
          id='wb_lesson-back'
          style={{
            position: 'absolute',
            left: 10,
            top: 10,
            width: 226,
            height: 223,
            zIndex: 7001,
          }}
        >
          <img
            src={imgBackToLevel[level]}
            id='lesson-back'
            className={c('my-btn focus')}
            onClick={goBack}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        {lessonIndex !== 0 && (
          // <div
          //   id="wb_lesson_backward"
          //   style={{
          //     position: 'absolute',
          //     left: 1690,
          //     top: 30,
          //     width: 109,
          //     height: 113,
          //     zIndex: 7002,
          //   }}
          // >
          <div
            id='wb_lesson_backward'
            style={{
              position: 'absolute',
              left: 10,
              top: 430,
              width: 109,
              height: 113,
              zIndex: 7002,
            }}
          >
            <img
              src={images['lesson-backward.png']}
              id='lesson_backward'
              className={c('my-btn focus')}
              onClick={moveฺBackward}
              alt=''
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        )}

        {/* <div
          id="wb_lesson_forward"
          style={{
            position: 'absolute',
            left: 1810,
            top: 30,
            width: 109,
            height: 113,
            zIndex: 7003,
          }}
        > */}
        <div
          id='wb_lesson_forward'
          style={{
            position: 'absolute',
            left: 130,
            top: 430,
            width: 109,
            height: 113,
            zIndex: 7003,
          }}
        >
          <img
            src={images['lesson-forward.png']}
            id='lesson_forward'
            className={c('my-btn focus')}
            onClick={moveForward}
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        {effectPass && (
          <div
            id='wb_lesson_pass'
            style={{
              position: 'absolute',
              left: 0,
              top: 200,
              // width: 159,
              // height: 168,
              zIndex: 7005,
            }}
          >
            <img
              src={images['effect-05.png']}
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
          </div>
        )}

        {lesson[lessonIndex]?.sound ? (
          <div
            id='wb_lesson_sound'
            style={{
              position: 'absolute',
              left: 72,
              top: 555,
              width: 109,
              height: 113,
              zIndex: 7002,
            }}
          >
            <img
              style={{
                width: 110,
              }}
              src={images['lesson-sound.png']}
              id='lesson_sound'
              className={c('my-btn focus' + (playSound ? ' active' : ''))}
              onClick={handleClickPlaySound}
              //ref={clickAnime}
              onContextMenu={(e) => e.preventDefault()}
              alt=''
            />
          </div>
        ) : null}

        <div
          id='wb_lesson_pass'
          style={{
            position: 'absolute',
            left: 30,
            top: 240,
            width: 159,
            height: 168,
            zIndex: 7004,
          }}
        >
          {currLesson &&
            (oldPass ? (
              <img
                src={images['lesson-starpass.png']}
                id='lesson_pass'
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : currPass ? (
              <img
                src={images['lesson-starpass.png']}
                id='lesson_pass'
                className='my-btn'
                onClick={() => removePass(currLesson.id)}
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <img
                src={images['lesson-starnopass.png']}
                id='lesson_pass'
                className='my-btn'
                onClick={() => addPass(currLesson.id)}
                alt=''
                onContextMenu={(e) => e.preventDefault()}
              />
            ))}
        </div>
      </div>
      {finish.score !== null && (
        <div
          id='wb_lesson_finish'
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 1920,
            height: 1080,
            zIndex: 7005,
          }}
          onClick={moveToState}
        >
          <img
            src={images['lesson-finish.png']}
            id='lesson_finish'
            alt=''
            onContextMenu={(e) => e.preventDefault()}
          />
          {scoreNumber && (
            <>
              <img
                src={images['effect-04.gif']}
                style={{
                  position: 'absolute',
                  left: 560,
                  top: 225,
                  width: 800,
                  height: 630,
                  zIndex: 7007,
                }}
                onContextMenu={(e) => e.preventDefault()}
                alt=''
              />

              <div
                id='wb_finish_score'
                style={{
                  position: 'absolute',
                  left: 790,
                  top: 260,
                  width: 300,
                  height: 300,
                  textAlign: 'center',
                  zIndex: 7006,
                }}
                onClick={moveToState}
              >
                {finish.score != null && !config.isMutedSound && (
                  <Sound
                    url={soundFinish}
                    playStatus='PLAYING'
                    // onFinishedPlaying={() =>
                    //   history.push(`/lesson/${level}/${soundToLesson}`)
                    // }
                  ></Sound>
                )}
                <span
                  style={{
                    color: '#FFFFFF',
                    fontFamily: 'Arial',
                    fontSize: 220,
                    textShadow: '0 0 5px #000000',
                  }}
                >
                  <strong>{finish.score}</strong>
                </span>
              </div>
            </>
          )}
        </div>
      )}
      <ToolBar toolstate={toolState} />
      {lastImage && number != 9 && (
        <div className={c('jump')}>
          <img
            // className={classes.imageFusekiLabel}
            className={c('my-btn focus')}
            src={images['jump.png']}
            id='jump'
            alt=''
            onClick={() => jump()}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ config, jump, student }) => ({
  config,
  jump,
  student,
});

const mapDispatchToProps = {
  saveJumpUri,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lesson);
