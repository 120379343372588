import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

// const useStyles = makeStyles((theme) => ({}));

const CheckTouchDevice = (props) => {
  // const classes = useStyles();

  const isTouchDevice = () => {
    var isTouchCapable =
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;
    return isTouchCapable;
  };

  return (
    <React.Fragment>
      <span style={{ fontSize: '32px' }}>
        {isTouchDevice() ? 'Is touch device.' : 'Is not touch device.'}
      </span>
    </React.Fragment>
  );
};
const mapStateToProps = ({ student }) => ({
  student,
});

export default connect(mapStateToProps, null)(CheckTouchDevice);
