import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useImagesContext } from '../../contexts/ImagesContext';
import { Grid } from '@material-ui/core';
import Problem from './Problem/Problem';
import Fuseki from './Fuseki/Fuseki';
import { connect } from 'react-redux';
import UIfx from 'uifx';
import soundMainFile from '../../sounds/16.mp3'; 
import soundSelectFile from '../../sounds/15.wav';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '1920px',
    height: '1080px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '1790px',
    height: '1120px',
  },
  imageBook: {
    height: '450px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  imgContainer: {},
  imageGridItem: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    '&:hover [shine]::before': {
      '-webkit-animation': '$shine .7s',
      animation: '$shine .7s',
    },
    '&:hover [imageproblempabel], &:hover [imagefusekilabel]': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(-4px 9px 9px black)',
    },
  },
  imageFusekiLabel: {
    marginRight: '12px',
    marginTop: '32px',
    '& + [shine]': {
      top: '100px',
      left: '55px',
      width: '320px',
      height: '140px',
    },
    '& + [shine]::before': {
      width: '100%',
      height: '100%',
      left: '-120%',
    },
  },
  imageProblemLabel: {
    marginLeft: '-28px',
    marginTop: '32px',
    '& + [shine]': {
      top: '100px',
      left: '25px',
      width: '320px',
      height: '140px',
    },
    '& + [shine]::before': {
      width: '100%',
      height: '100%',
      left: '-120%',
    },
  },
  imageBack: {
    marginLeft: '60px',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'drop-shadow(0px 4px 2px black)',
    },
  },

  shine: {
    overflow: 'hidden',
    position: 'absolute',
    margin: '0',
    '&::before': {
      position: 'absolute',
      zIndex: '2',
      display: 'block',
      content: '""',
      // background:
      //   '-webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
      background:
        'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
        '-webkit-transform': 'skewX(-25deg)',
      transform: 'skewX(-25deg)',
    },
  },
  '@-webkit-keyframes shine': {
    '100%': {
      left: '125%',
    },
  },
  '@keyframes shine': {
    '100%': {
      left: '125%',
    },
  },

  footer: {
    position: 'absolute',
    bottom: '40px',
    width: '100%',
  },
}));

const soundMain = new UIfx(soundMainFile);
const soundSelect = new UIfx(soundSelectFile);

const Mission = (props) => {
  const classes = useStyles();
  const { student, config } = props;
  const { images } = useImagesContext();
  const [isProblemDisp, setIsProblemDisp] = useState(false);
  const [isFusekiDisp, setIsFusekiDisp] = useState(false);

  const problemBackHandler = () => {
    setIsProblemDisp(false);
  };

  const fusekiBackHandler = () => {
    setIsFusekiDisp(false);
  };

  const adjustVolume = () => {
    document.getElementById('audio_problem_main').volume = '0.5';
  };

  return (
    <React.Fragment>
      {!isProblemDisp && !isFusekiDisp && !config.isMutedSound ? (
        <audio
          ref={adjustVolume}
          id='audio_problem_main'
          hidden
          src={soundMain}
          autoPlay
          controls
          loop
        ></audio>
      ) : null}

      {isProblemDisp ? (
        <Problem backHandler={problemBackHandler} />
      ) : isFusekiDisp ? (
        <Fuseki backHandler={fusekiBackHandler} />
      ) : (
        <div
          style={{
            backgroundImage: `url(${images['backfirst.jpg']})`,
          }}
          className={classes.root}
        >
          <div
            style={{
              backgroundImage: `url(${images['book.png']})`,
            }}
            className={classes.container}
          >
            <Grid container style={{ height: '100%' }}>
              <Grid
                className={classes.imgContainer}
                item
                xs={5}
                container
                direction='column'
                alignItems='center'
              >
                <Grid
                  className={classes.imageGridItem}
                  onClick={() => {
                    !config.isMutedSound && soundSelect.play(0.4);
                    //setIsProblemDisp(true);
                    props.history.push(`/mission/problem`);
                  }}
                >
                  <img
                    imageproblempabel=''
                    className={classes.imageProblemLabel}
                    src={images['problem.png']}
                    id='problem'
                    onContextMenu={(e) => e.preventDefault()}
                    alt=''
                  />
                  <figure shine='' className={classes.shine}></figure>
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid
                className={classes.imgContainer}
                item
                xs={5}
                container
                direction='column'
                alignItems='center'
              >
                <Grid
                  item
                  className={classes.imageGridItem}
                  onClick={() => {
                    !config.isMutedSound && soundSelect.play(0.4);
                    //setIsFusekiDisp(true);
                    props.history.push(`/mission/fuseki`);
                  }}
                >
                  <img
                    imagefusekilabel=''
                    className={classes.imageFusekiLabel}
                    src={images['fuseki.png']}
                    id='fuseki'
                    onContextMenu={(e) => e.preventDefault()}
                    alt=''
                  ></img>
                  <figure shine='' className={classes.shine}></figure>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <footer id='footer' className={classes.footer}>
            <Grid container justify='space-between'>
              <Grid item xs container>
                <img
                  className={classes.imageBack}
                  src={images['back.png']}
                  id='back'
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={() => {
                    // props.history.push(`/dashboard/${student.id}`);
                    props.history.push(`/hall/${student.id}`);
                  }}
                  alt=''
                />
              </Grid>
            </Grid>
          </footer>
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ student, config }) => ({
  student,
  config,
});

export default connect(mapStateToProps, null)(Mission);
